import styled from "styled-components";

export const StyledTwoFaForm = styled.form`
  .confirm-action {
    display: grid;
    padding-bottom: 0;
  }
  .twofa-input input {
    text-align: center;
    font-weight: 600;
    letter-spacing: 5px;
  }
`;

export const StyledTwoFaActive = styled.div`
  .security-code {
    text-align: center;
  }
`;