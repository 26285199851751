import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ClientDetailsMainInfo from "./ClientDetailsMainInfo";
import ClientDetailsStatisticsContainer from "./ClientDetailsStatisticsContainer";
import Notification from "rc-notification";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import AlertMessage from "../../../elements/alert/Alert";
import ClientDetailsActivityAndStatisticsWrapper from "./ClientDetailsActivityAndStatisticsWrapper";
import { StyledCloseImg, StyledContainer, StyledContentWrapper } from "../../../styles/styledContainer";
import { Helmet } from "react-helmet-async";
import { StyledClientDetailsWrapper } from "./styledClientDetails";
import Spinner from "../../../elements/spinner/Spinner";
import { generatePath } from "../../../../utils/getLanguage";
import http from "../../../../http";

const ClientDetailsContainer = ({ match }) => {

  const history = useHistory();

  const [user, setUser] = useState({ data: null, loading: false });

  const { t } = useTranslation("siteOptions");

  const [notification, setNotification] = useState({
    visible: false,
    type: "",
    message: ""
  });

  const fetchUserByNickname = () => {
    setUser({ data: null, loading: true });
    http.get("/api/users/check-if-exist-by-nickname/" + match.params.nickname, userAuthenticationConfig()).then(response => {
      if (response.status === 200 && response.data) {
        console.log(response.data)
        if (response?.data?.nickname !== match.params.nickname) {
          history.replace(`/account/${response.data.nickname}`);
        }
        setUser({ data: response.data, loading: false });
      } else {
        setUser({ data: null, loading: false });
        history.push('/page-not-found');
      }
    }).catch(error => {
      setNotification({ ...notification, visible: true, type: "error", message: error.response.data.message });
      setUser({ data: null, loading: false });
    });

  };

  useEffect(() => {
    fetchUserByNickname();
  }, [match.params.nickname]);

  const goBack = useCallback(() => history.goBack(), [history]);
  const goToLink = useCallback((link) => history.push(generatePath(link)), [history]);

  if (!match.params.nickname) {
    return <AlertMessage
      type="info"
      message={t('notFoundUser')}
    />;
  }

  if (user.loading) {
    return (
      <StyledContainer>
        <Spinner display="block" size="35px"/>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <Notification
        notification={notification}
        setNotification={setNotification}
      />
      <Helmet>
        <title>{t("meta.account.title")}</title>
        <meta
          name="description"
          content={t("meta.account.description")}
        />
        <link
          rel="canonical"
          href={"https://" + window.location.hostname + "/account"}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/account"}
          hrefLang="en-US"
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ru/account"}
          hrefLang="ru-RU"
        />
      </Helmet>
      <StyledClientDetailsWrapper>
        <StyledContentWrapper width="1000">
          <StyledCloseImg onClick={goBack} top={'-13px'} right={'25px'}/>
          <div className="account-wrapper">
            <ClientDetailsMainInfo
              notification={notification}
              setNotification={setNotification}
              nickname={match.params.nickname}
            />
            <div className={'account-wrapper__info'}>
              <ClientDetailsStatisticsContainer
                notification={notification}
                setNotification={setNotification}
                nickname={match.params.nickname}
              />
              <ClientDetailsActivityAndStatisticsWrapper
                match={match}
                setNotification={setNotification}
              />
            </div>
          </div>
        </StyledContentWrapper>
      </StyledClientDetailsWrapper>
    </StyledContainer>
  );
};

export default ClientDetailsContainer;
