import React, { /* useCallback, useRef,*/ useImperativeHandle, useRef } from 'react';
import { animated, easings, useSpring, to } from "@react-spring/web";
import { formatChip } from './utils';
// import rouletteBall from "../../../assets/images/roll/ball.png";
import {
  StyledChip,
} from "./styledRoulette";


const ChipItem = React.forwardRef(({
  children,
  as,
  isMobile,
  ...props
}, ref) => {
  const chipRef = useRef();
  const [chipItemStyle, chipItemApi] = useSpring(
    () => (
    {
      from: {
        x: 0,
        y: 0,
      },
      config: {
        duration: 200
      }
    }
  ), []);

  useImperativeHandle(ref, () => ({
    testFunc: async (
    ) => {

    },
    getBoundingClientRect: () => {
      return chipRef.current.getBoundingClientRect();
    },
    moveTo: (x, y, duration) => new Promise((resolve) => {
      chipRef.current.parentElement.style.zIndex = 30;
      chipItemApi.start({
        to: {
          x,
          y,
        },
        config: {
          duration: duration,
          easing: easings.easeOutCubic,
        },
        onResolve: () => {
          resolve();
        },
      });
    }),
    visible: (isShow) =>  {
      chipRef.current.style.opacity = isShow ? 1 : 0;
    },
    reset: (duration = 0) => new Promise((resolve) => {
      const span = chipRef.current.getElementsByTagName('span')[0];
      if (span) {
        span.innerHTML = `${span.dataset.oldvalue}`;
      }
      chipItemApi.start({
        to: {
          x: 0,
          y: 0,
        },
        config: {
          duration,
          easing: easings.easeOutCubic,
        },
        onResolve: () => {
          chipRef.current.parentElement.style.zIndex = 2;
          resolve();
        },
      });
    }),
    updateBetValue: (val, duration) => new Promise((resolve) => {
      const span = chipRef.current.getElementsByTagName('span')[0];
      if (!span) {
        resolve();
        return;
      }
      // console.log('updatBetValue,span:', span);
      const currentVal = parseFloat(span.dataset.value);
      span.dataset.oldvalue = span.innerHTML;
      const delta = parseFloat(`${val}`) - currentVal;
      if (delta === 0) {
        resolve();
        return;
      }
      if (duration === 0) {
        span.innerHTML = `${formatChip(Number(val))}`;
        resolve();
        return;
      }
      const dt = Math.min(40, duration); // Math.max(1, Math.round(duration / Math.abs(delta)));
      let t = 0;
      let time = 0;
      let intervalId = setInterval(() => {
        time += dt;
        t = Math.min(1, time / duration);
        span.innerHTML = `${formatChip(Number(currentVal + (delta * t)))}`;
        if (t >= 1) {
          clearInterval(intervalId);
          intervalId = 0;
          resolve();
        }
      }, dt);
    }),
  }), [chipItemApi]);


  return (
    <StyledChip
      isMobile={isMobile}
      ref={chipRef}
      as={animated.div}
      {...props}
      style={{
        transform: to(
          [chipItemStyle.x, chipItemStyle.y],
          (x, y) => `translate(-50%, -50%) translate(${x}px, ${y}px)`
        ),
      }}
    >
      {children}
    </StyledChip>
  );
});

export default ChipItem;
