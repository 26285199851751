import React from "react";
import { generatePath } from "../../../utils/getLanguage";

export const tagRegex = /(?:\b|:)bet:(\d{1,30})(?!\w|:)/gm;
// /(?<!\w|:)bet:(\d{1,30}(?!\w|:)+)/gm;

export const BetTag = ({ id, hasIcon = true }) => {
  const classList = ['tag bet-tag'];
  if (hasIcon) {
    classList.push('has-icon');
  }
  return <a href={generatePath(`/bet/${id}`)} className={classList.join(' ')}>
    {id}
  </a>
}

export default BetTag;
