import styled from "styled-components";
import { StyledContentWrapper } from "../../components/styles/styledContainer";
import { StyledParagraph } from "../../components/styles/styledDocumetnElemets";
import zoom from "../../assets/images/search-icon.svg";

export const StyledStakingPage = styled(StyledContentWrapper)`
  .custom-hint {
    position: relative;
    width: 0;
    transform: translate(15px, -30px);
    z-index: 100;
  }
`;

export const StyledStakingPageDescription = styled(StyledParagraph)`
  color: ${({ theme }) => theme.secondTextColor};
`;

export const StyledStakingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  & > .staking-info {

    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    & > .staking-info-wrapper {
      display: flex;
      gap: 8px;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      width: 100%;

      & > div {
        display: flex;
        flex-direction: column;
        background: ${({ theme }) => theme.staking.infoBlock.backgroundColor};
        border-radius: 2px;
        padding: 14px 9px;
        gap: 10px;
        font-size: 14px;
        width: 244px;
        scroll-snap-align: start;
        flex-shrink: 0;
        transform-origin: center center;
        min-height: 221px;

        & > div {
          width: 36px;
          height: 36px;
          border-radius: 2px;
          background: #2dab5b;
          margin-bottom: 3px;
          display: flex;
          align-items: center;
          justify-content: center;

          & > img {
            width: 18px;
          }
        }

        & > span:first-of-type {
          color: ${({ theme }) => theme.staking.infoBlock.mainTextColor};
          font-weight: 500;
        }

        & > span:last-of-type {
          color: ${({ theme }) => theme.staking.infoBlock.textColor};
        }
      }
    }
  }

`;

export const StyledStakingPlansContainer = styled.div`
  background: ${({ theme }) => theme.staking.backgroundColor};;
  display: flex;
  flex-direction: column;
  gap: 25px;
  border: 2px ${({ theme }) => theme.body} solid;
  padding: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  cursor: default;


  & > .staking-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    & > .staking-heading-tabs {
      display: flex;
      gap: 1px;

      & > button {
        display: flex;
        gap: 10px;
        position: relative;
        border: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        padding: 10px;
        text-transform: uppercase;
        margin-right: 12px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        color: ${({ theme }) => theme.staking.tabs.textColor};

        &.active {
          background: #2eab5b;
          color: #ffffff;
        }

        & > .staking-heading-counter {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          margin: 0 7px;
          right: 15px;
          min-width: 14px;
          height: 14px;
          border-radius: 7px;
          background: #46d378;
          box-shadow: 0 0 1px 4px #46d378;
        }
      }
    }

    & > .staking-search-wrapper {
      position: relative;

      & > input {
        border-radius: 3px;
        border: 1px solid ${({ theme }) => theme.paymentMethodSearch.borderColor};
        background-color: ${({ theme }) => theme.staking.search.backgroundColor};
        color: ${({ theme }) => theme.staking.search.textColor};
        font-family: inherit;
        font-size: 14px;
        line-height: 14px;
        padding: 11px 9px 13px 40px;
        position: relative;
        z-index: 2;
        transition: all 0.15s ease-in-out;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2);
        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2);
        height: 38px;
        width: 212px;

        &:focus {
          border-bottom-color: #058bd8;
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 17px;
        width: 14px;
        height: 38px;
        background: url(${zoom}) no-repeat center;
        background-size: 14px;
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
        z-index: 10;
      }
    }
  }

  & span.green {
    color: ${({ theme }) => theme.diceHistory.win} !important;
  }

  & > .staking-tabs-content {
    & > .staking-tab-content {
      display: none;
      flex-direction: column;
      position: relative;
      overflow: auto;

      &.active {
        display: flex;
      }

      & .staking-table {
        width: 100%;
        font-size: 15px;
        border: none;
        border-collapse: collapse;
        font-weight: 400;

        & > thead th {
          padding: 0 15px 6px 15px;
          color: #6b99b6;
          font-weight: 500;
          text-transform: uppercase;
        }

        & > tbody > tr {
          & > td {
            padding: 5px 15px;
            vertical-align: middle;
            text-align: center;
            height: 60px;
            font-size: 17px;
            text-wrap: nowrap;
            color: ${({ theme }) => theme.staking.table.mainTextColor};

            @media (min-width: 791px) {
              &.mobile {
                display: none;
              }
            }

            & > div.assets-col {
              display: flex;
              gap: 10px;

              & > [class^="payment-method-"], & > [class*="payment-method-"] {
                width: 36px;
                height: 36px;
              }

              & > div {
                display: flex;
                flex-direction: column;
                align-items: start;

                & > span:first-child {
                  font-size: 17px;
                  font-weight: ${({ theme }) => theme.staking.table.mainTextFontWeight};
                }

                & > span:last-child {
                  color: ${({ theme }) => theme.staking.table.textColor};
                  font-size: 14px;
                }
              }
            }

            &.percentage {
              color: #2eab5b;
            }

            & > .choose-it-btn {
              width: 134px;
              height: 38px;
              border: none;
              font-size: 13px;
              text-transform: uppercase;
              font-weight: 500;
              border-radius: 3px;
              background: #2f7bad;
              color: white;

              &:hover, &:focus {
                background: #1f97df;
              }
            }

            & > .collapse-btn {
              border: none;
              width: 38px;
              height: 38px;
              border-radius: 3px;

              &.collapsed > img {
                transform: rotateZ(-180deg);
              }

              &:hover {
                background: #00000033;
              }
            }

            &.two-rows > div {
              display: flex;
              flex-direction: column;
              align-items: start;

              & > span:first-child {
                font-size: 17px;
                font-weight: ${({ theme }) => theme.staking.table.mainTextFontWeight};
              }

              & > span:last-child {
                font-size: 14px;
                color: ${({ theme }) => theme.staking.table.textColor};
              }
            }

            @media (max-width: 790px) {
              font-size: 14px;
              padding: 5px;

              &.non-mobile {
                display: none;
              }

              & > div.assets-col {
                gap: 5px;

                & > [class^="payment-method-"], & > [class*="payment-method-"] {
                  width: 30px;
                  height: 30px;
                }

                & > div {
                  & > span:first-child {
                    font-size: 14px;
                  }

                  & > span:last-child {
                    font-size: 11px;
                  }
                }
              }

              &.two-rows > div {
                & > span:first-child {
                  font-size: 14px;
                }

                & > span:last-child {
                  font-size: 11px;
                }
              }
            }
          }

          &:nth-child(odd) {
            background-color: ${({ theme }) => theme.staking.table.tableRow.backgroundColorOdd};
          }

          &:nth-child(even) {
            background-color: ${({ theme }) => theme.staking.table.tableRow.backgroundColorEven};
          }

          &:last-child {
            position: -webkit-sticky;
            position: sticky;
          }
        }
      }

      & .staking-timer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        font-size: 17px;
        line-height: 17px;
        width: 100%;

        &.auto {
          padding: 0 10px 10px 10px;

          & > div {
            width: 100%;
          }
        }

        & > span {
          margin-bottom: 5px;
        }

        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 42px;
          width: 45px;
          border-radius: 3px;
          user-select: none;
          background: ${({ theme }) => theme.staking.table.timer.backgroundColor};

          &.active {
            border-color: #307cad;
          }

          & > span:first-child {
            font-size: 14px;
            line-height: 14px;
            font-weight: 500;
          }

          & > span:last-child {
            color: ${({ theme }) => theme.staking.table.period.textColor};
            font-size: 10px;
            line-height: 10px;
          }
        }
      }
    }
  }
}
`;

export const StyledChooseStakingPeriod = styled.div`
  display: flex;
  gap: 5px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 38px;
    min-width: 41px;
    border: solid ${({ theme }) => theme.staking.table.period.borderColor} 1px;
    border-radius: 3px;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s linear 0s;

    &.active {
      border-color: #307cad;
      background-color: ${({ theme }) => theme.staking.table.period.backgroundColorActive};
    }

    & > span:first-child {
      font-size: 14px;
      font-weight: ${({ theme }) => theme.staking.table.mainTextFontWeight};
    }

    & > span:nth-child(2) {
      color: ${({ theme }) => theme.staking.table.textColor};
      font-size: 10px;
    }

    & > span:nth-child(3) {
      font-size: 16px;
    }
  }
`;

export const StyledActivePlanInfoRow = styled.tr`

  overflow: hidden;
  background-color: ${({ theme }) => theme.staking.table.tableRow.backgroundColorCollapse} !important;

  & > td {
    padding: 0 !important;
    ${({ isCollapsed }) => !isCollapsed && 'height: 0 !important;'};
    transition: all 300ms ease-in-out;

    &:first-of-type {
      padding-left: 10px !important;
    }

    &:last-of-type {
      padding-right: 10px !important;
    }

    & > div {
      padding: 0;
      overflow: clip;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;

      & > div {
        max-height: ${({ isCollapsed }) => !isCollapsed ? '0' : '100px'};
        min-height: ${({ isCollapsed }) => !isCollapsed ? '0' : '60px'};
        transition: all 300ms ease-in-out;

        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    & > div > .two-rows {
      display: flex;
      flex-direction: column;
      align-items: start;

      & > span:first-child {
        font-size: 16px;
        font-weight: ${({ theme }) => theme.staking.table.mainTextFontWeight};
      }

      & > span:last-child {
        font-size: 13px;
        color: ${({ theme }) => theme.staking.table.textColor};
      }
    }

    @media (min-width: 791px) {
      &.due-to-date > div > div {
        width: 173px;
        align-self: center;
        margin-left: 10px;
      }

      & > div {
        padding: 0 10px;
      }
    }

    @media (max-width: 790px) {
      & > div > .two-rows {
        & > span:first-child {
          font-size: 13px;
        }

        & > span:last-child {
          font-size: 11px;
        }
      }
    }
  }
`