import React, {useContext, useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dialog from "rc-dialog";
import { showModal } from "../../../utils/consts";
import { AppContext } from "../../../App";
import LoginContainer from "../../login/LoginContainer";

import { StyledHoverNavigation, StyledMenuList, StyledNavigationWrapper } from "./styledNavigation.js";
import RegistrationContainer from "../../registration/RegistrationContainer";
import { generatePath } from "../../../utils/getLanguage";
import { useBetween } from "use-between";
import BurgerStates from "../mobileNavigation/BurgerStates";
import telegramAuthenticationConfig from "../../../utils/telegramAuthenticationConfig";
import {useLayout} from "../../../utils/useLayout";

const Navigation = () => {
  const { user, authenticated } = useContext(AppContext);
  const [visible, setVisible] = useState("");

  const [hoveLineWrapperStyle, setHoveLineWrapperStyle] = useState({
    visible: "hidden",
    opacity: 0,
    left: 20
  });
  const [hoveLineStyle, setHoveLineStyle] = useState({
    visible: "hidden",
    opacity: 0,
    width: 10
  });
  const { t } = useTranslation("header");

  const {
    handleClearAll
  } = useBetween(BurgerStates);

  const hoverNavigation = (e, hover) => {
    if (hover) {
      setHoveLineWrapperStyle((prevState) => ({
        ...prevState,
        visible: "visible",
        opacity: 1,
        left: e.target.offsetLeft + 18
      }));
      setHoveLineStyle((prevState) => ({
        ...prevState,
        visible: "visible",
        opacity: 1,
        width: e.target.offsetWidth - 18 - 18
      }));
    } else {
      setHoveLineWrapperStyle((prevState) => ({
        ...prevState,
        visible: "hidden",
        opacity: 0,
        left: e.target.offsetLeft + 18
      }));
      setHoveLineStyle((prevState) => ({
        ...prevState,
        visible: "hidden",
        opacity: 0,
        width: e.target.offsetWidth - 18 - 18
      }));
    }
  };

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');

    if (!hasVisited) {
      setVisible(showModal.REGISTRATION);
      localStorage.setItem('hasVisited', 'true');
    }

    if(!authenticated){
      const scriptMiniApp = document.createElement("script");
      scriptMiniApp.src = "https://telegram.org/js/telegram-web-app.js";
      scriptMiniApp.async = true;
      // scriptMiniApp.onload = () => {
      //   const telegram = window.Telegram?.WebApp;
      //   if (telegram && telegram?.initDataUnsafe?.user) {
      //     setVisible(showModal.LOGIN);
      //   }
      //
      // };
      document.body.appendChild(scriptMiniApp);
      return () => {
        document.body.removeChild(scriptMiniApp);
      };
    }
  }, []);

  if (!authenticated) {
    return (
      <>
        <Dialog
          visible={visible}
          wrapClassName="default-modal-wrapper"
          onClose={() => setVisible("")}
          animation="zoom"
          maskAnimation="fade"
          title={visible === showModal.LOGIN ? t("login") : t("createAccount")}
          forceRender={false}
          className="default-modal"
        >
          <div className="default-modal__content">
            {visible === showModal.LOGIN ?
              <LoginContainer visible={visible} setVisible={setVisible} /> :
              <RegistrationContainer setVisible={setVisible} />
            }
          </div>

        </Dialog>

        <StyledNavigationWrapper>
          <StyledMenuList>
            <li
              className="menu-item"
              onMouseOver={(e) => hoverNavigation(e, true)}
              onMouseOut={(e) => hoverNavigation(e, false)}
            >
              <button
                type="button"
                className="menu-item_link"
                onClick={() => setVisible(showModal.LOGIN)}
              >
                {t("login")}
              </button>
            </li>
            <li
              className="menu-item"
              onMouseOver={(e) => hoverNavigation(e, true)}
              onMouseOut={(e) => hoverNavigation(e, false)}
            >
              <button
                type="button"
                className="menu-item_link"
                onClick={() => setVisible(showModal.REGISTRATION)}
              >
                {t("createAccount")}
              </button>
            </li>
            <li
              className="menu-item"
              onMouseOver={(e) => hoverNavigation(e, true)}
              onMouseOut={(e) => hoverNavigation(e, false)}
            >
              <a
                href="https://t.me/xbet777_net"
                className="menu-item_link"
                target="_blank"
              >
                {t("support")}
              </a>
            </li>
          </StyledMenuList>
          <StyledHoverNavigation style={hoveLineWrapperStyle}>
            <div style={hoveLineStyle} />
          </StyledHoverNavigation>
        </StyledNavigationWrapper>
      </>
    );
  }

  return (
    <StyledNavigationWrapper>
      <StyledMenuList>
        <li
          className="menu-item"
          onMouseOver={(e) => hoverNavigation(e, true)}
          onMouseOut={(e) => hoverNavigation(e, false)}
        >
          <NavLink
            to={generatePath("/partners")}
            onClick={handleClearAll}
            className="menu-item_link"
            activeClassName="menu-item_link-active"
          >
            {t("partner")}
          </NavLink>
        </li>
        <li
          className="menu-item"
          onMouseOver={(e) => hoverNavigation(e, true)}
          onMouseOut={(e) => hoverNavigation(e, false)}
        >
          <NavLink
            to={generatePath("/contests")}
            onClick={handleClearAll}
            className="menu-item_link"
            activeClassName="menu-item_link-active"
          >
            {t("contests")}
          </NavLink>
        </li>
        <li
          className="menu-item"
          onMouseOver={(e) => hoverNavigation(e, true)}
          onMouseOut={(e) => hoverNavigation(e, false)}
        >
          <NavLink
            to={generatePath("/store")}
            onClick={handleClearAll}
            className="menu-item_link"
            activeClassName="menu-item_link-active"
          >
            {t("store")}
          </NavLink>
        </li>
        <li
          className="menu-item"
          onMouseOver={(e) => hoverNavigation(e, true)}
          onMouseOut={(e) => hoverNavigation(e, false)}
        >
          <NavLink
            to={generatePath("/control")}
            onClick={handleClearAll}
            className="menu-item_link"
            activeClassName="menu-item_link-active"
          >
            {t("control")}
          </NavLink>
        </li>
        <li
          className="menu-item"
          onMouseOver={(e) => hoverNavigation(e, true)}
          onMouseOut={(e) => hoverNavigation(e, false)}
        >
          <a
            href="https://t.me/xbet777_net"
            className="menu-item_link"
            target="_blank"
          >
            {t("support")}
          </a>
        </li>
      </StyledMenuList>
      <StyledHoverNavigation style={hoveLineWrapperStyle}>
        <div style={hoveLineStyle} />
      </StyledHoverNavigation>
    </StyledNavigationWrapper>
  );
};

export default Navigation;