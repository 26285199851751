import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import AccountImageContainer from "../../account/client/AccountImageContainer";
import AccountSettingsWrapper from "../../account/client/AccountSettingsWrapper";
import ThemeToggler from "../themeToggler/ThemeToggler";
import SoundToggler from "../soundToggler/SoundToggler";
import AnimationToggler from "../animationToggler/AnimationToggler";
import jackpotImg from "../../../assets/images/icons/jackpot-icon.png";
import tetherImg from "../../../assets/images/coins/small/tether.png";
import { AppContext, MercureUrl } from "../../../App";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import { jackpotConsts, responseStatus } from "../../../utils/consts";
import { closableNotification } from "../notification/ClosableNotification";
import { generatePath } from "../../../utils/getLanguage";
import { generateJWSToken } from "../../../utils/mercureAuth";
import Cookies from "js-cookie";
import SiteOptionsJackpot from "./SiteOptionsJackpot";
import http from "../../../http";

import { StyledSiteOptionsWrapper, StyledWelcomeContent } from "./styledSiteOptions";
import { roundTo } from "../../../utils/round";

const SiteOptions = ({ animationDisabled, setAnimationDisabled, isRunMagnet, isMobile, paymentMethod, isHome = false }) => {
  const path = useLocation();
  let game = "dice";
  if (path.pathname.includes("balls")) {
    game = "balls";
  } else if (path.pathname.includes("roulette")) {
    game = "roulette";
  }
  const { t } = useTranslation("siteOptions");

  const [visibleJackpot, setVisibleJackpot] = useState(false);
  const { user, authenticated } = useContext(AppContext);

  const [siteOptionsState, setSiteOptionsState] = useState({
    countOfAllBets: 0,
    sumOfWins: 0
  });
  const [jackpot, setJackpot] = useState(null);

  const fetchJackpot = () => {
    if (!paymentMethod || !game) {
      return;
    }

    http.get(`/api/game-jackpot?game=${game}&paymentMethod=${paymentMethod?.id}`,
      userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setJackpot(response.data);
      }
    }).catch(error => {
      console.log(new Error().stack);
      closableNotification(error.response.data.message, "error");
    });
  };

  const updateJackpotValue = ({ jackpotGame, value }) => {
    if (jackpotGame.toLowerCase() !== game) {
      return;
    }

    setJackpot((prevState) => ({ ...prevState, value: roundTo(value, jackpotConsts.VALUE_PRECISION) }));
  }

  const topic = "updateSiteOptions";
  const token = generateJWSToken(topic);

  useEffect(() => {
    MercureUrl.searchParams.delete("topic");

    MercureUrl.searchParams.append("topic", topic);

    Cookies.set("mercureAuthorization", token, { path: "" });

    const es = new EventSource(MercureUrl, { withCredentials: true });

    es.addEventListener("message", (event) => {
      let dataMercure = JSON.parse(event.data ?? null);
      if (!!dataMercure.jackpotGame) {
        updateJackpotValue(dataMercure);
        return;
      }

      setSiteOptionsState(dataMercure);
    });

    return () => {
      es.close();
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    http.get(
      '/api/get-count-of-all-bets-and-sum-of-wins',
      { ...userAuthenticationConfig(), signal: abortController.signal }
    ).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setSiteOptionsState(response.data);
      }
    }).catch(error => {
      console.log(new Error().stack);
      closableNotification(error.response.data.message, "error");
    });
  }, []);

  useEffect(() => {
    fetchJackpot();
  }, [paymentMethod]);

  return (
    <>
      {jackpot &&
        <SiteOptionsJackpot
          visibleJackpot={visibleJackpot}
          setVisibleJackpot={setVisibleJackpot}
          jackpot={jackpot}
          game={game}
          paymentMethod={paymentMethod}
        />
      }
      <StyledWelcomeContent isAuth={authenticated}>
        <div className="information__site user-info">
          {!authenticated && !user ?
            <React.Fragment>
              {t("welcome")}
              <span>777xbet</span>
            </React.Fragment> :
            <React.Fragment>
              {t("greeting")}
              <AccountImageContainer/>
              <NavLink to={generatePath(`/account/${user.nickname}`)}>
                <span>{user.nickname}</span>
              </NavLink>
            </React.Fragment>
          }
        </div>
        <div className="main-info">
          <div className="information__site bet">
            <span>{new Intl.NumberFormat("en-US").format(siteOptionsState?.countOfAllBets ?? 0)}</span>
            {t("bet")}
          </div>
          <AccountSettingsWrapper/>
          <div className="information__site wins">
            {t("wins")}
            <span>{new Intl.NumberFormat("en-US").format(siteOptionsState?.sumOfWins.toFixed(0) ?? 0)}</span>
            <img
              src={tetherImg}
              alt="tether"
            />
          </div>
        </div>
      </StyledWelcomeContent>
      {!isHome?
          <StyledSiteOptionsWrapper
              isMobile={isMobile}
          >
            <div className="panel">
              <div className="panel__item">
                <ThemeToggler/>
              </div>
              <div className="panel__item">
                <SoundToggler/>
              </div>
              <div
                  className="panel__item"
                  onClick={() => !isRunMagnet && setAnimationDisabled(!animationDisabled)}
              >
                <AnimationToggler animationDisabled={animationDisabled}/>
              </div>
              {/*<div className="panel__item">*/}
              {/*  <PanelToggler />*/}
              {/*</div>*/}
              {
                  isMobile && (
                      <div
                          className="jackpot"
                          onClick={() => setVisibleJackpot(true)}
                      >
                        <img
                            className="jackpot__img"
                            src={jackpotImg}
                            alt="jackpot"
                        />
                        {isMobile ? '' : t("jackpot") + ":"} {jackpot?.value} USDT
                      </div>
                  )
              }
            </div>
            {
                !isMobile && (
                    <div
                        className="jackpot"
                        onClick={() => setVisibleJackpot(true)}
                    >
                      <img
                          className="jackpot__img"
                          src={jackpotImg}
                          alt="jackpot"
                      />
                      {isMobile ? '' : t("jackpot") + ":"} {jackpot?.value} USDT
                    </div>
                )
            }
          </StyledSiteOptionsWrapper>:null}
    </>
  );
};

export default SiteOptions;
