import styled from "styled-components";
import themeIcon from "../../../assets/images/buttonIcons/theme-icon.svg";
import { hideOnMedia } from "../../styles/responsive/responsiveUtils";

export const StyledToggler = styled.button`
  .theme-icon {
    width: 11px !important;
    background: url(${themeIcon}) ${({ theme }) => theme.switchTheme} no-repeat;
    background-size: 22px 11px;
  }

  .text {
    ${hideOnMedia('xs')}
  }
`;

