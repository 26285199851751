import React from "react";
import AlertMessage from "../../elements/alert/Alert";
import { closableNotification } from "../../elements/notification/ClosableNotification";
import { Helmet } from "react-helmet-async";

import { StyledMainTitle } from "../../styles/styledTitle";
import { StyledButton } from "../../styles/styledButton";
import { StyledBubbleActions } from "../styledExamples";

const ExampleAlertMessages = () => {

  const showNotification = (e) => {
    closableNotification("Example closable notification", e);
  };

  return (
    <>
      <Helmet>
        <title>Example Alert messages • ITLab-Studio</title>
      </Helmet>

      <StyledMainTitle size="18" mb="15">
        Example Alert messages
      </StyledMainTitle>
      <AlertMessage type="info" message="Some info message" mb="20" />
      <AlertMessage type="error" message="Some error message" mb="20" />
      <AlertMessage type="success" message="Some success message" mb="20" />
      <AlertMessage type="warning" message="Some warning message" mb="20" />

      <StyledMainTitle as="p" size="18" mt="50" mb="15">
        Examples of bubble notification
      </StyledMainTitle>
      <StyledBubbleActions>
        <StyledButton color="warning" onClick={() => showNotification("warning")}>
          Warning notification
        </StyledButton>
        <StyledButton color="info" onClick={() => showNotification("info")}>
          Info notification
        </StyledButton>
        <StyledButton color="success" onClick={() => showNotification("success")}>
          Success notification
        </StyledButton>
        <StyledButton color="danger" onClick={() => showNotification("error")}>
          Danger notification
        </StyledButton>
        <StyledButton color="help"  onClick={() => showNotification("help")}>
          Help notification
        </StyledButton>
      </StyledBubbleActions>
    </>
  );
};

export default ExampleAlertMessages;