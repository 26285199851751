import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";
import PageSpinner from "../elements/spinner/PageSpinner";
import BetsDiceDetails from "./detailsByGame/BetsDiceDetails";
import CheckHonestyControl from "./CheckHonestyControl";
import BetsBallsDetails from "./detailsByGame/BetsBallsDetails";
import BetsRouletteDetails from "./detailsByGame/BetsRouletteDetails";
import { useHistory } from "react-router-dom";
import ProfileActionsWindow from "../profileActionsWindow/ProfileActionsWindow";
import { AppContext } from "../../App";
import SendTipWindow from "../profileActionsWindow/SendTipWindow";
import ReactHintFactory from "react-hint";
import { StyledBetDetailsWrapper } from "./styledDetails";

const ReactHint = ReactHintFactory(React);

const BetsDetailsContainer = ({ match }) => {
  const [bet, setBet] = useState([]);

  const history = useHistory();
  const [activeNickname, setActiveNickname] = useState(null);
  const [room, setRoom] = useState(null);
  const { authenticated } = useContext(AppContext);

  const renderHint = (target) => {
    const { id, nickname, room } = target.dataset;
    setActiveNickname(nickname);
    setRoom(room);

    return <ProfileActionsWindow
      placement="tips"
      keys={+id}
      nickname={nickname}
    />;
  };

  const fetchBetsDetail = () => {
    axios.get("/api/bets/" + match.match.params.id, userAuthenticationConfig(false)).then(response => {
      if (response.status === 200) {
        setBet(response.data);
      }
    }).catch(error => {
      history.push('/page-not-found');
    });
  };

  useEffect(() => {
    fetchBetsDetail();
  }, []);

  const renderBetDetail = (game) => {
    switch (game) {
      case "Dice": {
        return <BetsDiceDetails
          bet={bet}
        />;
      }
      case "Balls": {
        return <BetsBallsDetails
          bet={bet}
        />;
      }
      case "Roulette": {
        return <BetsRouletteDetails
          bet={bet}
        />;
      }
    }
  };

  if (!bet || bet.length === 0) {
    return <PageSpinner/>;
  }

  return (
    <StyledBetDetailsWrapper>
      <ReactHint
        persist
        attribute="data-custom"
        className="custom-hint"
        onRenderContent={renderHint}
        events={{ click: true }}
      />
      {authenticated ? <SendTipWindow
        activeNickname={activeNickname}
        room={room}
      /> : null}
      {renderBetDetail(bet.game)}
      <CheckHonestyControl bet={bet}/>
    </StyledBetDetailsWrapper>
  );
};

export default BetsDetailsContainer;