import React, { useContext } from "react";
import { useTranslation } from 'react-i18next';
import { func, string } from "prop-types";
import { themeColor } from "../../../utils/consts";

import { StyledToggler } from "./styledToggler";
import { AppContext } from "../../../App";

const ThemeToggler = () => {

  const { theme } = useContext(AppContext);

  const { t } = useTranslation('siteOptions');

  const { themeToggler, themeName } = theme;

  return (
    <StyledToggler onClick={themeToggler}>
      <span className="icon theme-icon"/>
      <span className="text">{themeName === themeColor.LIGHT ? t('day') : t('night')}</span>
    </StyledToggler>
  );
};

ThemeToggler.protoTypes = {
  theme: string.isRequired,
  toggleTheme: func.isRequired
};

export default ThemeToggler;