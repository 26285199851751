import { useEffect, useState } from "react";
import Cookies from "js-cookie";

const DiceStates = () => {
  const [animationDisabled, setAnimationDisabled] = useState(localStorage.getItem(`animationDisabled`) ? localStorage.getItem(`animationDisabled`) === 'true' : false);
  const [submitData, setSubmitData] = useState({
      game: "dice",
      suggestedNumbers: "50",
      sign: 1,
      bet: "0.00000000"
    }
  );

  const [settings, setSettings] = useState();
  const [statistic, setStatistic] = useState({
    sumOfBet: 0,
    profit: 0,
    bet: 0,
    countOfBet: 0,
    countOfWin: 0,
    countOfLose: 0,
    percentOfLuck: 0,
    countOfMessages: 0,
    maxBet: 0,
    maxProfit: 0
  });
  const [responseData, setResponseData] = useState({
    win: 0,
    manualMode: true
  });

  const [errorData, setErrorData] = useState({
    error: false,
    terminate: false,
  });

  const [winAmount, setWinAmount] = useState(0);

  useEffect(() => {
    localStorage.setItem("animationDisabled", animationDisabled.toString());
  }, [animationDisabled])

  return {
    submitData,
    setSubmitData,
    errorData,
    setErrorData,
    settings,
    setSettings,
    responseData,
    setResponseData,
    setStatistic,
    statistic,
    winAmount,
    setWinAmount,
    animationDisabled,
    setAnimationDisabled,
  };
};

export default DiceStates;
