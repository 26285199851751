import styled from "styled-components";

export const StyledSidebar = styled.aside`
  min-width: 245px;
  height: calc(100vh - 75px);
  flex: 0 0 auto;
  background-color: #333333;
  position: relative;
  transition: width 225ms cubic-bezier(.4,0,.6,1) 0ms;
`

export const StyledSidebarItem = styled.div`
  cursor: pointer;
  .sidebar-link {
    color: #fff;
    &__icon {
      width: 20px;
      height: 20px;
      font-size: 16px;
      text-align: center;
    }
    &__title {
      padding-left: 15px;
    }
    &_current {
      background: ${({theme}) => theme.defaultChangeColor};
      color: #FFFFFF;
      .sidebar-link__icon {
        opacity: 1;
      }
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      transition: all .3s ease;
    }
  }
`;

export const StyledSidebarLink = styled.div`
  width: 100%;
  padding: 20px 25px;
  border: none;
  border-bottom: 1px solid #636363;
  display: flex;
  align-items: center;
  outline: none;
`;