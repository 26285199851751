import React, {useCallback, useContext, useEffect, useRef} from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../App";
import { games } from "../../../utils/consts";

import RoulettePaymentMethodContainer from "../RoulettePaymentMethodContainer";
import SiteOptions from "../../elements/siteOptions/SiteOptions";
// import axios from "axios";
// import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
// import { responseStatus } from "../../../utils/consts";
// import { closableNotification } from "../../elements/notification/ClosableNotification";
import RouletteTable from "./RouletteTable";
import RouletteToPlayContainer from "./RouletteToPlayContainer";

// import { StyledContentWrapper } from "../../styles/styledContainer";
import {
  StyledAboutRoulette,
  StyledRotatePhone,
  StyledRouletteBetContent,
  StyledRouletteBetWrapper,
  StyledRouletteContentWrapper,
  StyledRouletteWrapper,
} from "./styledRoulette";
import { StyledBlockTitle, StyledParagraph } from "../../styles/styledDocumetnElemets";
import { fetchHonestyControl } from "../../../utils/fetchHonestyControl";
import { useLayout } from "../../../utils/useLayout";
import { useBetween } from "use-between";
import { CurrentHonestyControl } from "../../elements/leftSidebar/HonestyControl/CurrentHonestyControl";
import RouletteStates from "./RouletteStates";
import { useAutoBetsStatesRoulette } from "../../elements/autoBets/AutoBetsStates";
import rotateImg from "./../../../assets/images/rotate-device.png";
import RouletteBetsHistoryWrapper from "./RouletteBetsHistoryWrapper";
import { TEXT_RATES } from "./constants";
import { calculateChance, calculateCoefficient, calculateWinAmount, fetchSuggestedNumbers, } from "./utils";
import { GAMES } from "../Constants";

const useRouletteStates = () => useBetween(RouletteStates); // elint-disable-line;

const RouletteContainer = () => {
  const { t } = useTranslation("games");
  const {
    submitData,
    setSubmitData,
    settings,
    // setSettings,
    responseData,
    setResponseData,
    errorData,
    setErrorData,
    rouletteTableRef,
    isPlay,
    setIsPlay,
    animationDisabled,
    setAnimationDisabled,
    selectedNumber,
    rollPaymentMethodRef,
    resetBet,
  } = useRouletteStates();

  const { setCurrentHonestyControl } = useBetween(CurrentHonestyControl);
  const rouletteToPlayRef = useRef();

  /*
  autoModeIsStart,
  gameInfoRef,
  isDisableAutoBet,
  animationDisabled,
  */
  const { autoModeIsStart, autoMode, gameInfoRef, isDisableAutoBet } = useAutoBetsStatesRoulette();
  const { windowWidth, isMobile, orientation } = useLayout();

  const { currentGame, setCurrentGame } = useContext(AppContext);

  /*
  const getSettings = useCallback(async () => {
    return axios.get("/api/roulette-settings", userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        return response.data["hydra:member"].length > 0 ? response.data["hydra:member"][0] : [];
      }
    }).catch(error => {
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error.response.data.error, "error");
      }
    });
  }, []);
  */

  useEffect(() => {
    fetchHonestyControl(setCurrentHonestyControl, "Roulette");
  }, [setCurrentHonestyControl]);

  useEffect(() => {
    if (!settings) {
      return;
    }
    let suggestedNumbers = fetchSuggestedNumbers(submitData.suggestedNumbers);
    let chanceToWin = calculateChance(suggestedNumbers, settings);
    let coefficient = calculateCoefficient(suggestedNumbers, settings);
    let winAmount = calculateWinAmount(submitData.bet, coefficient);

    setResponseData((prevState) => ({
      ...prevState,
      "chanceToWin": chanceToWin,
      "coefficient": !isNaN(coefficient) ? coefficient : 0,
      "win": !isNaN(winAmount) ? winAmount : 0
    }));
  }, [submitData, setResponseData, settings]);

  const onChangeInput = useCallback((event, isMultiple = false) => {
    if (isMultiple) {
      setSubmitData((prevState) => ({ ...prevState, "suggestedNumbers": event.suggestedNumbers }));
      setSubmitData((prevState) => ({ ...prevState, "bet": event.bet }));
    } else {
      let { name, value } = event.target;
      if (name === "number") {
        value = parseInt(value);
      }
      setSubmitData((prevState) => ({ ...prevState, [name]: value }));
    }
  }, [setSubmitData]);

  useEffect(() => {
    if (currentGame !== games.roulette) {
      setCurrentGame(games.roulette);
    }
  }, []);

  function toPlayFunc(){
    rouletteToPlayRef.current.toPlay()
  }

  return (
    <>
      {
        orientation === "landscape" ?
          <>
            <StyledRouletteWrapper
              isMobile={isMobile}
            >
              <StyledRouletteContentWrapper
                roulette
                isMobile={isMobile}
              >
                <SiteOptions
                  paymentMethod={submitData?.paymentMethod}
                  animationDisabled={animationDisabled}
                  setAnimationDisabled={setAnimationDisabled}
                  isRun={isPlay}
                  isMobile={isMobile}
                />
                <RouletteTable
                  ref={rouletteTableRef}
                  submitData={submitData}
                  setSubmitData={setSubmitData}
                  handleChange={onChangeInput}
                  textRates={TEXT_RATES}
                  isPlay={isPlay}
                  autoModeIsStart={autoModeIsStart}
                  autoModeAcceleration={autoMode?.acceleration}
                  orientation={orientation}
                  selectedNumber={selectedNumber}
                  isMobile={isMobile}
                  windowWidth={windowWidth}
                  toPlay={toPlayFunc}
                  isDisableAutoBet={isDisableAutoBet}
                />
              </StyledRouletteContentWrapper>
            </StyledRouletteWrapper>
            <StyledRouletteBetWrapper>
              <StyledRouletteBetContent>

                <RoulettePaymentMethodContainer
                  ref={rollPaymentMethodRef}
                  responseData={responseData}
                  paymentMethod={submitData?.paymentMethod}
                  // submitData={submitData}
                  setSubmitData={setSubmitData}
                  isEnd={!isPlay}
                  isRoulette={true}
                  animationDisabled={animationDisabled}
                  isMobile={isMobile}
                  game={GAMES.ROULETTE}
                />

                <RouletteToPlayContainer
                  ref={rouletteToPlayRef}
                  submitData={submitData}
                  setSubmitData={setSubmitData}
                  responseData={responseData}
                  setResponseData={setResponseData}
                  errorData={errorData}
                  setErrorData={setErrorData}
                  handleChange={onChangeInput}
                  // setIsRotate={setIsRotate}
                  isPlay={isPlay}
                  setIsPlay={setIsPlay}
                  isMobile={isMobile}

                  autoModeIsStart={autoModeIsStart}
                  gameInfoRef={gameInfoRef}
                  isDisableAutoBet={isDisableAutoBet}
                  animationDisabled={animationDisabled}
                  resetBet={resetBet}
                  selectedNumber={selectedNumber}
                />
              </StyledRouletteBetContent>
            </StyledRouletteBetWrapper>
            <RouletteBetsHistoryWrapper
              fetch={responseData}
              isPlay={isPlay}
            />
          </> :
          <StyledRouletteWrapper>
            <StyledRouletteContentWrapper
              isMobile={isMobile}
              roulette
            >
              <SiteOptions
                paymentMethod={submitData?.paymentMethod}
              />
              <StyledRotatePhone>
                <img src={rotateImg} alt="rotate"/>
                <p>{t("pleaseTurnYourDevice")}</p>
              </StyledRotatePhone>
            </StyledRouletteContentWrapper>
          </StyledRouletteWrapper>

      }
      <StyledAboutRoulette orientationPortrait={orientation !== "landscape"}>
        <StyledBlockTitle pb="20" fw="100">
          {t("titleBestCasino")}
        </StyledBlockTitle>
        <StyledParagraph>
          {t("textBestCasino")}
        </StyledParagraph>
        <StyledBlockTitle pb="20" fw="100">
          {t("titleDescriptionRoulette")}
        </StyledBlockTitle>
        <StyledParagraph>
          {t("textDescriptionRoulette")}
        </StyledParagraph>
      </StyledAboutRoulette>
    </>
  );
};

export default RouletteContainer;
