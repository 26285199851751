import React, {useRef, useState} from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InputGroup from "../elements/inputGroup/InputGroup";
import basicAuthenticationConfig from "../../utils/basicAuthenticationConfig";
import LoadButton from "../elements/spinner/ButtonSpinner";
import AlertMessage from "../elements/alert/Alert";

import { StyledAuthForm } from "./styledLogin";
import { StyledButton } from "../styles/styledButton";
import { generatePath } from "../../utils/getLanguage";
import ReCaptcha from "../elements/reCaptcha/ReCaptcha";
import {showModal} from "../../utils/consts";

const LoginForm = ({ authData, setAuthData, loading, setVisible }) => {
  const { t } = useTranslation('header');
  const recaptchaRef = useRef(null);

  const [formValues, setFormValues] = useState({ email: '', password: '' });
  const [isValid, setIsValid] = useState(false);

  const validateForm = (values) => {
    const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email);
    const passwordValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(values.password);
    return emailValid && passwordValid;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    const newFormValues = {
      ...formValues,
      [name]: value
    };

    setFormValues(newFormValues);
    setIsValid(validateForm(newFormValues));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const captchaToken = recaptchaRef?.current?.getValue();
    recaptchaRef.current.reset();

    let data = {
      email: event.target.email.value,
      password: event.target.password.value
    };

    setAuthData({
      ...authData,
      body: {
        captchaToken
      },
      headers: basicAuthenticationConfig(data.email, data.password)
    });
  };

  return (
    <StyledAuthForm>
      <form onSubmit={handleSubmit}>
        <InputGroup
            id="email"
            type="email"
            label={t('enterEmail')}
            name="email"
            handleChange={handleChange}
            required
        />
        <InputGroup
            id="password"
            type="password"
            label={t('enterPassword')}
            name="password"
            handleChange={handleChange}
            required
        />
        <AlertMessage
            mt="25"
            mb="25"
            message={<React.Fragment>
              {t('forgotPassword') + ' '}
              <NavLink
                  to={generatePath('/remind')}
                  className="default-link"
                  onClick={() => setVisible("")}
              >
                {t('typeHere')}
              </NavLink>
            </React.Fragment>}
        />
        <ReCaptcha recaptchaRef={recaptchaRef}/>
        <div className="auth-action">
          {!loading ?
              <StyledButton
                  color="neutral"
                  type="submit"
                  className={isValid ? 'submit-highlight' : ''}
              >
                {t('enter')}
              </StyledButton> :
              <LoadButton
                  text={t('enter')}
                  color="neutral"
              />}
        </div>
        <p className={'login-transfer'} onClick={() => setVisible(showModal.REGISTRATION)}>{t('registerTransfer')}</p>

      </form>
    </StyledAuthForm>
  );
};

export default LoginForm;
