import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoginForm from "./LoginForm";
import LoginTwoFaForm from "./LoginTwoFaForm";
import LoginGoogleForm from "./LoginGoogleForm";
import LoginNicknameForm from "./LoginNicknameForm";
import LoginTelegramForm from "./LoginTelegramForm";

import { AppContext } from "../../App";
import { authenticationRequest } from "../../utils/authenticationRequest";

import {StyledAuthWithSocialWrapper, StyledOrAuthBlock, StyledOrAuthLine, StyledOrAuthText} from "./styledLogin";
import { StyledModalLoader } from "../elements/payment/styledPaymentInvoice";
import loadingGif from "../../assets/images/loading.gif";
import {GoogleOAuthProvider} from "@react-oauth/google";
import telegramAuthenticationConfig from "../../utils/telegramAuthenticationConfig";

const Login = ({ visible, setVisible }) => {
  const { t } = useTranslation("header");
  const { t: tE } = useTranslation("errors");

  const history = useHistory();

  const { setAuthenticated } = useContext(AppContext);

  const [existUserNickname, setExistUserNickname] = useState(false);

  const [existUser, setExistUser] = useState(false);
  const [loading, setLoading] = useState(false);

  const [authData, setAuthData] = useState({
    headers: null,
    body: null
  });

  useEffect(() => {
    authenticationRequest(authData, setAuthenticated, history, setExistUser, setLoading, setVisible, setExistUserNickname, tE);
  }, [authData]);

  const renderTwoFaOrNicknameForm = () => {
    if (!loading && !existUserNickname) {
      return <LoginNicknameForm
        loading={loading}
        authData={authData}
        setAuthData={setAuthData}
      />;
    }

    return <LoginTwoFaForm
      loading={loading}
      authData={authData}
      setAuthData={setAuthData}
    />;
  };

  if (loading) {
    return (
      <StyledModalLoader>
        <img src={loadingGif} alt={"loading..."} />
      </StyledModalLoader>
    );
  }

  return (
    !existUser ?
      <React.Fragment>
        <LoginForm
          loading={loading}
          authData={authData}
          setAuthData={setAuthData}
          setVisible={setVisible}
        />
        <StyledOrAuthBlock>
          <StyledOrAuthLine/>
          <StyledOrAuthText>{t("orUseSocial")}</StyledOrAuthText>
          <StyledOrAuthLine/>
        </StyledOrAuthBlock>
        <StyledAuthWithSocialWrapper>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <LoginGoogleForm
              authData={authData}
              setAuthData={setAuthData}
            />
          </GoogleOAuthProvider>
          <LoginTelegramForm
            authData={authData}
            setAuthData={setAuthData}
          />
        </StyledAuthWithSocialWrapper>
      </React.Fragment> :
      renderTwoFaOrNicknameForm()
  );
};

export default Login;