import styled from "styled-components";
import { StyledContentWrapper } from "../../styles/styledContainer";
import start from "../../../assets/images/level-icon.svg";
import { device } from "../../styles/responsive/responsiveUtils";

export const StyledFooterWrapper = styled.div`
  width: 100%;
  padding: 25px 0;
  background: ${({ theme }) => theme.footerBg};
  border-top: 1px #151515 solid;
`;

export const StyledFooterContainer = styled(StyledContentWrapper)`
  max-width: 1000px;
  padding-right: 10px;

  .footer-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #777777;
    line-height: normal;
    letter-spacing: 0;
    cursor: default;
    font-size: 17px;
  }

  .footer__right {
    position: relative;

    .footer-lang__active {
      display: flex;
    }

    img {
      width: 26px;
      height: 20px;
      cursor: pointer;
      border-radius: 3px;
    }
  }

  @media screen and ${device("desktop")} {
    padding: 0 20px;
  }
`;

export const StyledFooterNav = styled.nav`
  margin-top: 15px;
  color: ${({ theme }) => theme.footerColor};
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    margin-top: 25px;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      gap: 10px;
    }

    li {
      display: flex;
      align-items: center;
      line-height: 15px;
      letter-spacing: 0;
      padding: 0 6px;

      a, a:visited {
        color: #9f9f9f;
        text-shadow: 1px 2px 3px rgba(0, 0, 0, 1);
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;

        &:hover {
          color: #ffffff;
        }
      }
        
      &:first-child {
          padding-left: 0;
      }
        
      &:last-child {
          padding-right: 0;
      }

      &:not(:first-child) {
        &:before {
          content: "★";
          color: #373737;
          padding-right: 10px;
          font-size: 17px;
        }
      }

      @media screen and (max-width: 768px) {
        &:before {
          display: none !important;
        }
          
        &:first-child {
          padding-left: 6px;
        }

        &:last-child {
          padding-right: 6px;
        }
      }
    }
  }
`;