const themeColor = {
  DARK: "dark",
  LIGHT: "light"
};

const siteSound = {
  ON: "on",
  OFF: "off"
};

const showModal = {
  LOGIN: "login",
  REGISTRATION: "registration",
  CHANGE_PASSWORD: "change-password",
  CONFIG_SECURITY: "config-security"
};

const attributeNames = {
  TAG: "tag"
};

const feedbackStatusConst = {
  NOT_VIEWED: "NOT_VIEWED",
  VIEWED: "VIEWED",
  WELL_DONE: "WELL_DONE",
  DELETED: "DELETED"
};

const projectStatus = {
  WORKING: "WORKING",
  VERIFICATION: "VERIFICATION",
  VERIFICATION_FAILED: "VERIFICATION_FAILED",
  DISABLED: "DISABLED"
};

// You can get names and codes of statuses from https://httpstatuses.com/ and add status code to link
const responseStatus = {
  HTTP_OK: 200,
  HTTP_CREATED: 201,
  HTTP_NO_CONTENT: 204,
  HTTP_BAD_REQUEST: 400,
  HTTP_ERROR_VALIDATION: 422,
  FORBIDDEN: 403,
  HTTP_INTERNAL_SERVER_ERROR: 500,
  HTTP_UNAUTHORIZED: 401,
  HTTP_CONFLICT: 409
};

const waitTimer = {
  ZERO: 0,
  MAX: 60,
  CODE_EXPIRED: 600,
  INTERVAL: 1,
  INTERVAL_MS: 1000,
  SECONDS_IN_MINUTE: 60
};

const roles = {
  ADMIN: "ROLE_ADMIN",
  MANAGER: "ROLE_MANAGER",
  CLIENT: "ROLE_CLIENT",
  SYSTEM: "ROLE_SYSTEM",
  SEO: "ROLE_SEO"
};

const arrayOperation = {
  FIRST: 0
};

const regex = {
  EMAIL: "^[_a-z0-9-]+([\\._a-z0-9-])*@[a-z-]+(\\.[a-z-])*(\\.[a-z]{2,})$",
  TEXT: "^[\\'\\ʼ\\`\\-a-zA-Zа-яґіїєёА-ЯҐІЇЄЁ ]+$",
  NAME: "^[\\'\\ʼ\\`\\-a-zA-Zа-яґіїєёА-ЯҐІЇЄЁ ]+$",
  MEMO_TAG: "^[a-z0-9]{1,32}$",
};

const minLength = 10;

const mercureTopicOrigin = "http://localhost";

const mercureUrl = new URL(`${window.location.origin}/.well-known/mercure`);

const TOPICS_LIST = {
  MESSAGES: {
    UPDATE_EVENT_TOPIC: `${window.location.origin}/api/messages/`
  },
  ROOMS: {
    BOT_MESSAGE_TOPIC: `https://localhost/api/rooms-bot-message/`
  },
  BALANCES: {
    BALANCE: "https://localhost/balance/"
  },
  NOTIFICATION: {
    TOPIC: "https://localhost/notification/"
  },
  ONLINE: {
    CHECK_ONLINE: `http://localhost/api/check-online/`,
    ROOM: `http://localhost/api/online-room/`,
    GAME: "https://777xbet.net/api/online-game/"
  },
  CONTEST: {
    ALL: `https://localhost/contests/`
  }
};

const MERCURE_AUTHORIZATION_COOKIE_NAME = "mercureAuthorization";

const timeConsts = {
  DAY: 86400000,
  NORMALIZER: 1000
};

const logs_levels = {
  INFO: "INFO",
  ERROR: "ERROR",
  WARNING: "WARNING"
};

const LeftSidebarMenu = {
  GAMES: "Games",
  CHAT: "Chat",
  CONTROL: "JusticeControl",
  STATISTICS: "Statistics",
  TOKEN_BONUS: "TokenBonus"
};

const games = {
  balls: "balls",
  roulette: "roulette",
  dice: "dice"
};

let defaultCurrentStatistics = {
  sumOfBet: 0,
  profit: 0,
  bet: 0,
  countOfBet: 0,
  countOfWin: 0,
  countOfLose: 0,
  percentOfLuck: 0,
  countOfMessages: 0,
  maxBet: 0,
  maxProfit: 0
};

let defaultOurStatistics = {
  countOfMessages: 0,
  sumOfBet: 0,
  profit: 0,
  countOfBet: 0,
  countOfWins: 0,
  countOfLose: 0,
  percentOfLuck: 0
};

let jackpotConsts = {
  VALUE_PRECISION: 10
};

const canceledStatus = "canceled";
let miningConsts = {
  maxBNL: 50000000000,
  endDate: 1775782800,
  tbrRate: 1,
  secondsInDay: 86400,
  minTbrBuyValue: 1
};

let tokenBonusConsts = {
  DEFAULT_AMOUNT: 100
};

const ONLINE_ACTIVITY_TIME = 1000 * 5 * 60;

const chatRooms = {
  en: {
    title: "English"
  },
  ru: {
    title: "Russian"
  },
  br: {
    title: "Brazilian"
  },
  in: {
    title: "Indian"
  },
  es: {
    title: "Spanish"
  },
  id: {
    title: "Indonesian"
  },
  pk: {
    title: "Pakistani"
  },
  ph: {
    title: "Philippines"
  },
  tr: {
    title: "Turkish"
  },
  ua: {
    title: "Ukrainian"
  },

};

const onlineDataConsts = {
  CHAT_ROOM: "chatRoom",
  GAME: "game"
};

const contestPlaceReward = {
  1: 0.5,
  2: 0.3,
  3: 0.2,
  4: "VIP"
};

export {
  themeColor,
  siteSound,
  showModal,
  feedbackStatusConst,
  responseStatus,
  roles,
  regex,
  arrayOperation,
  mercureTopicOrigin,
  mercureUrl,
  minLength,
  timeConsts,
  logs_levels,
  projectStatus,
  LeftSidebarMenu,
  TOPICS_LIST,
  MERCURE_AUTHORIZATION_COOKIE_NAME,
  defaultCurrentStatistics,
  defaultOurStatistics,
  jackpotConsts,
  miningConsts,
  tokenBonusConsts,
  ONLINE_ACTIVITY_TIME,
  chatRooms,
  games,
  onlineDataConsts,
  contestPlaceReward,
  attributeNames,
  waitTimer,
  canceledStatus
};
