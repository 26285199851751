import styled, { css } from "styled-components";
import { device, hideOnMedia } from "../../styles/responsive/responsiveUtils";

export const StyledLeftSidebarWrapper = styled.div`
  height: 100vh;
  position: fixed;
  top: 0;
  left: ${({ visible }) => visible ? "350px" : "0"};
  z-index: 30;
  transition: all 0.25s ease-in-out;
  width: ${({ isNavigation, visible }) => isNavigation && visible ? "100%" : "0"};
  @media screen and ${device('desktop')} {
    display: none;
    #leftSidebar {
      transition: 0.5s;
      ${({ visible }) => visible && css`
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: auto;
        z-index: 100;
      `
      }
    }

    #leftSidebar p:not(.chat-header p) {
      background: unset;
    }
  }
`;

export const StyledLeftSidebarContainer = styled.div`
  height: 100vh;
  width: 350px;
  ${({ theme }) => theme.leftSidebarContainer};
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 100%;
`;

export const StyledLeftSidebarActions = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  width: ${({ isNavigation }) => isNavigation ? "100%" : "0"};

  ul {
    ${({ isNavigation }) => isNavigation && css`
      padding: 20px 20px 0 20px;
      ${hideOnMedia(580, 'min')}
    `};

    li {
      background-color: ${({ theme }) => theme.leftSidebarActions.bgColor};
      color: #fff;
      width: 64px;
      height: 71px;
      border-radius: 2px;
      margin: 0 0 1px 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 13px;
      
      cursor: pointer;
      transition: all 0.15s ease-in-out;
        
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
        
      span {
        width: 42px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
      }

      img {
        display: block;
        margin-bottom: 6px;
      }

      img, span {
        opacity: ${({ theme }) => theme.leftSidebarActions.opacityIcon};
      }

      &:hover, &.active {
        background-color: ${({ theme }) => theme.leftSidebarActions.hoverBgColor};

        img, span {
          opacity: ${({ theme }) => theme.leftSidebarActions.hoverOpacityIcon};
        }
      }
    }
  }
`;

export const StyledGamesButton = styled.li`
    background-color: ${({ theme }) => theme.mobileNav.navBlock.backgroundColor} !important;
    color: ${({ theme }) => theme.mobileNav.navBlock.color} !important;
    ${({ isNav }) => isNav && hideOnMedia(440, 'min')}

    span {
      color: #ffffff !important;
      opacity: ${({ theme }) => theme.mobileNav.navBlock.textOpacity} !important;
    }

    img {
      opacity: ${({ theme }) => theme.mobileNav.navBlock.opacity} !important;
    }
    
    &:hover, &:active {
      background-color: ${({ theme }) => theme.mobileNav.navBlock.bgHover} !important;
        
      span {
        opacity: ${({ theme }) => theme.mobileNav.navBlock.opacityHover} !important;
      }

      img {
        opacity: ${({ theme }) => theme.mobileNav.navBlock.opacityHover} !important;
      }
    }

    @media screen and (max-width: 1100px) {  
      background-color: ${({ theme }) => theme.mobileNav.mobNavBlock.backgroundColor} !important;

      span {
        color: #ffffff !important;
        opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.textOpacity} !important;
      }

      img {
        opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.opacity} !important;
      }
    
      &:hover, &:active {
        background-color: ${({ theme }) => theme.mobileNav.mobNavBlock.bgHover} !important;
        
        span {
          opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.opacityHover} !important;
        }

        img {
          opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.opacityHover} !important;
        }
      }
    }
`;

export const StyledControlButton = styled.li`
    background-color: ${({ theme }) => theme.mobileNav.navBlock.backgroundColor} !important;
    color: ${({ theme }) => theme.mobileNav.navBlock.color} !important;
    ${({ isNav }) => isNav && hideOnMedia(510, 'min')}

    span {
      color: #ffffff !important;
      opacity: ${({ theme }) => theme.mobileNav.navBlock.textOpacity} !important;
    }

    img {
      opacity: ${({ theme }) => theme.mobileNav.navBlock.opacity} !important;
    }

    &:hover, &:active {
      background-color: ${({ theme }) => theme.mobileNav.navBlock.bgHover} !important;
        
      span {
        opacity: ${({ theme }) => theme.mobileNav.navBlock.opacityHover} !important;
      }

      img {
        opacity: ${({ theme }) => theme.mobileNav.navBlock.opacityHover} !important;
      }
    }
    
    @media screen and (max-width: 1100px) {  
      background-color: ${({ theme }) => theme.mobileNav.mobNavBlock.backgroundColor} !important;

      span {
        color: #ffffff !important;
        opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.textOpacity} !important;
      }

      img {
        opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.opacity} !important;
      }
    
      &:hover, &:active {
        background-color: ${({ theme }) => theme.mobileNav.mobNavBlock.bgHover} !important;
        
        span {
          opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.opacityHover} !important;
        }

        img {
          opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.opacityHover} !important;
        }
      }
    }
`;

export const StyledStatsButton = styled.li`
    background-color: ${({ theme }) => theme.mobileNav.navBlock.backgroundColor} !important;
    color: ${({ theme }) => theme.mobileNav.navBlock.color} !important;
    ${({ isNav }) => isNav && hideOnMedia(550, 'min')}

    span {
      color: #ffffff !important;
      opacity: ${({ theme }) => theme.mobileNav.navBlock.textOpacity} !important;
    }
    
    img {
      opacity: ${({ theme }) => theme.mobileNav.navBlock.opacity} !important;
    }

    &:hover, &:active {
      background-color: ${({ theme }) => theme.mobileNav.navBlock.bgHover} !important;
        
      span {
        opacity: ${({ theme }) => theme.mobileNav.navBlock.opacityHover} !important;
      }

      img {
        opacity: ${({ theme }) => theme.mobileNav.navBlock.opacityHover} !important;
      }
    }
    
    @media screen and (max-width: 1100px) {  
      background-color: ${({ theme }) => theme.mobileNav.mobNavBlock.backgroundColor} !important;

      span {
        color: #ffffff !important;
        opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.textOpacity} !important;
      }

      img {
        opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.opacity} !important;
      }
    
      &:hover, &:active {
        background-color: ${({ theme }) => theme.mobileNav.mobNavBlock.bgHover} !important;
        
        span {
          opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.opacityHover} !important;
        }

        img {
          opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.opacityHover} !important;
        }
      }
    }
`;

export const StyledTokenButton = styled.li`
    background-color: ${({ theme }) => theme.mobileNav.navBlock.backgroundColor} !important;
    color: ${({ theme }) => theme.mobileNav.navBlock.color} !important;
    ${({ isNav }) => isNav && hideOnMedia(580, 'min')}

    span {
      color: #ffffff !important;
      opacity: ${({ theme }) => theme.mobileNav.navBlock.textOpacity} !important;
    }

    img {
      opacity: ${({ theme }) => theme.mobileNav.navBlock.opacity} !important;
    }

    &:hover, &:active {
      background-color: ${({ theme }) => theme.mobileNav.navBlock.bgHover} !important;
        
      span {
        opacity: ${({ theme }) => theme.mobileNav.navBlock.opacityHover} !important;
      }

      img {
        opacity: ${({ theme }) => theme.mobileNav.navBlock.opacityHover} !important;
      }
    }
    
    @media screen and (max-width: 1100px) {  
      background-color: ${({ theme }) => theme.mobileNav.mobNavBlock.backgroundColor} !important;

      span {
        color: #ffffff !important;
        opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.textOpacity} !important;
      }

      img {
        opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.opacity} !important;
      }
    
      &:hover, &:active {
        background-color: ${({ theme }) => theme.mobileNav.mobNavBlock.bgHover} !important;
        
        span {
          opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.opacityHover} !important;
        }

        img {
          opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.opacityHover} !important;
        }
      }
    }
`;

export const StyledChatButton = styled.li`
    background-color: ${({ theme }) => theme.mobileNav.navBlock.backgroundColor} !important;
    color: ${({ theme }) => theme.mobileNav.navBlock.color} !important;
    ${({ isNav }) => isNav && hideOnMedia(580, 'min')}

    span {
      color: #ffffff !important;
      opacity: ${({ theme }) => theme.mobileNav.navBlock.textOpacity} !important;
    }

    img {
      opacity: ${({ theme }) => theme.mobileNav.navBlock.opacity} !important;
    }

    &:hover, &:active {
      background-color: ${({ theme }) => theme.mobileNav.navBlock.bgHover} !important;
        
      span {
        opacity: ${({ theme }) => theme.mobileNav.navBlock.opacityHover} !important;
      }

      img {
        opacity: ${({ theme }) => theme.mobileNav.navBlock.opacityHover} !important;
      }
    }
    
    @media screen and (max-width: 1100px) {  
      background-color: ${({ theme }) => theme.mobileNav.mobNavBlock.backgroundColor} !important;

      span {
        color: #ffffff !important;
        opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.textOpacity} !important;
      }

      img {
        opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.opacity} !important;
      }
    
      &:hover, &:active {
        background-color: ${({ theme }) => theme.mobileNav.mobNavBlock.bgHover} !important;
        
        span {
          opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.opacityHover} !important;
        }

        img {
          opacity: ${({ theme }) => theme.mobileNav.mobNavBlock.opacityHover} !important;
        }
      }
    }
`;