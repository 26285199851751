import React from "react";
import { useTranslation } from "react-i18next";
import ExampleTab from "../../components/examples/ExampleTab";

import { StyledContainer } from "../../components/styles/styledContainer";
import { StyledMainTitle } from "../../components/styles/styledTitle";
import { StyledExamplesWrapper } from "../../components/examples/styledExamples";

const ExamplePage = () => {
  const { t } = useTranslation("siteOptions");

  return (
    <StyledContainer>
      <StyledExamplesWrapper>
        <StyledMainTitle mb="20" size="22" position="center">
            {t('exampleComponents')}
        </StyledMainTitle>
        <ExampleTab />
      </StyledExamplesWrapper>
    </StyledContainer>
  );
};

export default ExamplePage;