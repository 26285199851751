import React from "react";
import InputGroup from "../elements/inputGroup/InputGroup";

const Balance = React.forwardRef(({balance, bet, label = ""}, ref) => {

  const formatBalance = (num, precision = 8) => {
    const str = num.toString();
    const parts = str.split('.');
    if (parts.length === 1) {
      return `${str}.${'0'.repeat(precision)}`;
    }
    if (parts[1].length < precision) {
      return `${parts[0]}.${parts[1].padEnd(precision, '0')}`;
    }
    return `${parts[0]}.${parts[1].slice(0, precision)}`;
  };

  return (
      <InputGroup
          ref={ref}
          label={label}
          balance={bet}
          type="text"
          value={formatBalance(balance)}
          name="balance"
          readOnly
      />
  );
});

export default Balance;
