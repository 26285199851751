import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import SidebarLinkList from "./SidebarLinkList";
import eventBus from "../../../utils/eventBus";
import { AppContext } from "../../../App";

import { StyledSidebar, StyledSidebarItem, StyledSidebarLink } from "./styledSidebar";

const Sidebar = () => {

  const { user } = useContext(AppContext);

  const { t } = useTranslation("siteOptions");

  return (
    <StyledSidebar>
      <SidebarLinkList role={user.roles} />
      <StyledSidebarItem>
        <StyledSidebarLink
          as="button"
          className="sidebar-link"
          onClick={() => {
            eventBus.dispatch("logout");
          }}
        >
          <div className="sidebar-link__icon">
            <span className="icon-sign-out" />
          </div>
          <div className="sidebar-link__title">
            {t('output')}
          </div>
        </StyledSidebarLink>
      </StyledSidebarItem>
    </StyledSidebar>
  );
};

export default Sidebar;