import { StyledCoinConfig } from "./styledCoinConfig";
import { StyledRadioBox } from "../autoBets/styledAutoBets";
import React from "react";
import { useTranslation } from "react-i18next";
import { StyledButton } from "../../styles/styledButton";
import Cookies from "js-cookie";

const CoinConfig = ({
  coinConfig,
  setCoinConfig,
  close
}) => {
  const { t } = useTranslation("games");

  const saveConfig = (e) => {
    const type = e.currentTarget.name;
    const value = e.currentTarget.value;

    if (e.currentTarget.checked) {
      Cookies.set(type, value, { path: "" });
      setCoinConfig(oldCoinConfig => {
        if (oldCoinConfig[type] !== value) {
          oldCoinConfig[type] = value;
          return { ...oldCoinConfig };
        }
        return oldCoinConfig;
      })
    }
  }

  return <StyledCoinConfig>
    <StyledRadioBox>
      <div className="flex-inline position-relative">
        <input
          id={'assetsVisibility_visible'}
          type="checkbox"
          name={'assetsVisibility'}
          className="radio__input"
          defaultValue={'all'}
          checked={coinConfig.assetsVisibility === 'all'}
          onChange={saveConfig}
        />
        <span className="custom-radio"></span>
        <label htmlFor={"assetsVisibility_visible"}>{t("showAllAssets")}</label>
      </div>
      <div className="flex-inline position-relative">
        <input
          id={'assetsVisibility_nonzero'}
          type="checkbox"
          name={'assetsVisibility'}
          className="radio__input"
          defaultValue={'nonzero'}
          checked={coinConfig.assetsVisibility === 'nonzero'}
          onChange={saveConfig}
        />
        <span className="custom-radio"></span>
        <label htmlFor={"assetsVisibility_nonzero"}>{t("showNonZeroAssets")}</label>
      </div>
    </StyledRadioBox>
    <StyledRadioBox>
      <div className="flex-inline position-relative">
        <input
          id={'coinShownNames_name'}
          type="checkbox"
          name={'coinShownNames'}
          className="radio__input"
          defaultValue={'name'}
          checked={coinConfig.coinShownNames === 'name'}
          onChange={saveConfig}
        />
        <span className="custom-radio"></span>
        <label htmlFor={"coinShownNames_name"}>{t("showCoinNames")}</label>
      </div>
      <div className="flex-inline position-relative">
        <input
          id={'coinShownNames_balance'}
          type="checkbox"
          name={'coinShownNames'}
          className="radio__input"
          defaultValue={'balance'}
          checked={coinConfig.coinShownNames === 'balance'}
          onChange={saveConfig}
        />
        <span className="custom-radio"></span>
        <label htmlFor={"coinShownNames_balance"}>{t("showBalances")}</label>
      </div>
    </StyledRadioBox>
    <StyledButton color={'neutral'} onClick={close}>
      {t('closeSettings')}
    </StyledButton>
  </StyledCoinConfig>
}

export default CoinConfig;