import React from "react";
import BetsDetailsContainer from "../../components/bets/BetsDetailsContainer";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import HelpContainer from "../../components/home/helpSection/HelpContainer";

const BetsDetailsPage = (match) => {
  const { t } = useTranslation("siteOptions");

  return (
    <>
      <Helmet>
        <title>{t("meta.bet.title")}</title>
        <meta
          name="description"
          content={t("meta.bet.description")}
        />
        <link
          rel="canonical"
          href={"https://" + window.location.hostname + "/bet"}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/bet"}
          hrefLang="en-US"
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ru/bet"}
          hrefLang="ru-RU"
        />
      </Helmet>
      <BetsDetailsContainer match={match}/>
    </>
  );
};

export default BetsDetailsPage;