import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import InputGroup from "../inputGroup/InputGroup";
import LoadButton from "../spinner/ButtonSpinner";

import { StyledButton } from "../../styles/styledButton";
import { StyledTwoFaForm } from "./styledTwoFa";
import ReCaptcha from "../reCaptcha/ReCaptcha";

const TwoFa = ({ handleSubmit, loading, recaptchaRef }) => {
  const { t } = useTranslation("siteOptions");

  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("twofa").focus();
  }, []);


  return (
    <StyledTwoFaForm onSubmit={handleSubmit}>
      <InputGroup
        autocomplete="off"
        id="twofa"
        type="text"
        label={t('enterTwoFa')}
        name="twofa"
        className="twofa-input"
        maxLength="6"
        required
      />
      <ReCaptcha recaptchaRef={recaptchaRef}/>
      <div className="confirm-action">
        {!loading ?
          <StyledButton color="neutral" type="submit">
            {t('confirm')}
          </StyledButton> :
          <LoadButton
            text={t('confirm')}
            color="neutral"
          />
        }
      </div>
    </StyledTwoFaForm>
  );
};

export default TwoFa;