import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import HelpContainer from "../../components/home/helpSection/HelpContainer";
import KeepAlive from 'react-activation';
// import DiceContainer from "../../components/games/dice/DiceContainer";
import { StyledContainer, StyledContentWrapper } from "../../components/styles/styledContainer";
import { StyledAboutDice } from "../../components/games/dice/styledDice";
import { StyledBlockTitle, StyledParagraph } from "../../components/styles/styledDocumetnElemets";
import AlertMessage from "../../components/elements/alert/Alert";
import DiceBetsHistoryWrapper from "../../components/games/dice/DiceBetsHistoryWrapper";
const DiceContainer = React.lazy(() => import('../../components/games/dice/DiceContainer'));

const DicePage = () => {
  const { t } = useTranslation("games");

  return (
    <StyledContainer>
      <Helmet>
        <title>{t("meta.dice.title")}</title>
        <meta
          name="description"
          content={t("meta.dice.description")}
        />
        <link
          rel="canonical"
          href={"https://" + window.location.hostname}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/"}
          hrefLang="en-US"
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ru/"}
          hrefLang="ru-RU"
        />
      </Helmet>
      <HelpContainer />
      <KeepAlive>
        <DiceContainer />
      </KeepAlive>
      <DiceBetsHistoryWrapper />
      <StyledAboutDice>
        <StyledBlockTitle pb="20" fw="100">
          {t("titleBestCasino")}
        </StyledBlockTitle>
        <StyledParagraph>
          {t("textBestCasino")}
        </StyledParagraph>
        <StyledBlockTitle pb="20" fw="100">
          {t("titleDescriptionBones")}
        </StyledBlockTitle>
        <StyledParagraph>
          {t("textDescriptionBones")}
        </StyledParagraph>
      </StyledAboutDice>
    </StyledContainer>
  );
};

export default DicePage;
