import React from 'react';
import { useTranslation } from "react-i18next";
import {
  StyledStatisticsBlock,
  StyledStatisticsBlockLabel,
  StyledStatisticsBlockItem,
} from "../styledStatistics";

const StatisticsCounter = React.memo(({ percentOfLuck, countOfMessages }) => {
  const { t } = useTranslation("leftSidebar");
  return (
    <StyledStatisticsBlock className="statistic-flex" flex>
      <StyledStatisticsBlockItem className="statistic-one-block">
        <div className="num">
          {Number(percentOfLuck).toFixed(2)} %
        </div>
        <StyledStatisticsBlockLabel mb="2">
          {t("percentOfLuck")}
        </StyledStatisticsBlockLabel>
      </StyledStatisticsBlockItem>
      <StyledStatisticsBlockItem className="statistic-one-block">
        <div className="num">
          {countOfMessages}
        </div>
        <StyledStatisticsBlockLabel mb="2">
          {t("countOfMessages")}
        </StyledStatisticsBlockLabel>
      </StyledStatisticsBlockItem>
    </StyledStatisticsBlock>
  )
});

export default StatisticsCounter;
