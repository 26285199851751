import { useEffect, useState } from "react";
import { themeColor } from "./consts";

export const useDarkMode = () => {
  const [theme, setTheme] = useState(themeColor.DARK);
  const [mountedComponent, setMountedComponent] = useState(false);

  const setMode = (mode) => {
    localStorage.setItem("theme", mode);
    setTheme(mode);
  };

  const themeToggler = () => {
    theme === themeColor.LIGHT ? setMode(themeColor.DARK) : setMode(themeColor.LIGHT);
  };

  useEffect(() => {
    const localTheme = localStorage.getItem("theme");

    if (localTheme) {
      setTheme(localTheme === themeColor.LIGHT ? themeColor.LIGHT : themeColor.DARK);
    }

    setMountedComponent(true);
  }, []);

  return [theme, themeToggler, mountedComponent];
};