import styled from "styled-components";

export const StyledHelpWrapper = styled.section`
  padding: 15px 5%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${({theme}) => theme.elementBg};
  span {
    padding: 3px 7px;
    margin-right: 10px;
    color: #FFFFFF;
    border-radius: 2px;
    background: #F19117;
    cursor: default;
  }
  p {
    cursor: default;
  }
  a {
    cursor: pointer;
    transition: all .3s ease-out;
    &:hover {
      color: ${({theme}) => theme.subTitleColor};
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;