import styled from "styled-components";

const typeStyled = {
  as: String
}

const styledList = (as) => {
  switch (as) {
    case 'ol':
      return `
        counter-reset: item;
        li:before {
          content: counter(item) ".";
          counter-increment: item;
        }
      `;
    default:
      return `
        li:before {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #058bd8;
          top: 13px;
        }
      `;
  }
}
// lower-alpha
export const StyledList = styled('ul', typeStyled)`
  margin-bottom: 20px;
  ${({size}) => size && `font-size: ${size}px`};
  ${({as}) => styledList(as)}
  li {
    padding: 0 0 20px 30px;
    position: relative;
    ${({countStart}) => countStart && `
      padding-left: 36px;
      &:before {
        content: "${countStart}." counter(item) ".";
    }`};
    &:before {
      position: absolute;
      left: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  b {
    padding-bottom: 10px;
    color: #058bd8;
    font-size: 22px;
    font-weight: 500;
    display: block;
  }
  ${({listStyle}) => listStyle === 'alpha' &&
  `list-style: lower-alpha;
       padding-left: 22px;
       li {padding-left: 5px !important;}
       li:before {display: none;}
  ` };
`;

export const StyledBlockTitle = styled.h2`
  ${({ pt }) => pt && `padding-top: ${pt}px`};
  ${({ pb }) => pb && `padding-bottom: ${pb}px`};
  color: #058bd8;
  font-weight: 500;
  font-size: 26px;
  line-height: 26px;
  ${({ fw }) => fw && `font-weight: ${fw}`};
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const StyledParagraph = styled.p`
  letter-spacing: 0;
  line-height: normal;
  a {
    color: rgb(119, 119, 119) !important;
    border-bottom: 1px dashed rgb(119, 119, 119) !important;
  }
  
  &:not(:last-child) {
    padding-bottom: 40px;
  }
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
  .hover-white:hover {
    color: #fff !important;
    border-color: #fff !important;
  }
  a.gray {
    color: #9f9f9f !important;
    border-bottom: 1px dashed #9f9f9f !important;
  }
`;

export const StyledDocumentImage = styled.figure`
  ${({center}) => center && 'margin: 0 auto;'};
  ${({mt}) => mt && `margin-top: ${mt}px`};
  max-width: ${({width}) => `${width}px`};
  width: 100%;
  height: 255px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #cde9ff;
  background-color: #f5f9fc;
  transition: all .3s ease;
  figcaption {
    padding-top: 30px;
    color: #000;
    font-size: 17px;
    font-weight: 600;
  }
  &:hover {
    border-color: transparent;
    background: linear-gradient(20deg, #2F69FF -46.39%, #15BDF3 68.48%), #5DC3E7;
    box-shadow: 0 15px 20px rgb(135 185 202 / 50%);
    img {
      filter: grayscale(100%) brightness(330%) contrast(350%);
    }
    figcaption {
      color: #fff;
    }
  }
  
  @media screen and (max-width: 992px) {
    height: 130px;
    padding: 25px;
    flex-direction: row;
    justify-content: flex-start;
    img {
      width: 55px;
      height: auto;
    }
    figcaption {
      padding: 0 0 0 30px;
    }
  }
  @media screen and (max-width: 768px) {
    height: 85px;
    img {
      width: 40px;
    }
    figcaption {
      padding: 0 0 0 20px;
    }
  }
`;