import {listLanguage} from "./listLanguage";
import i18n from "i18next";

export const lang = () => document.cookie.split(';').filter((item) => item.includes('lang'))[0]?.split('=')[1];

export const generatePath = (path, isHistory = false) => {
  const language = lang();

  let isLang = false;
  const pieceOfPath = path.split('/');

  listLanguage.listLang.forEach((item) => {
    if (pieceOfPath[1] === item && !isLang) {
      isLang = true;
    }
  })

  if (isHistory) {
    if (pieceOfPath[1] && isLang) {
      document.cookie = `lang=${pieceOfPath[1]}; path=/; max-age=-1`;
      document.cookie = `lang=${pieceOfPath[1]}; path=/`;
    } else {
      document.cookie = `lang=${listLanguage.defaultLang}; path=/; max-age=-1`;
      document.cookie = `lang=${listLanguage.defaultLang}; path=/`;
    }

    if (i18n.language !== lang()) {
      i18n.changeLanguage(lang());
    }

    return path;
  }

  if (language !== listLanguage.defaultLang) {
    return `/${language}${path}`;
  }

  return path;
}
