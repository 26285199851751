import React from "react";
import Notification from "rc-notification";

let notification = null;
Notification.newInstance({ maxCount: 20, animation: 'fade' }, (n) => notification = n);

export const closableNotification = (message, type) => {
  notification.notice({
    content: <span id="closableNotification" className={`message-with-icon ${type}`}>{message}</span>,
    duration: 8,
    closable: true,
    key: Date.now()
  });
};