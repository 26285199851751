import { responseStatus } from "./consts";

const parseAxiosError = (response) => {
  let errors = {};
  if (!response) {
    return errors;
  }

  if (response.data.status === responseStatus.HTTP_UNAUTHORIZED || response.data.status === responseStatus.HTTP_INTERNAL_SERVER_ERROR) {
    errors['internal'] = response.data.detail
  } else if (response.data.status === responseStatus.FORBIDDEN) {
    errors['user'] = response.data.detail
  }
  if (response.data?.violations) {
    response.data?.violations.map(({ title, propertyPath }) => {
      let name = propertyPath.replaceAll("[", "").replaceAll("]", "");
      errors[name] = title;
    })
  }
  return errors;
};

const parseIRI = (IRI) => {
  const regex = /\d+/i;
  return +IRI.match(regex)[0];
};

const parseUuidIRI = (IRI) => {
  return IRI.split("/")[3];
};

const hideCreditCardSigns = (cardNumber) => {
  let cardNumberFirst = cardNumber.slice(0, 6);
  let cardNumberLast = cardNumber.substr(cardNumber.length - 4);
  return cardNumberFirst + "******" + cardNumberLast;
};

export {
  parseAxiosError,
  parseUuidIRI,
  parseIRI,
  hideCreditCardSigns
}