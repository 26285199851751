import React, { useRef } from "react";
import TwoFa from "../elements/twoFa/TwoFa";

const LoginTwoFaForm = ({ authData, setAuthData, loading }) => {

  const recaptchaRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    const captchaToken = recaptchaRef?.current?.getValue();
    recaptchaRef.current.reset();

    setAuthData({
      ...authData,
      body: {
        twofa: event.target.twofa.value,
        captchaToken
      }
    });
  };

  return (
    <TwoFa handleSubmit={handleSubmit} loading={loading} recaptchaRef={recaptchaRef}/>
  );
};

export default LoginTwoFaForm;