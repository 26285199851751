import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledBlockContent } from "../../banners/styledBannersPage";
import { getRemainingTime } from "../../../utils/timestampToDate";
import ActivePlanRow from "./ActivePlanRow";

export const ActivePlansTab = ({ stakingData, setStakingData, searchAsset, formatAmount, active = false }) => {
  const { t } = useTranslation('staking');
  const { t: gT } = useTranslation("games");

  const [timers, setTimers] = useState([]);

  const fetchData = async () => {
    return [];
  }

  const calcTime = useCallback(() => {
    if (stakingData.activePlans) {
      if (stakingData.activePlans.length > 0) {
        const result = stakingData.activePlans.map((plan, index) => {
          return getRemainingTime(plan.dueTo);
        })
        setTimers(result);
      } else {
        setTimers([])
      }
    }
  }, [stakingData]);


  useEffect(() => {
    if (active) {
      setStakingData(old => ({ ...old, loading: true }));
      fetchData().then(activePlans => {
        const newData = { activePlans };
        if (active) {
          newData.loading = false;
        }
        setStakingData(old => ({ ...old, ...newData }))
      }).catch(error => {
        if (active) {
          setStakingData(old => ({ ...old, loading: false }));
        }
      })
    }
  }, [active, setStakingData]);

  useEffect(() => {
    if (active) {
      calcTime();
    }
  }, [active, calcTime, stakingData]);

  useEffect(() => {
    if (active) {
      const intervalId = setInterval(() => {
        calcTime();
      }, 1000)
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [calcTime, timers, active]);

  const content = useMemo(() => {
    if (stakingData.loading) {
      return <StyledBlockContent className={'uppercase'}>{gT('loading')}</StyledBlockContent>
    }

    if (!stakingData.loading && (!stakingData.activePlans || stakingData.activePlans.length === 0)) {
      return <StyledBlockContent className={'uppercase'}>{gT('nothingFound')}</StyledBlockContent>
    }

    return <div>
      <table className={'staking-table'}>
        <tbody>
        {stakingData.activePlans
          .filter((item) => (
            item.currency.asset.toLowerCase().includes(searchAsset.toLowerCase()) ||
            item.currency.name.toLowerCase().includes(searchAsset.toLowerCase())
          ))
          .map((plan, index) => <ActivePlanRow
            plan={plan}
            key={index}
            index={index}
            t={t}
            timers={timers}
            formatAmount={formatAmount}
          />)}
        </tbody>
      </table>
    </div>
  }, [stakingData, stakingData, gT, searchAsset, t, timers, formatAmount]);

  return <div className={'staking-tab-content' + (active ? ' active' : '')}>
    {content}
  </div>
}

export default ActivePlansTab;