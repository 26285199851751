import { useState } from "react";
import { defaultCurrentStatistics } from "../../../../utils/consts";

export const MainShareableStatistics = () => {

  const [currentStatistics, setCurrentStatistics] = useState(defaultCurrentStatistics);

  return {
    currentStatistics,
    setCurrentStatistics
  };
};