import styled from "styled-components";
import { StyledContentWrapper } from "../styles/styledContainer";

// This file is an example and is not intended to be copied!
// The Styled components in this file should only be used in ExamplePage.jsx and his childes

export const StyledExamplesWrapper = styled(StyledContentWrapper)`
  padding-top: 50px;
`;

export const StyledButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 250px) max-content;
  grid-gap: 20px;
  &:not(&:last-child) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 576px) {
    grid-template-columns: 100%;
  }
`;

export const StyledBubbleActions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 175px);
  grid-gap: 20px;
`;

export const StyledHomeWrapper = styled.div`
  padding-top: 50px;
  
  .started-content {
    padding: 50px 25px;
    text-align: center;
    p {
      padding: 15px 0;
    }
  }
`;