import React from "react";
import { NavLink } from "react-router-dom";
import { generatePath } from "../../../utils/getLanguage";
import { TimestampToAgoFormat } from "../../../utils/timestampToDate";
import { StyledHistoryBet, StyledHistoryDate, StyledHistoryLink } from "../../styles/styledHistory";
import { StyledPaymentMethodsIcons } from "../../styles/StyledPaymentMethodsIcons";
// import { StyledDiceHistoryProgress } from './styledDice'

const BallsBetsListItem = React.memo(({ value, user, handleProfileActionsVisible }) => {
  // const { t } = useTranslation("games");
  // console.log('render BallsBetsListItem');
  return (
    <tr>
      <td data-title="ID">
        <StyledHistoryLink as={NavLink} to={`/bet/${value.id}`}>
          <div>
            {new Intl.NumberFormat("en-US").format(value.id)}
          </div>
        </StyledHistoryLink>
      </td>
      <td data-title="Время / Игрок">
        <StyledHistoryDate>
          {TimestampToAgoFormat(value.createdAt)}
        </StyledHistoryDate>
        <StyledHistoryLink as={NavLink} to={generatePath(`/account/${value.user.nickname}`)}>
          <div>
            {value.user.nickname}
          </div>
        </StyledHistoryLink>
      </td>
      <td data-title="Валюта" className="balls-history-table__currency">
        <StyledPaymentMethodsIcons className={`payment-method-${value.paymentMethod.currency.asset}`}/>
      </td>
      <td data-title="Прогноз / Выпавший номер" className="balls-history-table__number">
        {value.suggestedNumbers} / {value.drawnNumber}
      </td>
      <td data-title="Ставка">
        <StyledHistoryBet losing={parseFloat(value.lose) === 0}>
                <span>
                  {value.gameObject.chanceToWin}%
                </span>
          {value.bet} {value.paymentMethod.currency.asset}
        </StyledHistoryBet>
      </td>
      <td data-title="Выигрыш">
        <StyledHistoryBet losing={parseFloat(value.lose) === 0}>
                <span>
                  {parseFloat(value.lose) === 0 ? parseFloat(value.gameObject.coefficient).toFixed(4).slice(0, -1) : 0}x
                </span>
          {parseFloat(value.lose) === 0 ? (parseFloat(value.bet) * value.gameObject.coefficient).toFixed(8) : (0).toFixed(8)} {value.paymentMethod.currency.asset}
        </StyledHistoryBet>
      </td>
    </tr>
  );
});

export default BallsBetsListItem;
