import styled from "styled-components";
import { StyledPageTemplateWrapper } from "../../../styles/styledPageTemplate";
import editIcon from "../../../../assets/images/icons/edit-icon.svg";
import {device, hideOnMedia} from "../../../styles/responsive/responsiveUtils";

export const StyledClientDetailsWrapper = styled(StyledPageTemplateWrapper)`
  padding-top: 25px;

  .account-wrapper {
    padding-top: 35px;
    margin: 10px 0 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @media screen and ${device(599, 'min')} and ${device(799)} {
      gap: 4%;
      margin: 10px 0 20px;;
    }

    @media screen and ${device(800, 'min')} {
      gap: 3%;
      margin: 10px 0 20px;
    }

    @media screen and ${device('desktop', 'min')} {
      margin: 10px 23px 20px;
    }

    &__info {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media screen and ${device(599, 'min')} and ${device(800)} {
        width: 60%;
      }

      @media screen and ${device(800, 'min')} {
        width: 70%;
      }
    }

    &__stats {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      gap: 4%;

      @media screen and ${device(800, 'min')} {
        gap: 2%;
      }
    }

    &__other {
      margin-bottom: 33px;
      @media screen and ${device('md')} {
        justify-content: space-around;
        margin-bottom: 0;
      }

      & > div {
        width: calc((100% - 80px) / 5);
        @media screen and ${device('md')} {
          width: 100px;
          margin-bottom: 33px;
        }
      }
    }

    @media screen and (max-width: 1100px) {
      //grid-template-rows: 250px 1fr;
      grid-template-columns: unset;
    }
    //@media screen and (max-width: 992px) {
    //  grid-template-columns: 100%;
    //}
  }

  .account-statistics-table {
    border: 1px solid ${({ theme }) => theme.tableRow.borderColor};

    &__head, &__row {
      grid-template-columns: repeat(5, 60px) repeat(2, minmax(125px, 1fr));
      border: none;
    }

    &__currency {
      span {
        margin: 0 auto;
      }
    }

    &__green {
      color: #298f4e;
    }

    &__red {
      color: #942a39;
    }
  }

  .default-tabs {
    gap: 25px;

    .rc-tabs-tab {
      font-weight: 500 !important;
      color: ${({ theme }) => theme.subTitleColor} !important;
      margin-right: 25px !important;

      &.rc-tabs-tab-active {
        border: none;
        color: ${({ theme }) => theme.defaultChangeColor} !important;
      }
    }
  }
`;

const bgColor = (color) => {
  switch (color) {
    case "wins":
      return `
        .activity-img {
          background-color: #298f4e;
        }
        b {
          color: #298f4e;
        }
      `
    case "lose":
      return `
        .activity-img {
          background-color: #942a39;
        }
        b {
          color: #942a39;
        }
      `
    default:
      return `
        .activity-img {
          background-color: #307cad;
        }
      `;
  }
}

export const StyledBetsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 2px 23px 0;
  gap: 33px 20px;
  padding-top: 25px;

  @media screen and ${device('desktop')}{
    gap: 1.5% 1%;
    margin: 0;
  }
`;

export const StyledAccountActivityItem = styled.div`
  width: 100%;
  min-height: 75px;
  padding: 12px 20px;
  line-height: 1;
  background-color: ${({ theme }) => theme.accountActivityItemBg};
  box-shadow: ${({ theme }) => theme.accountActivityItemShadow};
  border-radius: 2px;
  text-align: center;
  margin-bottom: 35px;

  @media screen and ${device(379, 'min')} and ${device(800)} {
    width: 48%;
  }
  
  .activity-img {
    position: relative;
    width: 40px;
    height: 40px;
    margin: -35px auto 7px;
    border: 0 solid #000000;
    border-radius: 100%;
    box-shadow: ${({ theme }) => theme.accountActivityImgShadow};
    display: flex;
    align-items: center;
    justify-content: center;
    
    & > img {
      width: 60%;
    }
  }

  .user-avatar img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  .currency {
    background-color: transparent !important;
    box-shadow: none;
  }

  b {
    display: block;
    color: ${({ theme }) => theme.subTitleColor};
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 10px;

    @media screen and ${device('md')} {
      font-size: 14px;
    }
    
    @media screen and ${device('md', 'min')} and ${device('desktop')} {
      font-size: 16px;
    }

    &.win {
      color: ${({ theme }) => theme.diceHistory.win};
    }

    &.lose {
      color: ${({ theme }) => theme.diceHistory.lose};
    }
  }

  span {
    color: #777777;
    font-size: 16px;

    @media screen and ${device('desktop')} {
      font-size: 13px;
    }
  }

  @media screen and ${device('md')} {
    height: unset;
  }
  ${({ color }) => bgColor(color)};

  .userIcon {
    border-radius: 50%;
    background: ${({ theme }) => theme.elementBg};
  }

  &.box-1-3, &.box-1-4 {
    height: unset;
    width: 210px;
    padding: 12px 16px;
    
    @media screen and ${device('sm')}{
      width: 49.5%;
    }
    
    @media screen and ${device('sm', 'min')} and ${device('desktop')}{
      width: 27%;
    }

    b {
      padding-bottom: 3px;
      overflow-x: auto;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 12px;
        height: 7px;
        background-color: ${({ theme }) => theme.body};
      }

      &::-webkit-scrollbar-thumb {
        height: 8px;
        border: 1px solid ${({ theme }) => theme.body};
        background-color: ${({ theme }) => theme.neutralColor};
        border-radius: 4px;
      }
    }
  }

  &.box-1-4 {
    width: 150px;
    padding: 12px 0;

    @media screen and ${device('sm')}{
      width: 49.5%;
    }

    @media screen and ${device('sm', 'min')} and ${device('desktop')}{
      width: 19%;
    }
  }

  .level {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.subTitleColor};
    text-align: center;
    font-size: 11px;
    border-radius: 50%;
    background: ${({ theme }) => theme.accountActivityItemBg};
    border: 2px solid #215be3;
    z-index: 10;
    box-shadow: -1px 1px 5px rgb(0 0 0 / 80%);
    cursor: pointer;
  }

  .multiply {
    width: 30px;
  }

  .percent {
    width: 25px;
  }
`;

export const StyledAccountActivityItemLg = styled(StyledAccountActivityItem)`
  @media screen and ${device(800, 'min')} {
    width: 32%;
  }
`;

export const StyledAccountActivityItemSm = styled(StyledAccountActivityItem)`
  @media screen and ${device(800, 'min')} {
    width: 18.4%;
  }
`;

export const StyledAccountImage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and ${device(599, 'min')} and ${device(800)} {
    width: 36%;
  }

  @media screen and ${device(800, 'min')}  {
    width: 27%;
  }
  
  .user-info {
    width: 100%;
    padding: 0;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.accountActivityItemBg};
    box-shadow: ${({ theme }) => theme.accountActivityItemShadow};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &__nickname {
      max-width: 220px;
      color: ${({ theme }) => theme.subTitleColor};
      font-size: 26px;
      margin: 0 0 16px 0;
      text-align: center;
      word-wrap: break-word;
      word-break: break-all;
      
      @media screen and ${device('desktop', 'min')} {
        font-size: 30px;
      }
    }

    &__level {
      width: 30px;
      height: 30px;
      color: ${({ theme }) => theme.subTitleColor};
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
      position: absolute;
      top: -40px;
      right: 10px;
      border: 2px solid #215be3;
      background-color: ${({ theme }) => theme.accountActivityItemBg};
      border-radius: 100%;
      z-index: 10;
      cursor: pointer;
    }

    &__image {
      width: 140px;
      height: 140px;
      margin: -50px auto 0;
      background-color: ${({ theme }) => theme.accountActivityItemBg};
      box-shadow: ${({ theme }) => theme.accountAvatarShadow};
      border: 5px solid ${({ theme }) => theme.accountAvatarBorderColor};
      border-radius: 50%;
      overflow: hidden;
      display: inline-block;
      position: relative;
      
      @media screen and ${device('desktop', 'min')} {
        width: 160px;
        height: 160px;
        margin-bottom: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }

    &_edit {
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4) url(${editIcon}) 50% 50% no-repeat;
        background-size: 25%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
        border-radius: 45px;
        opacity: 0;
      }

      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }

    span {
      padding-left: 7px;
    }

    &__footer {
      background: ${({ theme }) => theme.accountInfoFooterColor};
      width: 100%;

      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 26px;
      opacity: 0.8;

      @media screen and ${device('md', 'min')} {
        height: 53px;
      }

      & > button {
        border: none;
        opacity: 0.6;
        height: 53px;

        &:hover {
          opacity: 1;
          -webkit-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
          -ms-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }
      }
    }
  }

  //@media screen and (max-width: 992px) {
  //  padding-bottom: 20px;
  //  display: flex;
  //  justify-content: center;
  //}

  .user-friends {
    background: ${({ theme }) => theme.accountFriends.backgroundColor};
    border: ${({ theme }) => theme.accountFriends.border};
    margin: 25px 0 40px 0;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    @media screen and ${device('md', 'min')} {
      padding: 20px;
    }

    &__title {
      font-size: 18px;
      color: ${({ theme }) => theme.accountFriends.title.color};
      line-height: normal;

      b {
        color: ${({ theme }) => theme.accountFriends.title.counterColor};
        font-weight: 500;
      }

      @media screen and ${device('md', 'min')} {
        font-size: 20px;
        margin-left: 6px;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 14px 19px;
      align-items: center;
      padding: 7px 5px;

      &.empty {
        padding: 0;
      }

      &__item {
        width: 50px;
        height: 50px;
        position: relative;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }

        &__level {
          position: absolute;
          right: -4px;
          top: -4px;
          width: 19px;
          height: 19px;
          line-height: 15px;
          text-align: center;
          font-size: 10px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          z-index: 2;
          ${({ theme }) => theme.accountFriends.item.level};
        }
      }
    }
  }

  .custom-hint {
    position: relative;
    width: 0;
    transform: translate(15px, -35px);
    z-index: 100;
  }
`;

export const StyledActivityAddToFriendsImg = styled.img
  .attrs(props => ({
    src: props.theme.accountImages.addToFriends
  }))`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

export const StyledActivitySendMessageImg = styled.img
  .attrs(props => ({
    src: props.theme.accountImages.sendMessage
  }))`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

export const StyledActivitySendTipImg = styled.img
  .attrs(props => ({
    src: props.theme.accountImages.sendTip
  }))`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

export const StyledActivityTable = styled.ul`
  display: flex;
  flex-direction: column-reverse;

  li {
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    ${({ theme }) => `
      color: ${theme.readingPage.color};
      background-color: ${theme.accountActivityRowBg};
      border: ${theme.accountActivityRowBorder};
    `};

    @media screen and ${device('sm')} {
      padding: 20px 14px;
    }
    
    &:first-child {
      margin-bottom: 0;
    }

    .activity-list_icon {
      width: 41px;
      min-width: 41px;
      height: 41px;
      background-color: #2dae5c;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 25px;

      img {
        max-width: 100%;
      }

      @media screen and ${device('sm')} {
        width: 34px;
        min-width: 34px;
        height: 34px;
        margin-right: 10px;
      }
    }

    p {
      color: ${({ theme }) => theme.accountActivityItemTextColor};
    }

    .activity-list_title {
      margin-right: auto;
      font-size: 17px;

      p {
        text-wrap: normal;
        line-break: strict;
      }

      time {
        font-size: 14px;
        margin-top: 3px;
      }

      @media screen and ${device('sm')} {
        font-size: 14px;

        time {
          font-size: 12px;
        }
      }
    }

    .activity-list__sum {
      display: flex;
      align-items: center;
      justify-content: center;
      word-wrap: initial;
      white-space: nowrap;
      font-size: 16px;
      margin-left: 15px;

      span {
        display: block;
        margin-right: 8px;
      }

      p {
        height: 20px;
      }

      @media screen and ${device('sm')} {
        margin-left: 5px;
      }
    }

    @media screen and (max-width: 500px) {
      flex-wrap: wrap;
    }
  }
`;

export const StyledUserLevel = styled.div`
  .main-client-details {
      border-radius: 3px;
      padding: 20px;
      background-color: ${({ theme }) => theme.userLevel.background};
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px ${({ theme }) => theme.userLevel.border} solid;
      
      .right-side {
          span {
              font-size: 16px;
              line-height: 20px;
              color: ${({ theme }) => theme.userLevel.color};
              font-weight: 500;
          }
          ${hideOnMedia(800)}
      }
      
      .left-side {
          display: flex;
          
          .star-block {
              background-color: ${({ theme }) => theme.userLevel.starBg};
              height: 40px;
              width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 100%;
              margin-right: 25px;
              margin-top: 5px;
              
              img {
                  height: 22px;
                  width: 22px;
              }
          }
          
          .details-block {
              display: flex;
              flex-direction: column;
              .level-info {
                  color: ${({ theme }) => theme.userLevel.levelColor};
                  font-size: 26px;
                  line-height: 26px;
                  margin-bottom: 5px;
                  font-weight: 500;
              }
              
              .points-info {
                  font-size: 14px;
                  color: ${({ theme }) => theme.userLevel.desColor};
                  font-weight: 400;
              }
          }
      }
  } 
  @media screen and ${device(800, 'max')} {
    .main-client-details {
      padding: 15px 20px;
      .right-side {
        font-size: 15px;
        line-height: 20px;
      } 
      .left-side {
        .star-block {
          height: 34px;
          width: 34px;
          margin-right: 16px;
          margin-top: 5px;
        }
        .details-block {
          .level-info {
            font-size: 22px;
            line-height: 22px;
          }
          .points-info {
            font-size: 12px;
          }
        }
      }  
    }  
  }  
`;

export const StyledUserLevelItem = styled.div`
    margin-top: 20px;
    padding: 20px;
    border: 1px ${({ theme }) => theme.userLevelItem.border} solid;
    background: ${({ theme }) => theme.userLevelItem.background};
    border-radius: 3px;
    .title-level-block {
        margin-bottom: 15px;
        font-size: 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .title-level-block b:last-child {
        color: ${({ theme }) => theme.userLevelItem.pointsColor};
        ${hideOnMedia(800)}
    }
    .title-level-block b:first-child {
        color: ${({ theme }) => theme.userLevelItem.titleColor};
    }
    .percent-block {
        background: ${({ theme }) => theme.userLevelItem.percentBg};
        width: 100%;
        border-radius: 5px;
        display: flex;
        overflow: hidden;
        
        .line-border {
            width: 1px;
            height: 20px;
            background-color: ${({ theme }) => theme.userLevelItem.percentLine};
        }
        
        span {
            font-weight: bold;
            color: ${({ theme }) => theme.userLevelItem.percentColor};
            font-size: 13px;
            line-height: 20px;
            margin-left: 10px;
        }
    }
    .info-block {
        margin-top: 12px;
        font-size: 13px;
        color: ${({ theme }) => theme.userLevelItem.descColor};
        line-height: 17.5px;
    }
    @media screen and ${device(800, 'max')} {
      padding: 15px 20px;
      .title-level-block {
        margin-bottom: 12px;
      }
      .info-block {
        margin-top: 10px;
      }
    }
`;