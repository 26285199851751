import React from "react";
import { StyledRadioBox, StyledTitle } from "./styledAutoBets";
import InputGroup from "../inputGroup/InputGroup";
import { useTranslation } from "react-i18next";

const AutoBetsLimits = React.memo(({ autoMode, setAutoMode }) => {
  const { t } = useTranslation("games");

  return (
    <StyledRadioBox>
      <StyledTitle>{t("limits")}</StyledTitle>
      <div className="flex-inline between">
        <label className="limits-label without-padding" htmlFor="maxCountOfBet">{t("limitNumberOfBetsBy")}</label>
        <InputGroup
          type="text"
          id="maxCountOfBet"
          placeholder="0"
          value={autoMode["maxCountOfBet"]}
          onChange={(data) => {
            const inputValue = data.target.value;
            let values = { ...autoMode };
            if ((!isNaN(Number(inputValue)) && Number(inputValue) >= 1 && Number(inputValue) <= 9999) || inputValue === '') {
              values["maxCountOfBet"] = inputValue === '' ? '' : Number(inputValue);
              setAutoMode(values);
            } else {
              setAutoMode(values);
            }
          }}
          paddingInput="5px 8px 7px"
          marginInput="-2px 5px 0 10px"
          width="150px"
          height="33px"
          textAlign="center"
          fontSizeInput="16px"
          autoInput={true}
        />
      </div>

      <div className="flex-inline between">
        <label className="limits-label without-padding"
               htmlFor="stopByMinBalanceAmount">{t("stopIfBalanceUnder")}</label>
        <InputGroup
          type="text"
          id="stopByMinBalanceAmount"
          placeholder="0.00000000"
          value={autoMode["stopByMinBalanceAmount"]}
          onChange={(data) => {
            const inputValue = data.target.value;
            let values = { ...autoMode };
            const regex = /^\d{0,10}\.{0,1}\d{0,8}$/;
            if ((regex.test(inputValue) && Number(inputValue) <= 9999999999.99999999 && inputValue !== '00') || inputValue === undefined || inputValue === '') {
              values["stopByMinBalanceAmount"] = inputValue;
              setAutoMode(values);
            } else {
              setAutoMode(values);
            }
          }}
          paddingInput="5px 8px 7px"
          marginInput="-2px 5px 0 10px"
          width="150px"
          height="33px"
          textAlign="center"
          fontSizeInput="16px"
          autoInput={true}
        />
      </div>

      <div className="flex-inline between">
        <label className="limits-label without-padding"
               htmlFor="stopByMaxBalanceAmount">{t("stopIfBalanceOver")}</label>
        <InputGroup
          type="text"
          id="stopByMaxBalanceAmount"
          placeholder="0.00000000"
          value={autoMode["stopByMaxBalanceAmount"]}
          onChange={(data) => {
            const inputValue = data.target.value;
            let values = { ...autoMode };
            const regex = /^\d{0,10}\.{0,1}\d{0,8}$/;
            if ((regex.test(inputValue) && Number(inputValue) <= 9999999999.99999999 && inputValue !== '00') || inputValue === undefined || inputValue === '') {
              values["stopByMaxBalanceAmount"] = inputValue;
              setAutoMode(values);
            } else {
              setAutoMode(values);
            }
          }}
          paddingInput="5px 8px 7px"
          marginInput="-2px 5px 0 10px"
          width="150px"
          height="33px"
          textAlign="center"
          fontSizeInput="16px"
          autoInput={true}
        />
      </div>

      <div className="flex-inline between">
        <label className="limits-label without-padding" htmlFor="stopByMaxBetsAmount">{t("stopIfBetAmountOver")}</label>
        <InputGroup
          type="text"
          id="stopByMaxBetsAmount"
          placeholder="0.00000000"
          value={autoMode["stopByMaxBetsAmount"]}
          onChange={(data) => {
            const inputValue = data.target.value;
            let values = { ...autoMode };
            const regex = /^\d{0,10}\.{0,1}\d{0,8}$/;
            if ((regex.test(inputValue) && Number(inputValue) <= 9999999999.99999999 && inputValue !== '00') || inputValue === undefined || inputValue === '') {
              values["stopByMaxBetsAmount"] = inputValue;
              setAutoMode(values);
            } else {
              setAutoMode(values);
            }
          }}
          paddingInput="5px 8px 7px"
          marginInput="-2px 5px 0 10px"
          width="150px"
          height="33px"
          textAlign="center"
          fontSizeInput="16px"
          autoInput={true}
        />
      </div>
    </StyledRadioBox>
  );
});

export default AutoBetsLimits;
