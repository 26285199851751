import { StyledReCaptcha } from "./styledReCaptcha";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

const ReCaptcha = ({ recaptchaRef }) => {
  const { t } = useTranslation("siteOptions");

  return <StyledReCaptcha>
    <span className={'recaptcha-wrapper__title'}>{t('reCaptchaTitle')}</span>
    <div className={'recaptcha-wrapper__content'}>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC}
        ref={recaptchaRef}
      />
    </div>
  </StyledReCaptcha>
}

export default ReCaptcha;