import { useState, useCallback, useEffect } from "react";
import { useBetween } from "use-between";

const AnimationSyncDataStates = () => {
  const [isAnimationSyncDataStart, setIsAnimationSyncDataStart] = useState(false);

  const startAnimationSync = useCallback(() => {
    setIsAnimationSyncDataStart(true);
  }, []);

  const stopAnimationSync = useCallback(() => {
    setIsAnimationSyncDataStart(false);
  }, []);

  return {
    startAnimationSync,
    stopAnimationSync,
    isAnimationSyncDataStart
  };
};



export const useAnimationSync = () => useBetween(AnimationSyncDataStates);

export const useAnimationSyncData = (data = null) => {
  const { isAnimationSyncDataStart } = useAnimationSync();
  const [savedData, setSavedData] = useState(data);

  // eslint-disable-next-line
  useEffect(() => {
    if (!isAnimationSyncDataStart) {
      const newData = data;
      setSavedData(newData);
    }
  });

  return !isAnimationSyncDataStart ? { ...data } : savedData;
};

export default AnimationSyncDataStates;
