import styled from "styled-components";

export const StyledFaqBlock = styled.div`
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};

  .faq-item .hidden {
    overflow: hidden;
    transition: all 0.5s linear;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const StyledDottedQuestion = styled.div`
  font-size: 18px;
  font-weight: 400;
  border-bottom: 1px ${({ theme }) => theme.subTitleColor} dashed;
  display: inline-block;
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  cursor: pointer;
  color: ${({ theme }) => theme.subTitleColor};
  opacity: 0.6;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    opacity: 1;
  }
`;

export const StyledAnswer = styled.div`
  overflow: hidden;
  transition: padding-bottom 0.2s ease-in, padding-top 0.2s ease-in, height 0.5s ease-in-out;
  padding: 22px 35px;
  font-size: 15px;
  line-height: 22px;
  border-radius: 3px;
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  color: ${({ theme }) => theme.readingPage.color};
  background-color: ${({ theme }) => theme.readingPage.bgColor};
`;

export const StyledLink = styled.a`
  border-bottom: 1px #555555 dashed;
  cursor: pointer;
  ${({ blue }) => blue && `
    color: #058bd8;
    border-color: #058bd8;
  `}
  ${({ gray }) => gray && `
    color: #9f9f9f;
    border-color: #9f9f9f;
  `}
  &:hover {
    ${({ blue, theme, gray }) => (!blue || !gray) && `color: ${theme.text}; border-color: ${theme.text};`};
  }
`;
