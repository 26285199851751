import styled, { css, keyframes } from "styled-components";

export const StyledMessagesContainer = styled.div`
  min-height: 50px;
  height: 100%;
  padding: 15px 20px 15px 20px;
  overflow-y: auto;
  position: relative;
  transition: all 0.3s ease-in-out;
  overflow-x: hidden;

  .custom-hint {
    position: absolute;
    width: 0;
    transform: translate(30px, -18px);
    z-index: 10000;
  }
`;

export const StyledChatMessages = styled.div`
  padding-top: ${({ glued }) => (glued ? '1px' : '15px')};
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => (align ? `flex-${align}` : 'space-between')};
  width: 100%;

  .wrapper-relative {
    position: relative;
  }
`;

export const StyledMessageBody = styled.div`
  padding: 11px 14px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colorsChat.backgroundColor};
  display: flex;
  justify-content: space-between;
  align-self: flex-end;
  flex-wrap: wrap;
  font-size: 14px;
  width: calc(100% - 61px);
  position: relative;
  word-break: break-word;

  &.bot {
    width: 100%;
    background-color: ${({ theme }) => theme.colorsChat.backgroundColorBot};
  }

  &:before {
    ${({ alignArrow }) => (alignArrow ? 'content: "";' : null)};
    display: block;
    position: absolute;
    top: 50%;
    ${({ alignArrow }) => alignArrow}: 100%;
    margin-top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${({ alignArrow }) => {
      if (alignArrow === 'right') {
        return '10px 9px 10px 0';
      } else if (alignArrow === 'left') {
        return '10px 0 10px 9px';
      } else {
        return '0';
      }
    }};
    border-color: transparent ${({ theme }) => theme.colorsChat.backgroundColor};
  }
`;

export const StyledMessageHeader = styled.div`
  position: relative;
  margin-bottom: 6px;
  color: ${({ theme }) => theme.colorsChat.colorTime};

  &:first-child {
    color: ${({ theme }) => theme.colorsChat.colorUser};
    cursor: pointer;
    user-select: none;
  }

  & > .bot-title {
    background-color: ${({ theme }) => theme.colorsChat.bgColorBotTitle};
    color: #ffffff;
    width: 40px;
    text-align: center;
    padding: 2px 4px;
    font-size: 11px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }

  & > .bot-name {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 6px;
    color: #eaeaea;
    margin-left: 7px;
  }

  & > .width-reducer {
    position: absolute;
    top: 0;
    bottom: 6px;
    right: 0;
    left: 0;
    max-width: 85px;
  }

  @media screen and (max-width: 425px) {
    max-width: 140px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media screen and (max-width: 315px) {
    max-width: 100px;
  }
`;

export const StyledMessageTitle = styled.div`
  width: 100%;
  font-weight: normal;
  line-height: normal;
  color: ${({ theme }) => theme.colorsChat.colorMessage};

  & div {
    display: inline;
  }

  & img {
    margin-bottom: -6px;
  }
`;

const shimmer = keyframes`
  100% {-webkit-mask-position: left}
`

export const StyledMessageAvatar = styled.div`
  position: relative !important;
  width: auto !important;

  & .rating {
    width: 22px !important;
    height: 22px !important;
    color: ${({ theme }) => theme.subTitleColor};
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute !important;
    top: -4px;
    right: -4px;
    border: 2px solid #215be3;
    background-color: ${({ theme }) => theme.accountActivityItemBg};
    border-radius: 100%;
    z-index: 10;
    cursor: pointer;
  }

  ${({ loading, theme }) => loading && css`
    & > .user-image_shimmer {
      background-color: #ffffff22;
      display: inline-block;
      -webkit-mask: -webkit-linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
      background-repeat: no-repeat;
      width: 41px;
      height: 41px;
      border-radius: 50%;
      cursor: pointer;
      animation: ${shimmer} 1.5s infinite linear;
    }
  `};

  & > img {
    display: block;
    width: 41px;
    height: 41px;
    border-radius: 50%;
    cursor: pointer;
    object-fit: cover;
    -o-object-fit: cover;
  }
`;
