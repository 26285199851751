import {
  account,
  cashFlow,
  clients,
  currencies,
  feedbacks,
  goods,
  logs,
  navbar,
  news,
  paymentSettings,
  projects,
  transactionAndRainHistory,
  transactions
} from "./rbac-consts";

const rules = {
  ROLE_CLIENT: {
    static: [
      //navbar rules
      navbar.GOODS,
      navbar.ACCOUNT,
      navbar.PROJECTS,
      navbar.BATCHES,
      navbar.ACCOUNT_SETTINGS,
      navbar.CASH_FLOW_STATEMENT,
      goods.USER,
      projects.CLIENT,
      cashFlow.USER,
      account.CLIENT,
      transactionAndRainHistory.CLIENT
    ],
    dynamic: {}
  },
  ROLE_ADMIN: {
    static: [
      //navbar rules
      navbar.GOODS,
      navbar.CLIENTS,
      navbar.CURRENCIES,
      navbar.LOGS,
      navbar.TRANSACTIONS,
      clients.ADMIN,
      feedbacks.ACTIONS,
      projects.ADMIN,
      logs.ADMIN,
      cashFlow.ADMIN,
      paymentSettings.ADMIN,
      currencies.ADMIN,
      news.ADMIN,
      transactions.ADMIN
    ],
    dynamic: {}
  }
};

export default rules;
