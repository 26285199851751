import styled from "styled-components";
import zoom from "../../../../../assets/images/search-icon.svg";

export const StyledChatFriends = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) => (props.active ? "0" : "-100%")};
  width: 350px;
  height: 100%;
  background: ${({ theme }) => theme.leftSidebar.blockBgColor};
  z-index: 999999;
  color: #333;
  overflow-y: auto;
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: 375px) {
    left: ${(props) => (props.active ? "0" : "-110%")};
    width: 320px;
  }
`;

export const StyledChatFriendsCloseIcon = styled.div`
  font-size: 24px;
  line-height: 24px;
  opacity: 0.6;
  margin: 23px 20px 0 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;

  &:hover {
    opacity: 1;
  }

  & > button {
    font-size: 24px;
    line-height: 24px;
    border: none;
  }

  ${({ theme }) => theme.leftSidebar.closeBtn}
`;

export const StyledChatFriendsBody = styled.div`
  display: flex;
  flex-direction: column;

  & > .search-warning {
    display: none;
  }

  & > .content {
    display: flex;
    flex-direction: column;
  }

  &:has(> .search-input:focus) {
    & > .search-warning {
      display: block;
    }

    & > .content {
      display: none;
    }
  }
`;

export const StyledChatFriendsSearch = styled.input`
  padding: 20px 50px;
  opacity: 0.7;
  font-size: 16px;
  background: url(${zoom}) 20px 50% no-repeat;
  background-size: 16px;
  ${({ theme }) => theme.leftSidebar.input};

  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;

  &:focus {
    opacity: 1;
  }
`;

export const StyledChatMyFriends = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.leftSidebar.blockBgLightColor};
  margin-bottom: 10px;
  padding: 20px;
  color: ${({ theme }) => theme.mainTextColor};
  font-size: 14px;


  & .friends__title {
    font-size: 16px;
    margin-bottom: 15px;
  }
`;

export const StyledChatIgnored = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.leftSidebar.blockBgLightColor};
  margin-bottom: 10px;
  padding: 20px;
  color: ${({ theme }) => theme.mainTextColor};
  font-size: 14px;


  & .ignored__title {
    font-size: 16px;
    margin-bottom: 15px;
  }
`;
