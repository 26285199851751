import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useBetween } from "use-between";
import ProfileActionsStates from "../../profileActionsWindow/ProfileActionsStates";
import { AppContext } from "../../../App";
import DiceBetsListItem from "./DiceBetsListItem";

const DiceBetsList = ({ bets }) => {
  const { user } = useContext(AppContext);

  const { t } = useTranslation("games");

  const {
    handleProfileActionsVisible
  } = useBetween(ProfileActionsStates);

  if (!bets) {
    return (
      <tr className="full">
        <td className={"center"}>{t("loadingBets")}</td>
      </tr>
    );
  }

  if (bets && bets.length === 0) {
    return (
      <tr className="full">
        <td className={"center"}>{t("betsIsEmpty")}</td>
      </tr>
    );
  }

  return (
    bets.sort((a, b) => b.id - a.id).slice(0, 40).map((value, index) => (
      <DiceBetsListItem
        key={value.id}
        value={value}
        user={user}
        handleProfileActionsVisible={handleProfileActionsVisible}
      />
    ))
  );
};

export default DiceBetsList;