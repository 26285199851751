import styled from "styled-components";

export const StyledChatConversations = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) => (props.active ? "0" : "-100%")};
  width: 350px;
  height: 100%;
  background: ${({theme}) => theme.leftSidebar.blockBgColor};
  z-index: 999999;
  color: #333;
  overflow-y: auto;
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: 375px) {
    left: ${(props) => (props.active ? "0" : "-110%")};
    width: 320px;
  }
`;

export const StyledChatConversationsCloseIcon = styled.div`
  font-size: 24px;
  line-height: 24px;
  opacity: 0.6;
  margin: 23px 20px 0 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;

  &:hover {
    opacity: 1;
  }

  & > button {
    font-size: 24px;
    line-height: 24px;
    border: none;
  }

  ${({ theme }) => theme.leftSidebar.closeBtn}
`;

export const StyledChatConversationsBody = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({theme}) => theme.mainTextColor};

  & > .content {
    padding: 20px;

    & .content__title {
      font-size: 16px;
      margin-bottom: 15px;
    }

    & .content__results {
      background: ${({theme}) => theme.leftSidebar.blockBgLightColor};
      margin: 20px 0 0 0;
      padding: 8px 10px;
      box-sizing: border-box;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      font-size: 14px;
    }
  }
`;