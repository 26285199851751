import React, {useEffect, useState, useCallback, useMemo, useImperativeHandle} from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
// import RouletteStates from "./RouletteStates";
import InputGroup from "../../elements/inputGroup/InputGroup";
import { closableNotification } from "../../elements/notification/ClosableNotification";

import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import { responseStatus } from "../../../utils/consts";

import { StyledButton } from "../../styles/styledButton";
import { StyledChip, StyledChipsWrapper, StyledRouletteBetOptions } from "./styledRoulette";
import { useBetween } from "use-between";
// import calculateGameStatistics from "../../../utils/calculateGameStatistics";
import BetAction from "../../elements/betAction/BetAction";
import { CurrentHonestyControl } from "../../elements/leftSidebar/HonestyControl/CurrentHonestyControl";
import { VisibleLeftSidebarStates } from "../../elements/leftSidebar/VisibleLeftSidebarStates";
import BalanceStates from "../BalanceStates";
import AutoBetsContainer from "../../elements/autoBets/AutoBetsContainer";
import { GAMES } from "../Constants";
import { useAutoBetsStatesRoulette } from "../../elements/autoBets/AutoBetsStates";
import { useAnimationSync } from '../AnimationSyncDataStates';
import CurrentStatisticsStates from "../CurrentStatisticStates";


const RouletteToPlayContainer = React.forwardRef(({
  submitData,
  setSubmitData,
  responseData,
  setResponseData,
  errorData,
  setErrorData,
  handleChange,
  //setIsRotate,
  isPlay,
  setIsPlay,
  isMobile,
  autoModeIsStart,
  gameInfoRef,
  isDisableAutoBet,
  animationDisabled,
  resetBet,
  selectedNumber,
},ref) => {
  const { t } = useTranslation("games");

  // const { autoModeIsStart, gameInfoRef, isDisableAutoBet } = useAutoBetsStatesRoulette();
  const { startAnimationSync, stopAnimationSync } = useAnimationSync();

  const chips = {
    "1": {
      "dignity": "1",
      "color": "red"
    },
    "5": {
      "dignity": "5",
      "color": "red"
    },
    "10": {
      "dignity": "10",
      "color": "red"
    },
    "50": {
      "dignity": "50",
      "color": "red"
    },
    "100": {
      "dignity": "0.1k",
      "color": "green"
    },
    "500": {
      "dignity": "0.5k",
      "color": "green"
    },
    "1000": {
      "dignity": "1k",
      "color": "green"
    },
    "5000": {
      "dignity": "5k",
      "color": "green"
    },
    "10000": {
      "dignity": "10k",
      "color": "green"
    },
    "50000": {
      "dignity": "50k",
      "color": "green"
    },
    "100000": {
      "dignity": "0.1m",
      "color": "yellow"
    },
    "500000": {
      "dignity": "0.5m",
      "color": "yellow"
    },
    "1000000": {
      "dignity": "1m",
      "color": "yellow"
    },
    "5000000": {
      "dignity": "5m",
      "color": "yellow"
    }
  };

  const [sum, setSum] = useState(0);

  const { currentHonestyControl } = useBetween(CurrentHonestyControl);
  const { visible } = useBetween(VisibleLeftSidebarStates);
  const { activeCurrency, setBalance } = useBetween(BalanceStates);
  const {
    updateStatistics
  } = useBetween(CurrentStatisticsStates);

  const toPlay = useCallback(() => {
    console.log('');
    console.log('BET:', submitData.bet.toString());
    console.log('suggestedNumbers:', submitData.suggestedNumbers);
    let data = {
      bet: submitData.bet.toString(),
      clientSeed: currentHonestyControl.clientSeed,
      isActiveStatistic: visible,
      paymentMethod: submitData.paymentMethod.id,
      serverSeedHash: currentHonestyControl.serverSeedHash,
      suggestedNumbers: submitData.suggestedNumbers
    };

    setIsPlay(true);
    startAnimationSync();

    axios.post("/api/roulettes", data, userAuthenticationConfig(false)).then(response => {
      if (response.status === responseStatus.HTTP_CREATED) {
        if (!gameInfoRef.current?.isLeavePage) {
          console.log(
            'Баланс після ставки:',
            response.data.balance,
            (parseFloat(response.data.lose) !== 0 ?
                `(-${response.data.lose})` :
                `(+${response.data.win})`
            ),
            // `(${response.headers['request-duration']}ms)`
          );
          setBalance(response.data.balance)
          setResponseData({ ...response.data, onComplete: false });
        } else {
          gameInfoRef.current.responseData = { ...response.data, onComplete: false };
        }
        updateStatistics(response.data, 'roulette');
        console.log('post api/roulettes,response.data:', response.data);
      }
    }).catch(error => {
      let terminate = false;
      console.log('error:', error?.response);
      stopAnimationSync();
      if (error?.response?.status) {
        terminate = true;
      }
      if (error?.response?.status === responseStatus.HTTP_UNAUTHORIZED) {
        closableNotification(error?.response?.data?.detail, "error");
      }
      if (error?.response?.status === responseStatus.HTTP_ERROR_VALIDATION) {
        closableNotification(error?.response?.data?.error, "warning");
      }
      if (error?.response?.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error?.response?.data?.error || 'Invalid bet', "error");
      }
      if (!gameInfoRef.current?.isLeavePage) {
        setIsPlay(false);
        setErrorData({ error: true, terminate });
      } else {
        gameInfoRef.current.errorData = { error: true, terminate };
      }
    });
  }, [
    updateStatistics,
    setIsPlay,
    currentHonestyControl,
    setResponseData,
    setErrorData,
    submitData,
    visible,
    gameInfoRef,
    stopAnimationSync,
    startAnimationSync,
  ]);

  const sumSuggestedNumbers = (bets) => {
    let sum = 0;
    for (let bet of Object.values(bets)) {
      sum += bet;
    }
    return setSum(sum);
  };

  useEffect(() => {
    sumSuggestedNumbers(submitData.suggestedNumbers);
  }, [submitData]);

  const onChange = (e) => {
    const { value } = e.target;
    setSum(value);
    handleChange(e);
  };

  const profitOnWin = useMemo(() => {
    // (!isPlay || autoModeIsStart) ? (Number(responseData.win) +  Number(responseData.bet)).toFixed(8) : Number(0).toFixed(8)
    if (!isPlay || /*autoModeIsStart || */ selectedNumber !== undefined) {
      const win = Number(responseData.win);
      const lose = Number(responseData.lose);
      // const bet = Number(responseData.bet);
      if (!isNaN(lose) && lose === 0) {
        if (!isNaN(win)) {
          return Number(win).toFixed(8);
        }
      }
    }
    return Number(0).toFixed(8);
  }, [responseData.win, responseData.lose, isPlay, selectedNumber]);
  // console.log('profitOnWin:', profitOnWin);
  // console.log('responseData.win:', responseData.win);

  useImperativeHandle(ref, () => ({
    toPlay:  () => {toPlay();}
  }), [toPlay]);

  return (
    <StyledRouletteBetOptions
      isMobile={isMobile}
    >
      <div>
        {
          isMobile && (
            <StyledButton mt="14" color={(isPlay || autoModeIsStart || isDisableAutoBet) ? "danger" : "main"} onClick={toPlay} disabled={isPlay || autoModeIsStart || isDisableAutoBet} className="control">
              {t("spin")}
            </StyledButton>
          )
        }
        <InputGroup
          label={t("bet")}
          type="text"
          name="bet"
          value={Number(sum).toFixed(8)}
          onChange={onChange}
          position="center"
          betAction={
            <BetAction
              isPlay={isPlay || autoModeIsStart}
              value={sum}
              handleChange={handleChange}
              setValue={setSum}
              max={submitData.paymentMethod?.balances?.amount}
              min="1"
              isRoulette={true}
              submitData={submitData}
            />}
          // error={
          //   sum < 0 ||
          //   sum > submitData.paymentMethod?.balances?.amount}
          readOnly
        />
        <InputGroup
          label={t("win")}
          type="text"
          value={profitOnWin}
          name="win"
          readOnly
        />
        {
          !isMobile && (
            <StyledButton mt="14" color={(isPlay || autoModeIsStart || isDisableAutoBet) ? "danger" : "main"} onClick={toPlay} disabled={isPlay || autoModeIsStart || isDisableAutoBet}>
              {t("spin")}
            </StyledButton>
          )
        }
      </div>
      <div>
        {
          isMobile && (
            <AutoBetsContainer
              game={GAMES.ROULETTE}
              toPlay={toPlay}
              responseData={responseData}
              submitData={submitData}
              setSubmitData={setSubmitData}
              errorData={errorData}
              setErrorData={setErrorData}
              setStopAnimation={setIsPlay}
              isMobile={isMobile}
              isPlay={isPlay}
              animationDisabled={animationDisabled}
              resetBet={resetBet}
            />
          )
        }
        <StyledChipsWrapper
          isMobile={isMobile}
        >
          <div className="title">
            {t("chips")}:
          </div>
          <div
            className="chips-grid"
          >
            {Object.entries(chips).map(([key, value]) => (
              <StyledChip
                current={submitData.currentBet === key.toString()}
                color={value.color}
                onClick={() => setSubmitData((prevState) => ({ ...prevState, "currentBet": key.toString() }))}
                value={key}
                key={key}
              >
                <span>{value.dignity}</span>
                {activeCurrency}
              </StyledChip>
            ))}
            <StyledChip
              color="gray"
              onClick={(e) => setSubmitData((prevState) => ({ ...prevState, "currentBet": e.target.value }))}
              value="clear"
            >
            </StyledChip>
          </div>
        </StyledChipsWrapper>
        {
          !isMobile && (
            <AutoBetsContainer
              game={GAMES.ROULETTE}
              toPlay={toPlay}
              responseData={responseData}
              submitData={submitData}
              setSubmitData={setSubmitData}
              errorData={errorData}
              setErrorData={setErrorData}
              setStopAnimation={setIsPlay}
              isMobile={isMobile}
              isPlay={isPlay}
              animationDisabled={animationDisabled}
              resetBet={resetBet}
            />
          )
        }
      </div>
    </StyledRouletteBetOptions>
  );
});

export default RouletteToPlayContainer;
