import {useEffect} from "react";
import telegramAuthenticationConfig from "../../utils/telegramAuthenticationConfig";

import {StyledSocialButtonWrapper, StyledTelegramButton} from "./styledLogin";

import telegramIcon from "../../assets/images/telegram-button.svg";

const LoginTelegramForm = ({authData, setAuthData}) => {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://telegram.org/js/telegram-widget.js";
        script.async = true;
        document.body.appendChild(script);

        const telegram = window.Telegram?.WebApp;
        const user = telegram?.initDataUnsafe?.user;
        if (telegram && user) {
            let authObj = {
                id: user.id
            }
            let nickname = user.username;
            if(!nickname)nickname = `${user.first_name?user.first_name+" ":''}${user.last_name || ''}`
            if(nickname)authObj.nickname = nickname;

            setAuthData({
                ...authData,
                headers: telegramAuthenticationConfig(authObj)
            });
        }

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleTelegramResponse = (response) => {
        console.log(response)
        setAuthData({
            ...authData,
            headers: telegramAuthenticationConfig(response)
        });
    };

    const handleCustomTelegramButton = () => {
        window.Telegram.Login.auth(
            {bot_id: process.env.REACT_APP_TELEGRAM_BOT_TOKEN, request_access: true},
            (data) => {
                if (!data) return;
                handleTelegramResponse(data)
            }
        );
    }

    return (
        <StyledSocialButtonWrapper>
            <StyledTelegramButton onClick={handleCustomTelegramButton}>
                <img src={telegramIcon} alt={"telegram icon"}/>
            </StyledTelegramButton>
        </StyledSocialButtonWrapper>
    );
};

export default LoginTelegramForm;