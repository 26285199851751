import styled from "styled-components";

export const StyledHonestyControlWrapper = styled.div`
  padding: 20px;
  h3 {
    color: ${({theme}) => theme.subTitleColor};
    font-size: 24px;
    font-weight: 400;
  }
  button {
    width: 100%;
    margin: 10px 0 25px;
  }
`;

export const StyledHonestyControlDescriptions = styled.div`
  padding: 5px 0 25px;
  p {
    letter-spacing: 0;
  }
  a {
    border-bottom: 1px dashed ${({theme}) => theme.mainTextColor};
    &:hover {
      color: ${({theme}) => theme.leftHonestyControl.linkHoverColor};
      border-bottom: 1px dashed ${({theme}) => theme.leftHonestyControl.linkHoverColor};
    }
  }
  @media screen and (max-width: 1100px) {
    color: ${({theme}) => theme.mainTextColor};
  }
`;

export const StyledHonestyControlTitle = styled.div`
  width: calc( 100% + 40px );
  margin-left: -20px;
  margin-bottom: 20px;
  padding: 0 20px 15px;
  color: #058bd8;
  font-size: 21px;
  border-bottom: 1px solid ${({theme}) => theme.leftSidebar.blockBorderColor};
`;