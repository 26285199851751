import styled from "styled-components";
import { device } from "../../styles/responsive/responsiveUtils";

export const StyledReCaptcha = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin: 5px 0;
  font-size: 12px;

  .recaptcha-wrapper__title {
  }

  .recaptcha-wrapper__content {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.lightBackground};
    padding: 5px;
    border-radius: 3px;
  }

  @media screen and ${device('xs', 'min')} and ${device('desktop')} {
    font-size: 15px;
    margin: 15px 0;
    gap: 8px;

    .recaptcha-wrapper__content {
      padding: 10px;
    }
  }

  @media screen and ${device('desktop', 'min')} {
    font-size: 16px;
    margin: 25px 0;
    gap: 10px;

    .recaptcha-wrapper__content {
      padding: 15px;
    }
  }
`;