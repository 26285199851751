import { StyledContestItem } from "./styledContestsPage";
import { useTranslation } from "react-i18next";
import React, { useContext, useState } from "react";
import firstPlaceImg from "../../assets/images/contests/1-place-icon.svg";
import secondPlaceImg from "../../assets/images/contests/2-place-icon.svg";
import thirdPlaceImg from "../../assets/images/contests/3-place-icon.svg";
import { StyledHistoryLink } from "../../components/styles/styledHistory";
import { NavLink } from "react-router-dom";
import { generatePath } from "../../utils/getLanguage";
import { AppContext } from "../../App";
import { contestPlaceReward } from "../../utils/consts";
import Cookies from "js-cookie";

const placeImages = [
  firstPlaceImg,
  secondPlaceImg,
  thirdPlaceImg
];

export const ContestItem = ({ contest, handleProfileActionsVisible }) => {

  const {
    name,
    description,
    prizePool,
    contestParticipants: participants,
    participantsAmount,
    endDate,
    personalData,
    remainingWagering
  } = contest;

  const { t } = useTranslation("contests");

  const { user } = useContext(AppContext);
  const [collapsed, setCollapsed] = useState(Cookies.get(`contestCollapsed-${contest.id}`) ? JSON.parse(Cookies.get(`contestCollapsed-${contest.id}`)) : false);
  const [timeleft, setTimeleft] = useState("");

  const getReward = (place) => {
    return contestPlaceReward.hasOwnProperty(place) ?
      Number(contestPlaceReward[place]) ? (prizePool * contestPlaceReward[place]) : contestPlaceReward[place] :
      "-";
  };

  const toggleCollapsed = () => {
    const newValue = !collapsed;
    setCollapsed(newValue)
    Cookies.set(`contestCollapsed-${contest.id}`, newValue);
  }

  return <StyledContestItem isActive={!endDate} collapsed={collapsed}>
    <div className={"contest-title"}>
      <div className={"contest-title-wrapper"}>
        <div className={"contest-status"}>{!endDate ? t("active") : t("finished")}</div>
        <span>{name ?? t("wanderingContest")}</span>
      </div>
      <div className={"contest-button-wrapper"}>
        {!endDate ?
          <div className={"contest-collapsed-timeleft"}>
            <span>{t("remainingWagering")}: </span>
            <span>{Number(remainingWagering).toFixed() + "$"}</span>
          </div> :
          null
        }
        <button className={"contest-collapse-btn"} onClick={toggleCollapsed}>–</button>
      </div>
    </div>
    <div className={"contest-item-body"}>
      <div className={"contest-description"}>{description ?? t("defaultContestDescription")}</div>
      <div className={"contest-stats"}>
        <div className={"contest-stats-item contest-prize-pool"}>
          <span className={"contest-stats-number green"}>{Number(prizePool).toFixed()}$</span>
          <span className={"contest-stats-label"}>{t("prizePool")}</span>
        </div>
        <div className={"contest-stats-item contest-timeleft"}>
          <span className={"contest-stats-label"}>{t("remainingWagering")}</span>
          <span
            className={"contest-stats-number" + (remainingWagering && !endDate ? " red" : "")}>{!endDate ? Number(remainingWagering).toFixed() + "$" : t("finished")}</span>
        </div>
        <div className={"contest-stats-item contest-participants"}>
          <span className={"contest-stats-number"}>{participantsAmount}</span>
          <span className={"contest-stats-label"}>{t("participants")}</span>
        </div>
        <div className={"contest-stats-item contest-place"}>
          <span className={"contest-stats-number"}>{personalData?.place ?? "-"}</span>
          <span className={"contest-stats-label"}>{t("yourPlace")}</span>
        </div>
        <div className={"contest-stats-item contest-wagered"}>
          <span className={"contest-stats-label"}>{t("youWagered")}</span>
          <span className={"contest-stats-number"}>{Number(personalData?.wagered ?? 0).toFixed()} $</span>
        </div>
      </div>
      <div className={"contest-leaderboard"}>
        <span className={"contest-leaderboard-title"}>{t("leaderboard")}</span>
        <div className={"contest-leaderboard-wrapper"}>
          <table className={"contest-leaders-table"}>
            <thead>
            <tr>
              <th>{t("place")}</th>
              <th>{t("user")}</th>
              <th>{t("totalBets")}</th>
              <th>{t("totalWagered")}</th>
              <th>{t("potentialReward")}</th>
            </tr>
            </thead>
            <tbody>
            {participants && participants.map((leader, key) => {
              const {
                nickname,
                totalBets,
                waged: wagered
              } = leader;

              const place = key + 1;
              const reward = leader?.reward ?? getReward(place);

              return <tr key={key}>
                <td className={"contest-leader-image"}>{[1, 2, 3].includes(place) ?
                  <img src={placeImages[place - 1]} alt={place}/> : place}
                </td>
                {nickname ?
                  <td>{
                    user?.nickname === nickname ?
                      <StyledHistoryLink as={NavLink} to={generatePath(`/account/${nickname}`)}>
                        {nickname}
                      </StyledHistoryLink>
                      :
                      <StyledHistoryLink
                        onClick={() => handleProfileActionsVisible(key, "contests")}
                        data-custom
                        data-custom-at="right"
                        data-id={key}
                        data-nickname={nickname}
                      >
                        {nickname}
                      </StyledHistoryLink>
                  }</td> :
                  <td>-</td>
                }
                <td>{totalBets ?? "-"}</td>
                <td>{Number(wagered).toFixed(8) ?? "-"} USDT</td>
                <td>{Number(reward) ? `${reward} USDT` : reward}</td>
              </tr>;
            })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </StyledContestItem>;
};

export default ContestItem;