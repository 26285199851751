import React from "react";
import ListLanguage from "./ListLanguage";
import {StyledFooterLangs} from "./styledListLanguage";

const WrapperListLanguage = ({isActiveLang, setIsActiveLang}) => (
    <StyledFooterLangs isActive={isActiveLang}>
      <ListLanguage setIsActiveLang={setIsActiveLang}/>
    </StyledFooterLangs>
);

export default WrapperListLanguage;