import a1Img from "../../../../assets/images/chat/emoji/a1.png";
import a2Img from "../../../../assets/images/chat/emoji/a2.png";
import a3Img from "../../../../assets/images/chat/emoji/a3.png";
import a4Img from "../../../../assets/images/chat/emoji/a4.png";
import a5Img from "../../../../assets/images/chat/emoji/a5.png";
import a6Img from "../../../../assets/images/chat/emoji/a6.png";
import a7Img from "../../../../assets/images/chat/emoji/a7.png";
import a8Img from "../../../../assets/images/chat/emoji/a8.png";
import a9Img from "../../../../assets/images/chat/emoji/a9.png";

import b1Img from "../../../../assets/images/chat/emoji/b1.png";
import b2Img from "../../../../assets/images/chat/emoji/b2.png";
import b3Img from "../../../../assets/images/chat/emoji/b3.png";
import b4Img from "../../../../assets/images/chat/emoji/b4.png";
import b6Img from "../../../../assets/images/chat/emoji/b6.png";
import b7Img from "../../../../assets/images/chat/emoji/b7.png";
import b8Img from "../../../../assets/images/chat/emoji/b8.png";
import b9Img from "../../../../assets/images/chat/emoji/b9.png";

import c1Img from "../../../../assets/images/chat/emoji/c1.png";
import c2Img from "../../../../assets/images/chat/emoji/c2.png";
import c3Img from "../../../../assets/images/chat/emoji/c3.png";
import c4Img from "../../../../assets/images/chat/emoji/c4.png";
import c5Img from "../../../../assets/images/chat/emoji/c5.png";
import c6Img from "../../../../assets/images/chat/emoji/c6.png";
import c7Img from "../../../../assets/images/chat/emoji/c7.png";
import c8Img from "../../../../assets/images/chat/emoji/c8.png";
import c9Img from "../../../../assets/images/chat/emoji/c9.png";

import d1Img from "../../../../assets/images/chat/emoji/d1.png";
import d2Img from "../../../../assets/images/chat/emoji/d2.png";
import d3Img from "../../../../assets/images/chat/emoji/d3.png";
import d4Img from "../../../../assets/images/chat/emoji/d4.png";
import d5Img from "../../../../assets/images/chat/emoji/d5.png";
import d6Img from "../../../../assets/images/chat/emoji/d6.png";

import e6Img from "../../../../assets/images/chat/emoji/e6.png";
import e8Img from "../../../../assets/images/chat/emoji/e8.png";

export const chatEmoji = [
  {
    icon: a1Img,
    id: 1,
    code: "xGkhaBZGgO"
  },
  {
    icon: a2Img,
    id: 2,
    code: "hqnZFdMikg"
  },
  {
    icon: a3Img,
    id: 3,
    code: "3GkKKQ6dW6"
  },
  {
    icon: a4Img,
    id: 4,
    code: "y8SRb46F7T"
  },
  {
    icon: a5Img,
    id: 5,
    code: "YTIMEnzNlZ"
  },
  {
    icon: a6Img,
    id: 6,
    code: "Awag5T86LU"
  },
  {
    icon: a7Img,
    id: 7,
    code: "dwFcqqSswb"
  },
  {
    icon: a8Img,
    id: 8,
    code: "9Ckdq2cUDA"
  },
  {
    icon: a9Img,
    id: 9,
    code: "Xu6qtXmECX"
  },
  {
    icon: b1Img,
    id: 10,
    code: "hzmHAZhLpI"
  },
  {
    icon: b2Img,
    id: 11,
    code: "s38A7cTUAM"
  },
  {
    icon: b3Img,
    id: 12,
    code: "0gmFlD89xa"
  },
  {
    icon: b4Img,
    id: 13,
    code: "Maa8MI6B9C"
  },
  {
    icon: b6Img,
    id: 15,
    code: "beeBdfOEwJ"
  },
  {
    icon: b7Img,
    id: 16,
    code: "qcLbwnPzol"
  },
  {
    icon: b8Img,
    id: 17,
    code: "hXk08F5byT"
  },
  {
    icon: b9Img,
    id: 18,
    code: "FCkgPOhwXI"
  },
  {
    icon: c1Img,
    id: 19,
    code: "tVoULDdgoa"
  },
  {
    icon: c2Img,
    id: 20,
    code: "Fs14XHYkHW"
  },
  {
    icon: c3Img,
    id: 21,
    code: "7ANaCkHaxw"
  },
  {
    icon: c4Img,
    id: 22,
    code: "4emqd5KZlA"
  },
  {
    icon: c5Img,
    id: 23,
    code: "HpJ8hOTmKa"
  },
  {
    icon: c6Img,
    id: 24,
    code: "YMxpYZgwCt"
  },
  {
    icon: c7Img,
    id: 25,
    code: "77xewU7F96"
  },
  {
    icon: c8Img,
    id: 26,
    code: "Hpe63FRL4u"
  },
  {
    icon: c9Img,
    id: 27,
    code: "zbYCQGJtA5"
  },
  {
    icon: d1Img,
    id: 28,
    code: "8gA7mMm66i"
  },
  {
    icon: d2Img,
    id: 29,
    code: "HqnYcxYQYJ"
  },
  {
    icon: d3Img,
    id: 30,
    code: "YsW6sfdDLz"
  },
  {
    icon: d4Img,
    id: 31,
    code: "9oq4yERVTW"
  },
  {
    icon: d5Img,
    id: 32,
    code: "klkbOYolvz"
  },
  {
    icon: d6Img,
    id: 33,
    code: "0wGZZ9uxru"
  },
  {
    icon: e6Img,
    id: 34,
    code: "BP26mGI5bf"
  },
  {
    icon: e8Img,
    id: 35,
    code: "aQglfCBa8m"
  },
];

// export const chatEmoji = [
//   "/emoji/a1.png",
//   "/emoji/a2.png",
//   "/emoji/a3.png",
//   "/emoji/a4.png",
//   "/emoji/a5.png",
//   "/emoji/a6.png",
//   "/emoji/a7.png",
//   "/emoji/a8.png",
//   "/emoji/a9.png",
//   "/emoji/b1.png",
//   "/emoji/b2.png",
//   "/emoji/b3.png",
//   "/emoji/b4.png",
//   "/emoji/b6.png",
//   "/emoji/b7.png",
//   "/emoji/b8.png",
//   "/emoji/b9.png",
//   "/emoji/c1.png",
//   "/emoji/c2.png",
//   "/emoji/c3.png",
//   "/emoji/c4.png",
//   "/emoji/c5.png",
//   "/emoji/c6.png",
//   "/emoji/c7.png",
//   "/emoji/c8.png",
//   "/emoji/c9.png",
//   "/emoji/d1.png",
//   "/emoji/d2.png",
//   "/emoji/d3.png",
//   "/emoji/d4.png",
//   "/emoji/d5.png",
//   "/emoji/d6.png",
//   "/emoji/e6.png",
//   "/emoji/e8.png",
// ];
