import { useCallback, useState } from "react";

const ProfileActionsStates = () => {
  const [visibleProfileAction, setVisibleProfileAction] = useState(null);
  const [visibleTipWindow, setVisibleTipWindow] = useState(false);
  const [place, setPlace] = useState(null);

  const handleProfileActionsVisible = useCallback((key, place) => {
    if (visibleProfileAction === key) {
      setVisibleProfileAction(null);
      setPlace(null);
    } else {
      setVisibleProfileAction(key);
      setPlace(place);
    }
  }, [visibleProfileAction]);

  return {
    visibleProfileAction,
    setVisibleProfileAction,
    place,
    setPlace,
    handleProfileActionsVisible,
    visibleTipWindow,
    setVisibleTipWindow
  }
}

export default ProfileActionsStates;