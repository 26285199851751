import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { translateRU } from "./locales/ru/translateRU";
import { translateEN } from "./locales/en/translateEN";
import {listLanguage} from "./utils/listLanguage";

const resources = {
  ru: translateRU,
  en: translateEN,
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    defaultNS: "bones",
    lng: listLanguage.defaultLang,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;