import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import { responseStatus } from "../../../utils/consts";
import { closableNotification } from "../notification/ClosableNotification";
import { StyledPaymentMethodItem, StyledWrapperPaymentItem } from "../../profileActionsWindow/styledProfileActions";
import { StyledPaymentMethodsIcons } from "../../styles/StyledPaymentMethodsIcons";
import InputGroup from "../inputGroup/InputGroup";
import checkObjectIsNullish from "../../../utils/checkObjectIsNullish";
import { StyledButton } from "../../styles/styledButton";
import { StyledLabel } from "../inputGroup/styledInputGroup";
import BetAction from "../betAction/BetAction";
import ReCaptcha from "../reCaptcha/ReCaptcha";
import AlertMessage from "../alert/Alert";

const Rain = ({ room, setVisible }) => {

  const [paymentMethods, setPaymentMethods] = useState([]);

  const [activePaymentInfo, setActivePaymentInfo] = useState();

  const [valueNumber, setValueNumber] = useState(activePaymentInfo ? activePaymentInfo?.fee?.min : paymentMethods[0]?.fee?.min || 0);

  const [submitData, setSubmitData] = useState({
    currencyAsset: null,
    amount: valueNumber.toString(),
    room: room?.id
  });
  
  const { t } = useTranslation("siteOptions");
  const recaptchaRef = useRef(null);

  const handleInput = (valueNumber) => setValueNumber(valueNumber);

  const fetchPaymentMethod = () => {
    axios.get("/api/payment-methods", userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setPaymentMethods(response.data["hydra:member"]);

        setSubmitData((prevState) => ({
          ...prevState,
          currencyAsset: response.data["hydra:member"][0]?.currency.asset
        }));

      }
    }).catch(error => {
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error.response.data.error, "error");
      }
    });
  };

  const createRain = (event) => {
    event.preventDefault();

    const captchaToken = recaptchaRef.current.getValue();
    recaptchaRef.current.reset();

    if (checkObjectIsNullish(submitData)) {
      closableNotification(t("fillAll"), "error");
      return;
    }

    axios.post("/api/rain", { ...submitData, captchaToken }, userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_CREATED) {
        setVisible(false);
        closableNotification(t("rainSuccessfully"), "success");
      }
    }).catch(error => {
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error.response.data.error, "error");
      }
    });
  };

  const handleItemClick = (value) => {
    localStorage.setItem("rain-paymentMethod", JSON.stringify(value.id));
    setActivePaymentInfo(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPaymentMethod();
  }, []);

  useEffect(() => {
    const savedMethod = localStorage.getItem("rain-paymentMethod") ?
      JSON.parse(localStorage.getItem("rain-paymentMethod")) :
      null;
    let paymentMethod = savedMethod ? paymentMethods.find(method => method.id === savedMethod) : null;
    if (!paymentMethod) {
      const defaultMethod = localStorage.getItem("paymentMethod") ?
        JSON.parse(localStorage.getItem("paymentMethod")) :
        null;
      paymentMethod = defaultMethod ?? paymentMethods[0];
    }
    setActivePaymentInfo(paymentMethod);
  }, [paymentMethods]);

  useEffect(() => {
    setSubmitData(prevState => ({
      ...prevState,
      currencyAsset: activePaymentInfo?.currency.asset,
      amount: valueNumber.toString()
    }));
  }, [activePaymentInfo, valueNumber]);

  const activeCurrency = useMemo(() => {
    return activePaymentInfo?.currency.asset ?? null;
  }, [activePaymentInfo]);

  return (
    <>
      <div>
        <StyledLabel>{t("chooseTheCoin")}:</StyledLabel>
        <StyledWrapperPaymentItem>
          {paymentMethods.length !== 0 ? paymentMethods.map((value, key) => (
            <StyledPaymentMethodItem
              key={key}
              onClick={() => handleItemClick(value)}
              active={submitData.currencyAsset === value.currency.asset}
              className={`payment-method-item ${activeCurrency === value.currency.asset ? "active" : ""}`}
            >
              <StyledPaymentMethodsIcons className={`payment-method-${value.currency.asset}`} />
            </StyledPaymentMethodItem>
          )) : <StyledPaymentMethodItem>{t("loading")}</StyledPaymentMethodItem>}
        </StyledWrapperPaymentItem>
        <div>
          <InputGroup
            autocomplete="off"
            id="amount"
            type="number"
            label={t("enterSum")}
            name="amount"
            value={valueNumber}
            betAction={
              <BetAction value={valueNumber}
                         setValue={setValueNumber}
                         min={activePaymentInfo?.fee?.min || 0}
                         max={activePaymentInfo?.fee?.max || 0}
              />}
            hintsWithMax={activePaymentInfo ? {
              ...activePaymentInfo,
              fee: {
                ...activePaymentInfo?.fee,
                min: activePaymentInfo?.minTip.toFixed(8),
                max: activePaymentInfo?.maxTip.toFixed(8)
              }
            } : null}
            handleInput={handleInput}
            required
          />
          <AlertMessage
              type="warning"
              message={t("rainWarning")}
              mt="20"
              mb="20"
          />
          <ReCaptcha recaptchaRef={recaptchaRef} />
          <StyledButton color="main" width="100" onClick={createRain}>
            {t("confirm")}
          </StyledButton>
        </div>
      </div>
    </>
  );
};

export default Rain;