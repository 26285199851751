import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { animated, easings, useSpring } from "@react-spring/web";

import { StyledBallItem, StyledBallShadowItem } from "./styledBalls";

const BallItem = ({
  index,
  submitData,
  selectNumbers,
  isActiveBall,
  isRunMagnet,
  animationDisabled,
  isMobile,
  rowWidth,
  autoMode,
}) => {
  const [shadowHide, setShadowHide] = useState(false);
  const ballRow = useRef();
  const maxBackgroundPosY = useMemo(() => !isMobile ? 140 : (140 / 1.6) + 11, [isMobile]);
  const middleBackgroundPosY = useMemo(() => !isMobile ? 110 : (110 / 1.6) + 10, [isMobile]);

  const [ballItemDragStyle, ballItemDragAnim] = useSpring(
    () => ({
      config: {
        duration: 200
      }
    }), []);

  const handleMouseMove = useCallback((event) => {
    if (isRunMagnet || isActiveBall || isMobile || autoMode) {
      return false;
    }
    const ballRowRect = ballRow.current.getBoundingClientRect();
    const ballRowOffset = { top: ballRowRect.top + window.scrollY };
    let e = (event.pageY - ballRowOffset.top) - 40;
    e < 3 && (e = 0);
    maxBackgroundPosY < e && (e = maxBackgroundPosY);
    middleBackgroundPosY < e ? setShadowHide(false) : setShadowHide(true);
    ballItemDragAnim.stop();

    const form = parseFloat(event.target.style.backgroundPositionY.replace("px", ""));
    ballItemDragAnim.start({
      from: {
        backgroundPositionY: form
      },
      to: {
        backgroundPositionY: e
      },
      config: {
        duration: 200,
        easing: null
      }
    });
  }, [isRunMagnet, isActiveBall, ballItemDragAnim, maxBackgroundPosY, middleBackgroundPosY, isMobile, autoMode]);

  const leaveBall = useCallback(() => {
    const form = parseFloat(ballRow.current.style.backgroundPositionY.replace("px", ""));
    ballItemDragAnim.stop();
    ballItemDragAnim.start({
      from: {
        backgroundPositionY: form
      },
      to: {
        backgroundPositionY: maxBackgroundPosY
      },
      config: {
        duration: 500,
        easing: easings.easeOutBounce
      },
      onChange: () => {
        shadowHide && setShadowHide(false);
      }
    });
  }, [ballItemDragAnim, shadowHide, maxBackgroundPosY]);

  const handleMouseOut = useCallback((event) => {
    if (isRunMagnet || isActiveBall || isMobile || autoMode) {
      return false;
    }
    leaveBall();
  }, [leaveBall, isRunMagnet, isActiveBall, isMobile, autoMode]);

  useEffect(() => {
    if (animationDisabled) {
      return;
    }
    if (isActiveBall) {
      ballItemDragAnim.stop();
      const form = parseFloat(ballRow.current.style.backgroundPositionY.replace("px", "")) || 0;
      ballItemDragAnim.start({
        from: {
          backgroundPositionY: form
        },
        to: {
          backgroundPositionY: 0
        },
        config: {
          duration: 200,
          easing: null
        },
        onChange: () => {
          const pos = parseFloat(ballRow.current.style.backgroundPositionY.replace("px", "")) || 0;
          if (middleBackgroundPosY < pos) {
            setShadowHide(false);
          } else {
            setShadowHide(true);
          }
        }
      });
    } else {
      leaveBall();
    }
  }, [isActiveBall, animationDisabled, leaveBall, ballItemDragAnim, middleBackgroundPosY]);

  useEffect(() => {
    if (animationDisabled) {
      leaveBall();
    }
  }, [animationDisabled, leaveBall]);

  const isActive = useMemo(() => {
    /*
    if (typeof submitData.suggestedNumbers === 'string') {
      console.log('submitData.suggestedNumbers:', submitData.suggestedNumbers);
      // const result = JSON.parse(submitData.suggestedNumbers);
      return JSON.parse(submitData.suggestedNumbers)?.includes(index);
    }
    return submitData.suggestedNumbers;
    */
    if (typeof submitData.suggestedNumbers === 'string') {
      console.log('submitData.suggestedNumbers:', submitData.suggestedNumbers);
      const result = JSON.parse(submitData.suggestedNumbers);
      console.log('result:', result);
      if (Array.isArray(result) && result) {
        return result?.includes(index);
      } else {
        return false;
      }
    }
    if (Array.isArray(submitData.suggestedNumbers)) {
      submitData.suggestedNumbers.includes(index);
    }
    return false;
  }, [submitData.suggestedNumbers, index]);

  return (
    <StyledBallItem
      ref={ballRow}
      onMouseOut={handleMouseOut}
      onMouseMove={handleMouseMove}
      onClick={() => selectNumbers(index)}
      position={index}
      active={isActive}
      animationDisabled={animationDisabled}
      as={animated.div}
      style={ballItemDragStyle}
      isMobile={isMobile}
      rowWidth={rowWidth}
    >
      <StyledBallShadowItem
        isMobile={isMobile}
        hide={shadowHide}
      />
    </StyledBallItem>
  );
};

export default BallItem;
