import React, { Suspense, useContext, useEffect } from "react";
import Header from "../components/elements/header/Header";
import Footer from "../components/elements/footer/Footer";
import PageSpinner from "../components/elements/spinner/PageSpinner";
import LeftSidebar from "../components/elements/leftSidebar/LeftSidebar";
import { Redirect, Switch, useLocation } from "react-router-dom";
import RouletteStates from "../components/games/roulette/RouletteStates";
import BallsStates from "../components/games/balls/BallsStates";
import DiceStates from "../components/games/dice/DiceStates";

import LoadRoute from "../components/loadRouter/LoadRoute";
import { RightSidebar } from "../components/elements/rightSidebar/RightSidebar";
import { AppContext } from "../App";

const UserWrapper = ({ routes }) => {
  const location = useLocation();

  let page = DiceStates;

  if (location.pathname.includes('balls')) {
    page = BallsStates;
  } else if (location.pathname.includes('roulette')) {
    page = RouletteStates;
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location]);

  return (
    <React.Fragment>
      <Suspense fallback={<PageSpinner/>}>
        <Header page={page}/>
        <LeftSidebar/>
        <Switch>
          {routes.map((route, i) => (
            <LoadRoute key={i} {...route} />
          ))}
          <Redirect to="/page-not-found"/>
        </Switch>
        <RightSidebar />
        <Footer/>
      </Suspense>
    </React.Fragment>
  );
};

export default UserWrapper;
