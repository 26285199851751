import React from "react";
import ClientDetailsContainer from "../../components/account/client/details/ClientDetailsContainer";

const ClientDetailsPage = ({ match }) => {

  return (
    <ClientDetailsContainer match={match} />
  );
};

export default ClientDetailsPage;