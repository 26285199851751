import React from "react";
import { navbar } from "../../../rbac-consts";

const adminRoutes = [
  {
    title: "Пользователи",
    path: "/panel/clients",
    perform: navbar.CLIENTS,
    icon: "users"
  },
  {
    title: "Транзакции",
    path: "/panel/transactions",
    perform: navbar.TRANSACTIONS,
    icon: "users"
  },
  {
    title: "Логи",
    path: "/panel/logs",
    perform: navbar.LOGS,
    icon: "history"
  }
];

export default adminRoutes;