import styled from "styled-components";
import { device } from "./responsive/responsiveUtils";

export const StyledMainTitle = styled.h1`
  ${({mt}) => mt && `margin-top: ${mt}px`};
  ${({mb}) => mb && `margin-bottom: ${mb}px`};
  ${({size}) => size && `font-size: ${size}px`};
  color: ${({color}) => color || '#058bd8'};
  font-weight: ${({weight}) => weight || '500'};
  text-align: ${({position}) => position || 'left'};
  position: relative;
  &.page-title {
    color: ${({ theme }) => theme.subTitleColor};
  }
  @media screen and ${device('576', 'min')} and ${device('desktop')} {
    font-size: 26px;
  }
  @media screen and ${device('576')} {
    margin-bottom: 15px;
    font-size: 22px;
  }
`;

export const StyledMainDescription = styled.div`
  ${({mt}) => mt && `margin-top: ${mt}px`};
  ${({mb}) => mb && `margin-bottom: ${mb}px`};
  ${({size}) => size && `font-size: ${size}px`};
  font-weight: ${({weight}) => weight || '500'};
  color: ${({theme}) => theme.subTitleColor};
  text-align: ${({position}) => position || 'left'};
`;