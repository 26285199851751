import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AppContext } from "../../../App";
import { StyledSoundToggler } from "./styledSoundToggler";
import { siteSound } from "../../../utils/consts";

const SoundToggler = () => {
  const { t } = useTranslation("siteOptions");

  const { soundMode } = useContext(AppContext);

  const { sound, setSound } = soundMode;

  const setSoundMode = (mode) => {
    window.localStorage.setItem("sounds", mode);
    setSound(mode);
  };

  const soundToggler = () => {
    sound === siteSound.ON ? setSoundMode(siteSound.OFF) : setSoundMode(siteSound.ON);
  };

  useEffect(() => {
    const localSound = window.localStorage.getItem("sounds");

    if (localSound) {
      setSound(localSound);
    }
  });

  return (
    <StyledSoundToggler onClick={soundToggler} sound={sound}>
      <span className="icon sound-icon" />
      <span className="text">{t("volume")}</span>
    </StyledSoundToggler>
  );
};

export default SoundToggler;