import React, { useState } from "react";
import InputGroup from "../../elements/inputGroup/InputGroup";
import Checkbox from "rc-checkbox";
import TextareaGroup from "../../elements/inputGroup/TextareaGroup";
import Select, { Option } from "rc-select";
import { Helmet } from "react-helmet-async";
import DatePicker from "react-date-picker";
import Switch from "rc-switch";

import { StyledDatepicker, StyledDatepickerLabel } from "../../styles/styledDatepicker";
import { StyledMainTitle } from "../../styles/styledTitle";
import { StyledSelect, StyledSelectLabel } from "../../styles/styledSelect";
import { StyledCheckboxLabel, StyledCheckboxWrapper } from "../../styles/styledCheckbox";

const ExampleFormFields = () => {

  const [dateValue, setDateValue] = useState(new Date());
  const [selectValue, setSelectValue] = useState();
  const [checkboxValue, setCheckboxValue] = useState(true);

  return (
    <>
      <Helmet>
        <title>Example form fields • ITLab-Studio</title>
      </Helmet>
      <StyledMainTitle size="18" mb="15">
        Example form fields
      </StyledMainTitle>

      <InputGroup
        id="defaultInput"
        label="Default input"
        name="defaultInput"
        type="text"
        placeholder="Some text"
      />

      <InputGroup
        id="passwordInput"
        label="Password input"
        name="passwordInput"
        type="password"
      />

      <InputGroup
        id="errorInput"
        label="Error input"
        name="errorInput"
        defaultValue="test@com.gmail"
        type="email"
        error="Invalid e-mail"
      />

      <StyledDatepicker mb="15">
        <StyledDatepickerLabel>Default datepicker:</StyledDatepickerLabel>
        <DatePicker
          format="dd-MM-yyyy"
          locale="en"
          id="someDate"
          name="someDate"
          className="date-input"
          value={dateValue}
          onChange={setDateValue}
        />
      </StyledDatepicker>

      <StyledSelect mb="15">
        <StyledSelectLabel htmlFor="searchSeason">Default select:</StyledSelectLabel>
        <Select
          id="searchSeason"
          name="searchSeason"
          className="custom-select"
          defaultValue={null}
          onChange={(value) => (setSelectValue(value))}
        >
          <Option value={null}>
            <div className="option-select-item">
              Select an option
            </div>
          </Option>
          <Option value="1">
            <div className="option-select-item">
              Some option 1
            </div>
          </Option>
          <Option value="2">
            <div className="option-select-item">
              Some option 2
            </div>
          </Option>
          <Option value="3">
            <div className="option-select-item">
              Some option 3
            </div>
          </Option>
        </Select>
      </StyledSelect>

      <StyledSelect mb="15">
        <StyledSelectLabel htmlFor="searchSeason">Select with icon:</StyledSelectLabel>
        <Select
          id="searchSeason"
          name="searchSeason"
          className="custom-select"
          defaultValue={null}
          onChange={(value) => (setSelectValue(value))}
        >
          <Option value={null}>
            <div className="option-select-item option-select-item_with-img">
              <span className="icon-bitcoin" />
              Select an option
            </div>
          </Option>
          <Option value="1">
            <div className="option-select-item option-select-item_with-img">
              <span role="img" className="icon-budget" />
              Some option 1
            </div>
          </Option>
          <Option value="2">
            <div className="option-select-item option-select-item_with-img">
              <span className="icon-cash-transit" />
              Some option 2
            </div>
          </Option>
          <Option value="3">
            <div className="option-select-item option-select-item_with-img">
              <span className="icon-global" />
              Some option 3
            </div>
          </Option>
        </Select>
      </StyledSelect>

      <StyledSelect mb="15">
        <StyledSelectLabel htmlFor="searchSeason">Multi select:</StyledSelectLabel>
        <Select
          id="searchSeason"
          name="searchSeason"
          className="custom-multiselect"
          mode="tags"
          defaultValue={null}
          onChange={(value) => (setSelectValue(value))}
        >
          <Option value={null}>
            <div className="option-select-item">
              Select an option
            </div>
          </Option>
          <Option value="1">
            <div className="option-select-item">
              Some option 1
            </div>
          </Option>
          <Option value="2">
            <div className="option-select-item">
              Some option 2
            </div>
          </Option>
          <Option value="3">
            <div className="option-select-item">
              Some option 3
            </div>
          </Option>
        </Select>
      </StyledSelect>

      <TextareaGroup
        id="defaultTextarea"
        label="Default textarea"
        name="defaultTextarea"
        placeholder="Some message..."
      />

      <div>
        <StyledCheckboxWrapper margin="0 0 20px">
          <Checkbox
            id="defaultCheckbox"
            className="default-checkbox"
            onClick={() => setCheckboxValue(!checkboxValue)}
            name="publish"
            value={checkboxValue}
          />
          <StyledCheckboxLabel position="right" htmlFor="defaultCheckbox">
            Default checkbox, position right
          </StyledCheckboxLabel>
        </StyledCheckboxWrapper>
      </div>

      <div>
        <StyledCheckboxWrapper margin="0 0 20px">
          <Switch className="default-switch"/>
          <StyledCheckboxLabel position="right" htmlFor="defaultCheckbox">
            Default Switch, position right
          </StyledCheckboxLabel>
        </StyledCheckboxWrapper>
      </div>
    </>
  );
};

export default ExampleFormFields;