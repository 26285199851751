import React from "react";
import loadingGif from "../../../assets/images/loading.gif";

import { StyledPageSpinner } from "./styledSpinner";
import { StyledContainer } from "../../styles/styledContainer";

const PageSpinner = () => {
  return (
    <StyledContainer>
      <StyledPageSpinner>
          <img src={loadingGif} alt={"loading..."} />
      </StyledPageSpinner>
    </StyledContainer>
  );
};

export default PageSpinner;