import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Dialog from "rc-dialog";
import { AppContext } from "../../../App";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import { closableNotification } from "../notification/ClosableNotification";
import { responseStatus } from "../../../utils/consts";
import InputGroup from "../inputGroup/InputGroup";
import { StyledButton } from "../../styles/styledButton";
import { StyledTwoFaActive } from "./styledTwoFa";
import { StyledMainTitle } from "../../styles/styledTitle";

const TwoFaModal = ({ visible, setVisible, twoFaIsActive }) => {

  const { user } = useContext(AppContext);

  const [authenticatorSecret, setAuthenticatorSecret] = useState(false);
  const [codeActive, setCodeActive] = useState("");
  const [codeRemove, setCodeRemove] = useState("");

  const { t } = useTranslation("siteOptions");
  const { t: tE } = useTranslation('errors');

  const getAuthenticatorSecretUser = () => {
    axios.get("/api/users/authenticator-secret/" + user.userId, userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setAuthenticatorSecret(response.data);
      }
    }).catch(error => {
      closableNotification(tE(error.response.data.error), "error");
    });
  };

  const setAuthenticatorSecretUser = (code) => {
    let data = {
      ...authenticatorSecret,
      code,
      twoFaIsActive
    };

    axios.put("/api/users/authenticator-secret/" + user.userId, data, userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setCodeActive("");
        setVisible(false);
      }
    }).catch(error => {
      closableNotification(tE(error.response.data.error), "error");
    });
  };

  const removeAuthenticatorSecretUser = (code) => {

    let data = {
      code,
      twoFaIsActive
    };

    axios.put("/api/users/authenticator-secret-remove/" + user.userId, data, userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setCodeRemove("");
        setVisible(false);
      }
    }).catch(error => {
      closableNotification(tE(error.response.data.error), "error");
    });
  };

  useEffect(() => {
    if (visible) {
      getAuthenticatorSecretUser();
    }
  }, [visible]);

  return (
    <Dialog
      visible={visible}
      wrapClassName="default-modal-wrapper"
      onClose={() => setVisible(false)}
      animation="zoom"
      maskAnimation="fade"
      title={t('using2FA')}
      forceRender={false}
      className="default-modal"
    >
      <div className="default-modal__content">
        {!twoFaIsActive && (
          <StyledTwoFaActive>
            <InputGroup
              id="twoFaCode"
              label={t('enterCode')}
              name="twoFaCode"
              value={codeActive}
              handleChange={(event) => setCodeActive(event.target.value)}
              type="text"
              maxLength="6"
            />
            <div className="security-code">
              <StyledMainTitle
                mb="15"
                as="div"
                size="20"
                position="center"
              >{authenticatorSecret.tempSecret}</StyledMainTitle>
              <img
                src={authenticatorSecret.tempQRCode}
                alt="secret"
              />
            </div>
            <div className="default-modal__footer security-code-action">
              <StyledButton
                color="danger"
                border
                onClick={() => setVisible(!visible)}
              >
                {t('cancel')}
              </StyledButton>
              <StyledButton
                color="success"
                id={"set"}
                onClick={() => setAuthenticatorSecretUser(codeActive)}
              >
                {t('approve')}
              </StyledButton>
            </div>
          </StyledTwoFaActive>
        )}
      </div>

      {twoFaIsActive && (
        <div>
          <InputGroup
            id="twoFaCodeRemove"
            label={t('enterCode')}
            name="twoFaCodeRemove"
            value={codeRemove}
            handleChange={(event) => setCodeRemove(event.target.value)}
            type="text"
            maxLength="6"
          />
          <div className="default-modal__footer security-code-action">
            <StyledButton
              color="danger"
              border
              onClick={() => setVisible(!visible)}
            >
              {t('cancel')}
            </StyledButton>
            <StyledButton
              color="success"
              id={"remove"}
              onClick={() => removeAuthenticatorSecretUser(codeRemove)}
            >
              {t('approve')}
            </StyledButton>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default TwoFaModal;