import Cookies from "js-cookie";

export const welcomeCookieName = "isWelcomeNotificationRead";
export const notificationsCookie = "notifications";
export const isVisibleNotifications = "isVisibleNotifications";

export const getStartingNotification = (t) => {
  const currentDate = Cookies.get(welcomeCookieName) ? Number(Cookies.get(welcomeCookieName)) : Date.now();

  if (!Cookies.get(welcomeCookieName)) {
    Cookies.set(welcomeCookieName, currentDate, { path: "" });
  }

  return {
    id: "welcome",
    createdAt: currentDate,
    title: t("notifications.welcome.title"),
    content: t("notifications.welcome.content"),
    type: "welcome",
    className: ["new"]
  };
};

export const formNotificationsArray = (oldNotifications, newNotifications) => {
  let result = [...oldNotifications];
  let cookiesNotifications = Cookies.get(notificationsCookie) ? JSON.parse(Cookies.get(notificationsCookie)) : [];

  newNotifications.forEach(newNotification => {
    if (newNotification && !oldNotifications.some(oldNotification => oldNotification.id === newNotification.id) && result.length <= 10) {
      result.push({
        ...newNotification,
        className: newNotification.className ?? cookiesNotifications.find((cookieNotification) => newNotification.id === cookieNotification.id)?.className ?? ["new"]
      });
    }
  });

  return result.sort((a, b) => b.createdAt - a.createdAt).slice(0, 10).reverse();
};

