import {StyledUserLevel, StyledUserLevelItem} from "./styledClientDetails";
import starIcon from "../../../../assets/images/level-star-icon.svg"
import {useTranslation} from "react-i18next";

const ClientDetails = () => {
    const {t} = useTranslation("games");

    return (
        <>
            <StyledUserLevel>
                <div className={"main-client-details"}>
                    <div className={"left-side"}>
                        <div className={"star-block"}>
                            <img src={starIcon} alt={"star icon"}/>
                        </div>
                        <div className={"details-block"}>
                            <span className={"level-info"}>{t("levelTab.level")} 0</span>
                            <span className={"points-info"}>0 / 10,000 {t("levelTab.pointsNextLevel")}</span>
                        </div>
                    </div>
                    <div className={"right-side"}>
                    <span>
                        {t("levelTab.total")} <b>0 {t("levelTab.of")} 1,000,000 {t("levelTab.points")}</b>
                    </span>
                    </div>
                </div>
            </StyledUserLevel>
            <StyledUserLevelItem>
                <div className={"title-level-block"}>
                    <b>{t("levelTab.loyaltyTitle")}</b>
                    <b>0 {t("levelTab.of")} 100,000 {t("levelTab.points")}</b>
                </div>
                <div className={"percent-block"}>
                    <div className={"line-border"}/>
                    <span>0%</span>
                </div>
                <div className={"info-block"}>
                    <b>{t("levelTab.loyaltyDesc")}</b>
                </div>
            </StyledUserLevelItem>
            <StyledUserLevelItem>
                <div className={"title-level-block"}>
                    <b>{t("levelTab.wageringTitle")}</b>
                    <b>0 {t("levelTab.of")} 100,000 {t("levelTab.points")}</b>
                </div>
                <div className={"percent-block"}>
                    <div className={"line-border"}/>
                    <span>0%</span>
                </div>
                <div className={"info-block"}>
                    <b>{t("levelTab.wageringDesc")}</b>
                </div>
            </StyledUserLevelItem>
            <StyledUserLevelItem>
                <div className={"title-level-block"}>
                    <b>{t("levelTab.bettingTitle")}</b>
                    <b>0 {t("levelTab.of")} 100,000 {t("levelTab.points")}</b>
                </div>
                <div className={"percent-block"}>
                <div className={"line-border"}/>
                    <span>0%</span>
                </div>
                <div className={"info-block"}>
                    <b>{t("levelTab.bettingDesc")}</b>
                </div>
            </StyledUserLevelItem>
            <StyledUserLevelItem>
                <div className={"title-level-block"}>
                    <b>{t("levelTab.chatActivityTitle")}</b>
                    <b>0 {t("levelTab.of")} 100,000 {t("levelTab.points")}</b>
                </div>
                <div className={"percent-block"}>
                <div className={"line-border"}/>
                    <span>0%</span>
                </div>
                <div className={"info-block"}>
                    <b>{t("levelTab.chatActivityDesc")}</b>
                </div>
            </StyledUserLevelItem>
            <StyledUserLevelItem>
                <div className={"title-level-block"}>
                    <b>{t("levelTab.referringTitle")}</b>
                    <b>0 {t("levelTab.of")} 100,000 {t("levelTab.points")}</b>
                </div>
                <div className={"percent-block"}>
                    <div className={"line-border"}/>
                    <span>0%</span>
                </div>
                <div className={"info-block"}>
                    <b>{t("levelTab.referringDesc")}</b>
                </div>
            </StyledUserLevelItem>
            <StyledUserLevelItem>
                <div className={"title-level-block"}>
                    <b>{t("levelTab.referralsTitle")}</b>
                    <b>0 {t("levelTab.of")} 100,000 {t("levelTab.points")}</b>
                </div>
                <div className={"percent-block"}>
                    <div className={"line-border"}/>
                    <span>0%</span>
                </div>
                <div className={"info-block"}>
                    <b>{t("levelTab.referralsDesc")}</b>
                </div>
            </StyledUserLevelItem>
            <StyledUserLevelItem>
                <div className={"title-level-block"}>
                    <b>{t("levelTab.contestTitle")}</b>
                    <b>0 {t("levelTab.of")} 100,000 {t("levelTab.points")}</b>
                </div>
                <div className={"percent-block"}>
                <div className={"line-border"}/>
                    <span>0%</span>
                </div>
                <div className={"info-block"}>
                    <b>{t("levelTab.contestDesc")}</b>
                </div>
            </StyledUserLevelItem>
            <StyledUserLevelItem>
                <div className={"title-level-block"}>
                    <b>{t("levelTab.jackpotTitle")}</b>
                    <b>0 {t("levelTab.of")} 100,000 {t("levelTab.points")}</b>
                </div>
                <div className={"percent-block"}>
                <div className={"line-border"}/>
                    <span>0%</span>
                </div>
                <div className={"info-block"}>
                    <b>{t("levelTab.jackpotDesc")}</b>
                </div>
            </StyledUserLevelItem>
            <StyledUserLevelItem>
                <div className={"title-level-block"}>
                    <b>{t("levelTab.rainmakerTitle")}</b>
                    <b>0 {t("levelTab.of")} 100,000 {t("levelTab.points")}</b>
                </div>
                <div className={"percent-block"}>
                    <div className={"line-border"}/>
                    <span>0%</span>
                </div>
                <div className={"info-block"}>
                    <b>{t("levelTab.rainmakerDesc")}</b>
                </div>
            </StyledUserLevelItem>
            <StyledUserLevelItem>
                <div className={"title-level-block"}>
                    <b>{t("levelTab.shopaholicTitle")}</b>
                    <b>0 {t("levelTab.of")} 100,000 {t("levelTab.points")}</b>
                </div>
                <div className={"percent-block"}>
                    <div className={"line-border"}/>
                    <span>0%</span>
                </div>
                <div className={"info-block"}>
                    <b>{t("levelTab.shopaholicDesc")}</b>
                </div>
            </StyledUserLevelItem>
        </>
    )
}

export default ClientDetails;