import React from "react";
import Can from "../can/Can";
import { StyledSidebarItem, StyledSidebarLink } from "./styledSidebar";

const SidebarItem = ({ role, perform, as, to, icon, linkTitle, handleChange, exact, badge }) => {

  return (
    <Can
      role={role}
      perform={perform}
      yes={() => (
        <StyledSidebarItem badge={badge}>
          <StyledSidebarLink
            exact={exact}
            as={as}
            to={to}
            onClick={handleChange}
            className="sidebar-link lo"
            activeClassName="sidebar-link_current"
          >
            <div className="sidebar-link__icon">
              <span className={`icon-${icon}`} />
            </div>
            <div className="sidebar-link__title">
              {linkTitle}
            </div>
          </StyledSidebarLink>
        </StyledSidebarItem>
      )}
    />
  );
};

export default SidebarItem;