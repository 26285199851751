import React from "react";
import { StyledAdminHeaderContainer, StyledHeaderWrapper } from "./styledHeader";
import { StyledLogoWrapper } from "../logo/styledLogo";

const AdminHeader = () => {

  return (
    <StyledHeaderWrapper>
      <StyledAdminHeaderContainer wrapper="content">
        <StyledLogoWrapper />
      </StyledAdminHeaderContainer>
    </StyledHeaderWrapper>
  );
};

export default AdminHeader;