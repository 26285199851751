import styled from "styled-components";

export const StyledQrCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 20px 0;
  background-color: ${({ theme }) => theme.alertMessageBg};
  border-radius: 3px;
`;

export const StyledModalLoader = styled.div`
  min-height: ${({ minHeight }) => minHeight ?? '400px'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTonConnectBlock = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    
    #ton-connect-button{
        border: 1px solid #0098EA;
        border-radius: 100px;
    }
    .ton-input-block{
        display: flex;
        background-color: white;
        border-radius: 100px;
    }
    #ton-input{
        width: 100px;
        padding-left: 10px;
        border-radius: 100px;
        font-size: 16px;
    }
    .ton-send-button{
        background-color: #0098EA;
        border-radius: 100px;
        display: flex;
        align-items: center;
        font-size: 20px;
        color: white;
        padding: 0 10px;
        cursor: pointer;
    }
`;