import styled from "styled-components";

export const StyledHeadingThree = styled.h3`
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const StyledHeadingFour = styled.h4`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const StyledParagraph = styled.p`
  ${({mt}) => mt && `margin-top: ${mt}px`};
  ${({mb}) => mb && `margin-bottom: ${mb}px`};
  ${({mr}) => mr && `margin-right: ${mr}px`};
  ${({ml}) => ml && `margin-left: ${ml}px`};
  cursor: ${({cursor}) => cursor ? cursor : "unset"};
  font-size: ${({fontSize}) => fontSize ? `${fontSize}px` : '14px'};
  color: ${({theme}) => theme.text};
  font-style: normal;
  font-weight: ${({fontWeight}) => fontWeight ? fontWeight : 400};
`;

export const StyledSpan = styled.span`
  ${({mt}) => mt && `margin-top: ${mt}px`};
  ${({mb}) => mb && `margin-bottom: ${mb}px`};
  ${({mr}) => mr && `margin-right: ${mr}px`};
  ${({ml}) => ml && `margin-left: ${ml}px`};
  display: ${({display}) => display || "inline" };
  cursor: ${({cursor}) => cursor ? cursor : "unset"};
  font-size: ${({fontSize}) => fontSize ? `${fontSize}px` : '14px'};
  color: ${({theme}) => theme.labelColor};
  font-style: normal;
  font-weight: ${({fontWeight}) => fontWeight ? fontWeight : 400};
`;