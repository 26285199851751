import React, { useState } from "react";
import Dialog from "rc-dialog";

import { StyledButton } from "../../styles/styledButton";

const ExampleDialog = () => {

  const [visible, setVisible] = useState(false);

  return (

    <>
      <Dialog
        visible={visible}
        wrapClassName="default-modal-wrapper"
        onClose={() => setVisible(false)}
        animation="zoom"
        maskAnimation="fade"
        title="Example dialog"
        forceRender={false}
        className="default-modal"
      >
        <div className="default-modal__content">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          </p>
          <p>
            A accusamus accusantium, adipisci atque, aut
            consequatur cumque ducimus enim explicabo itaque modi nulla optio praesentium quae quam, quos reprehenderit
            similique sit?
          </p>
        </div>
        <div className="default-modal__footer">
          <StyledButton color="neutral" onClick={()=>setVisible(!visible)}>
            Close
          </StyledButton>
        </div>
      </Dialog>

      <StyledButton
        color="main"
        onClick={() => setVisible(!visible)}
      >
        <span className="login-button__text">Show dialog window</span>
      </StyledButton>
    </>

  );
};

export default ExampleDialog;