import React from "react";

export const tagRegex = /(?:\b|:)user:([A-Za-z0-9_]{1,30})(?!\w|:)/gm;
// /(?<!\w|:)user:(\w{1,30}(?!\w|:)+)/gm;

export const UserTag = ({ username, isCurrentUser, hintData, hasIcon = true }) => {
  const classList = ['tag user-tag'];
  if (isCurrentUser) {
    classList.push('current');
  }
  if (hasIcon) {
    classList.push('has-icon');
  }
  return <a className={classList.join(' ')} {...hintData}>
    {username}
  </a>
}

export default UserTag;
