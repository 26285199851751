import React, { useCallback, useRef, useImperativeHandle } from "react";
import { animated, easings, useSpring, to } from "@react-spring/web";
import rouletteBall from "../../../assets/images/roll/ball.png";
import mobileRouletteBall from "../../../assets/images/roll/mobile-ball.png";
import {
  StyledRouletteBall,
  StyledRouletteRoll,
  StyledRouletteRollSpan,
  StyledRouletteRollWrapper,
  StyledRouletteStart,
} from "./styledRoulette";
import {
  WHEEL_STOPS
} from './constants';
import useSound from "use-sound";
import { playSound } from "../../../utils/checkSound";
import winSound from "../../../assets/sounds/dice/dice-win.wav";
import loseSound from "../../../assets/sounds/dice/dice-lose.wav";
import rouletteSound from "../../../assets/sounds/roulette/roulette-spin.wav";

// import { useBetween } from "use-between";
// import BalanceStates from "../BalanceStates";
const getRotateTransformValue = (element) =>  {
  const transformString = element?.style?.transform || '364';
  const numbers = transformString.match(/\d+/g);
  if (numbers?.length > 0) {
    return parseInt(numbers[0]);
  }
  return 364;
}

const randomInt = (min, max) => {
  return (Math.random() * Math.abs(max - min)) + min;
}

const Roulette = React.memo(React.forwardRef(({
  isMobile, toPlay, isPlay, autoModeIsStart, isDisableAutoBet
}, ref) => {

  const rollRef = useRef();
  const ballRef = useRef();
  const startRef = useRef();
  const isRotateProcessRef = useRef(false);
  const [playRouletteSound] = useSound(rouletteSound);

  const [rollRotateStyle, rollRotateAnim] = useSpring(
    () => (
    {
      from: {
        x: 364,
      },
      config: {
        duration: 200
      }
    }
  ), []);

  const [ballRotateStyle, ballRotateAnim] = useSpring(
    () => (
    {
      from: {
        x: 0,
      },
      config: {
        duration: 200
      }
    }
  ), []);

  const rotateTo = useCallback((
    number,
    isAnimationDisbaled = false,
    isAutoPlay = false,
    onSelectNumber = null,
    autoModeAcceleration = 3,
  ) => new Promise((resolve) => {
    if (isRotateProcessRef.current) {
      return;
    }

    let n = randomInt(-50, 50);
    const wheelPosFrom = getRotateTransformValue(rollRef.current);
    const wheelPosTo = 360 * Math.round(wheelPosFrom / 360) + WHEEL_STOPS[number] + 1440 + n;
    const ballPosFrom = getRotateTransformValue(ballRef.current);
    const ballPosTo =  360 * Math.floor(ballPosFrom / 360) - 1440 + n;
    isRotateProcessRef.current = true;
    let resolveRoll = false;
    let resolveBall = false;

    const handleOnSelectNumber = () => {
      try {
        onSelectNumber && onSelectNumber(number);
      } catch ({ message }) {
      }
    }
    const onComplete = () => {
      if (resolveRoll && resolveBall) {
        if (isAnimationDisbaled || (isAutoPlay && autoModeAcceleration > 0)) {
          setTimeout(() => resolve(), 200);
        } else {
          setTimeout(() => resolve(), 1500);
        }
      }
    }
    const startTime = Date.now();
    playSound(playRouletteSound);
    ballRef.current.style.opacity = 1;

    rollRotateAnim.start({
      from: {
        x: wheelPosFrom,
      },
      to: {
        x: wheelPosTo,
      },
      config: {
        duration: (isAnimationDisbaled || (isAutoPlay && autoModeAcceleration > 0)) ? 0 : 3500,
        easing: easings.easeOutCubic,
      },
      onResolve: (event) => {
        if (isAnimationDisbaled || (isAutoPlay && autoModeAcceleration > 0)) {
          handleOnSelectNumber();
          resolveRoll = true;
          onComplete();
        } else {
          const newWheelPosTo = wheelPosTo + 360;
          rollRotateAnim.start({
            to: { x: newWheelPosTo },
            config: {
              duration: 1000,
              easing: easings.easeOutCubic,
            },
            onResolve: (event) => {
              isRotateProcessRef.current = false;
              resolveRoll = true;
              onComplete();
            },
          });
          handleOnSelectNumber();
        }
      }
    });

    ballRotateAnim.start({
      from: {
        x: ballPosFrom,
      },
      to: {
        x: ballPosTo,
      },
      config: {
        duration: (isAnimationDisbaled || (isAutoPlay && autoModeAcceleration > 0)) ? 0 : 3500,
        easing: easings.easeOutCubic,
      },
      onResolve: (event) => {
        if (isAnimationDisbaled || (isAutoPlay && autoModeAcceleration > 0)) {
          resolveBall = true;
          onComplete();
        } else {
          const newBallPosTo = ballPosTo + 360;
          ballRotateAnim.start({
            // from: { x: newBallPosFrom },
            to: { x: newBallPosTo },
            config: {
              duration: 1000,
              easing: easings.easeOutCubic,
            },
            onResolve: (event) => {
              resolveBall = true;
              onComplete();
            }
          });
        }
        isRotateProcessRef.current = false;
      }
    });
  }), [rollRotateAnim, ballRotateAnim, playRouletteSound]);

  useImperativeHandle(ref, () => ({
    rotateTo: async (
      number,
      isAnimationDisbaled = false,
      isAutoPlay = false,
      onSelectNumber = null,
      autoModeAcceleration = 3,
    ) => {
      const result = await rotateTo(number, isAnimationDisbaled, isAutoPlay, onSelectNumber, autoModeAcceleration);
      return result;
    }
  }), [rotateTo]);

  return (
    <StyledRouletteRollWrapper
      isMobile={isMobile}
    >
      <StyledRouletteRoll
        ref={rollRef}
        as={animated.div}
        style={{
          transform: to(rollRotateStyle.x, value => `rotate(${value}deg)`),
        }}
        isMobile={isMobile}
      >
        <StyledRouletteRollSpan
          isMobile={isMobile}
        />
      </StyledRouletteRoll>
      {(!isPlay && !autoModeIsStart && !isDisableAutoBet) ?
          <StyledRouletteStart
              ref={startRef}
              isMobile={isMobile}
              onClick={toPlay}
          >
            <span>Start</span>
          </StyledRouletteStart> : null}
      <StyledRouletteBall
        ref={ballRef}
        as={animated.div}
        style={{
          transform: to(ballRotateStyle.x, value => `rotate(${value}deg)`),
        }}
        isMobile={isMobile}
      >
        <img src={isMobile ? mobileRouletteBall : rouletteBall} alt="ball"/>
      </StyledRouletteBall>
    </StyledRouletteRollWrapper>
  );
}));

export default Roulette;
