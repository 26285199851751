import React, { useEffect, useState } from "react";
import Tabs, { TabPane } from "rc-tabs";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import ExampleFormFields from "./elements/ExampleFormFields";
import ExampleButtons from "./elements/ExampleButtons";
import ExampleAlertMessages from "./elements/ExampleAlertMessages";
import ExampleTable from "./elements/ExampleTable";
import ExampleDialog from "./elements/ExampleDialog";

const ExampleTab = () => {
  const history = useHistory();

  const changeTab = (key) => {
    setDefaultKey(key);
  };

  const urlForTabs = () => {
    let queryParams = queryString.parse(history.location.search);

    if (!queryParams.currentTab) {
      return "formFields";
    }

    switch (queryParams.currentTab) {
      case "formFields": {
        return "formFields";
      }
      case "buttons": {
        return "buttons";
      }
      case "alert": {
        return "alert";
      }
      case "table": {
        return "table";
      }
      case "dialog": {
        return "dialog";
      }
    }
  };

  const [defaultKey, setDefaultKey] = useState(urlForTabs);

  useEffect(() => {
    history.push("?currentTab=" + defaultKey);
  }, [defaultKey]);

  return (
    <Tabs
      defaultActiveKey={defaultKey}
      tabPosition="top"
      className="default-tabs default-tabs-top"
      onChange={changeTab}
    >
      <TabPane tab="Form fields" key="formFields">
        <ExampleFormFields />
      </TabPane>
      <TabPane tab="Buttons" key="buttons">
        <ExampleButtons />
      </TabPane>
      <TabPane tab="Alerts" key="alert">
        <ExampleAlertMessages />
      </TabPane>
      <TabPane tab="Table" key="table">
        <ExampleTable />
      </TabPane>
      <TabPane tab="Dialog" key="dialog">
        <ExampleDialog />
      </TabPane>
    </Tabs>
  );
};

export default ExampleTab;