import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import AutoBetsByLoseOrWins from "./AutoBetsByLoseOrWins";
import { StyledButton } from "../../styles/styledButton";
import Dialog from "rc-dialog";
import { useTranslation } from "react-i18next";
import { useBetween } from "use-between";
import {
  useAutoBetsStatesDice,
  useAutoBetsStatesBalls,
  useAutoBetsStatesRoulette,
} from "./AutoBetsStates";
import { StyledFlexInline } from "./styledAutoBets";
import AlertMessage from "../alert/Alert";
import AutoBetsLimits from "./AutoBetsLimits";
import AutoBetsAcceleration from './AutoBetsAcceleration';
import { GAMES } from '../../games/Constants';
import BalanceStates from '../../games/BalanceStates';


const AutoBetsContainer = ({
  game: currentGame,
  toPlay,
  responseData,
  submitData,
  setSubmitData,
  setStopAnimation = null,
  errorData,
  setErrorData,
  isMobile,
  isPlay,
  animationDisabled,
  resetBet,
}) => {
  const [autoBetsModalVisible, setAutoModalBetsVisible] = useState(false);
  const { t } = useTranslation("games");

  const {
    balance,
  } = useBetween(BalanceStates);

  const diceAutoBetsData = useAutoBetsStatesDice();
  const ballsAutoBetsData = useAutoBetsStatesBalls();
  const rouletteAutoBetsData = useAutoBetsStatesRoulette();

  const {
    autoModeIsStart,
    setAutoModeIsStart,
    setAutoMode,
    autoMode,
    setGame,
    game,
    startAutoBets,
    gameInfoRef,
    isDisableAutoBet,
    setIsDisableAutoBet,
    isStartRequestStopAutoBets,
    setIsStartRequestStopAutoBets,
  } = useMemo(
    () => {
      const data = {
        [GAMES.DICE]: diceAutoBetsData,
        [GAMES.BALLS]: ballsAutoBetsData,
        [GAMES.ROULETTE]: rouletteAutoBetsData,
      }
      return data[currentGame];
    },
    [currentGame, diceAutoBetsData, ballsAutoBetsData, rouletteAutoBetsData],
  );

  const [values, setValues] = useState({ ...autoMode });
  const isAutoStartRef = useRef(false);
  const isButtonPressRef = useRef(false);

  const waitFullStop = useCallback(() => new Promise((resolve) => {
    const check = () => {
      // console.log('waitFullStop,check,gameInfoRef.current.isLeavePage:', gameInfoRef.current.isLeavePage);
      if (!gameInfoRef.current.isLeavePage) {

        resolve();
        return;
      }
      setTimeout(check, 20);
      return;
    };
    check();
  }), [gameInfoRef]);

  useEffect(() => {
    if (gameInfoRef.current) {
      gameInfoRef.current.animationDisabled = animationDisabled;
    }
  }, [animationDisabled, gameInfoRef]);

  useEffect(() => {
    if (gameInfoRef.current) {
      gameInfoRef.current.balance = balance;
    }
  }, [balance, gameInfoRef]);

  useEffect(() => {
    if (gameInfoRef.current) {
      gameInfoRef.current.resetBet = resetBet;
    }
  }, [resetBet, gameInfoRef]);

  useEffect(() => {
    (async () => {
      if (gameInfoRef.current) {
        // await waitFullStop();
        gameInfoRef.current.responseData = responseData;
      }
    })();
    /*
    if (gameInfoRef.current) {
      gameInfoRef.current.responseData = responseData;
    }
    */
  }, [responseData, gameInfoRef, waitFullStop]);

  useEffect(() => {
    (async () => {
      if (gameInfoRef.current) {
        // await waitFullStop();
        gameInfoRef.current.submitData = submitData;
      }
    })();
    /*
    if (gameInfoRef.current) {
      gameInfoRef.current.submitData = submitData;
    }
    */
  }, [submitData, gameInfoRef, waitFullStop]);

  useEffect(() => {
    (async () => {
      if (gameInfoRef.current) {
        // await waitFullStop();
        gameInfoRef.current.errorData = errorData;
      }
    })();
  }, [errorData, gameInfoRef, waitFullStop]);

  useEffect(() => {
    (async () => {
      if (gameInfoRef.current) {
        // await waitFullStop();
        gameInfoRef.current.toPlay = toPlay;
      }
    })();
  }, [toPlay, gameInfoRef, waitFullStop]);

  useEffect(() => {
    (async () => {
      if (gameInfoRef.current) {
        // await waitFullStop();
        gameInfoRef.current.autoModeIsStart = autoModeIsStart;
      }
    })();
  }, [autoModeIsStart, gameInfoRef, waitFullStop]);

  useEffect(() => {
    (async () => {
      if (gameInfoRef.current) {
        // await waitFullStop();
        gameInfoRef.current.setSubmitData = setSubmitData;
      }
    })();
  }, [setSubmitData, gameInfoRef, waitFullStop]);

  useEffect(() => {
    (async () => {
      if (gameInfoRef.current) {
        // await waitFullStop();
        gameInfoRef.current.setErrorData = setErrorData;
      }
    })();
  }, [setErrorData, gameInfoRef, waitFullStop]);

  useEffect(() => {
    (async () => {
      if (gameInfoRef.current) {
        // await waitFullStop();
        gameInfoRef.current.setStopAnimation = setStopAnimation;
      }
    })();
  }, [setStopAnimation, gameInfoRef, waitFullStop]);

  useEffect(() => {
    /*
    (async () => {
      console.log('CREATE GAME:', currentGame)
      if (gameInfoRef.current) {
        console.log('waitFullStop,isLeavePage',gameInfoRef.current.isLeavePage);
        // await waitFullStop();
        console.log('stop waitFullStop,isLeavePage',gameInfoRef.current.isLeavePage);
        gameInfoRef.current.isUserStop = false;
      }
      // setGame(currentGame);
    })();
    */
    setGame(currentGame);
  }, [gameInfoRef, currentGame, setGame, waitFullStop]);

  const formatAutoBet = useCallback((values) => {
    const newValues = { ...values };
    newValues['lose']['countOfSubsequence'] = Number(newValues['lose']['countOfSubsequence']);
    if (isNaN(newValues['lose']['countOfSubsequence']) || newValues['lose']['countOfSubsequence'] < 1) {
      newValues['lose']['countOfSubsequence'] = 1;
    }
    newValues['wins']['countOfSubsequence'] = Number(newValues['wins']['countOfSubsequence']);
    if (isNaN(newValues['wins']['countOfSubsequence']) || newValues['wins']['countOfSubsequence'] < 1) {
      newValues['wins']['countOfSubsequence'] = 1;
    }

    if (newValues["lose"]["increase"]["value"] !== '') {
      newValues["lose"]["increase"]["value"] = Number(newValues["lose"]["increase"]["value"]);
      if (isNaN(newValues["lose"]["increase"]["value"])) {
        newValues["lose"]["increase"]["value"] = ''
      }
    }

    if (newValues["wins"]["increase"]["value"] !== '') {
      newValues["wins"]["increase"]["value"] = Number(newValues["wins"]["increase"]["value"]);
      if (isNaN(newValues["wins"]["increase"]["value"])) {
        newValues["wins"]["increase"]["value"] = ''
      }
    }

    if (newValues["lose"]["decrease"]["value"] !== '') {
      newValues["lose"]["decrease"]["value"] = Number(newValues["lose"]["decrease"]["value"]);
      if (isNaN(newValues["lose"]["decrease"]["value"])) {
        newValues["lose"]["decrease"]["value"] = ''
      }
    }

    if (newValues["wins"]["decrease"]["value"] !== '') {
      newValues["wins"]["decrease"]["value"] = Number(newValues["wins"]["decrease"]["value"]);
      if (isNaN(newValues["wins"]["decrease"]["value"])) {
        newValues["wins"]["decrease"]["value"] = ''
      }
    }

    if (newValues["maxCountOfBet"] !== '') {
      newValues["maxCountOfBet"] = Number(newValues["maxCountOfBet"]);
      if (isNaN(newValues["maxCountOfBet"])) {
        newValues["maxCountOfBet"] = '';
      }
    }

    if (newValues["stopByMinBalanceAmount"] !== '') {
      newValues["stopByMinBalanceAmount"] = Number(newValues["stopByMinBalanceAmount"]);
      if (isNaN(newValues["stopByMinBalanceAmount"])) {
        newValues["stopByMinBalanceAmount"] = '';
      }
    }

    if (newValues["stopByMaxBalanceAmount"] !== '') {
      newValues["stopByMaxBalanceAmount"] = Number(newValues["stopByMaxBalanceAmount"]);
      if (isNaN(newValues["stopByMaxBalanceAmount"])) {
        newValues["stopByMaxBalanceAmount"] = '';
      }
    }

    if (newValues["stopByMaxBetsAmount"] !== '') {
      newValues["stopByMaxBetsAmount"] = Number(newValues["stopByMaxBetsAmount"]);
      if (isNaN(newValues["stopByMaxBetsAmount"])) {
        newValues["stopByMaxBetsAmount"] = '';
      }
    }
    console.log('newValues:', newValues);
    return newValues;
  }, []);


  const getGameInfo = useCallback(() => gameInfoRef.current, [gameInfoRef]);

  const handleStartClick = useCallback(() => {
    console.log('handleStartClick:', isButtonPressRef.current)
    if (isButtonPressRef.current) {
      return true;
    }
    isButtonPressRef.current = true;

    isAutoStartRef.current = true;
    setAutoModalBetsVisible(false);
    // startAutoBets();
    setAutoMode({
      ...formatAutoBet(values),
      firstBet: submitData.bet,
      firstSign: submitData.sign,
      firstSuggestedNumbers: submitData.suggestedNumbers,
    });

    setTimeout(() => {
      isButtonPressRef.current = false;
    }, 1000);

    /*
    setTimeout(() => {
      startAutoBets();
    }, 500);
    */
    return true;
  }, [isAutoStartRef, setAutoMode, submitData.bet, submitData.sign, submitData.suggestedNumbers, formatAutoBet, values]);

  useEffect(() => {
    if (isAutoStartRef.current) {
      isAutoStartRef.current = false;
      startAutoBets();
    }
  }, [startAutoBets, isAutoStartRef]);

  useEffect(() => {
    // setAutoModeIsStart(false);
    // gameInfoRef.current.isUserStop = false;
    // gameInfoRef.current.isLeavePage = false;
    if (getGameInfo().pausedGame[game]) {
      // getGameInfo().pausedGame[game]['autoMode']
      /*
      console.log('NEED START PAUSED GAME', game);
      console.log('getGameInfo().pausedGame[game]:', getGameInfo().pausedGame[game])
      console.log('getGameInfo().pausedGame[game][currentSuggestedNumbers]:', getGameInfo().pausedGame[game]['currentSuggestedNumbers']);
      setAutoMode(getGameInfo().pausedGame[game]['autoMode']);
      setSubmitData((prevState) => ({
        ...prevState,
        bet: getGameInfo().pausedGame[game]['currentBet'],
        suggestedNumbers: getGameInfo().pausedGame[game]['currentSuggestedNumbers'],
        sign: getGameInfo().pausedGame[game]['currentSign'],
      }));

      setTimeout(() => {
        startAutoBets();
      }, 1000);
      */
      getGameInfo().pausedGame[game] = null;
      /*
      autoMode,
      currentBet,
      currentSuggestedNumbers,
      currentSign,
      */
    }
    return () => {
      /*
      console.log('AUTO BET FOR PAGE DID UNMOUNT:', game);
      console.log('getGameInfo().autoModeIsStart:', getGameInfo().autoModeIsStart);
      if (getGameInfo().autoModeIsStart) {
        // getGameInfo().autoModeIsStart = false;
        getGameInfo().isLeavePage = true;
      } else {
        getGameInfo().isLeavePage = false;
      }
      */
    }
  }, [getGameInfo, setAutoModeIsStart, game, setSubmitData, setAutoMode, startAutoBets]);

  useEffect(() => {
    let timeoutId = 0;
    const checkAnimationCount = () => {
      timeoutId = 0;
      // console.log('gameInfoRef.current.animationCount:', gameInfoRef.current.animationCount);
      if (gameInfoRef.current.animationCount > 0) {
        setIsDisableAutoBet(true);
      } else {
        setIsDisableAutoBet(false);
      }
      timeoutId = setTimeout(checkAnimationCount, 400);
    }
    checkAnimationCount();
    return () => {
      if (timeoutId > 0) {
        clearTimeout(timeoutId);
        timeoutId = 0;
      }
    }
  });

  const waitEndPlay = useCallback(() => new Promise((resolve) => {
    const check = () => {
      if (!getGameInfo().isPlay) {
        resolve();
        return;
      }
      if (!getGameInfo().autoModeIsStart) {
        resolve();
        return;
      }
      setTimeout(check, 5);
    }
    check();
  }), [getGameInfo]);

  const waitStartPlay = useCallback(() => new Promise((resolve) => {
    const check = () => {
      if (getGameInfo().isPlay) {
        resolve();
        return;
      }
      if (!getGameInfo().autoModeIsStart) {
        resolve();
        return;
      }
      setTimeout(check, 5);
    }
    check();
  }), [getGameInfo]);

  const handleClickAuto = useCallback((event) => {
    if (autoModeIsStart) {
      console.log('REQUEST STOP AUTO BETS', 'isPlay:',   getGameInfo().isPlay);
      event.currentTarget.disabled = true;
      const el = event.currentTarget;
      setIsStartRequestStopAutoBets(true);
      (async () => {
        await waitEndPlay();
        let isAnimation = false;
        if (currentGame === GAMES.ROULETTE && autoMode.acceleration === 0 && !animationDisabled) {
          isAnimation = true;
        } else if (currentGame === GAMES.DICE && (autoMode.acceleration < 3 && !animationDisabled)) {
          isAnimation = true;
        } else if (currentGame === GAMES.BALLS && (autoMode.acceleration < 3 && !animationDisabled)) {
          isAnimation = true;
        }

        if (!isAnimation) {
          await waitStartPlay();
        }
        setAutoModeIsStart(false);
        setIsStartRequestStopAutoBets(false);
        setTimeout(() => el.disabled = false, 1000);
      })();
      // getGameInfo().isUserStop = true;

      return;
    }
    if (getGameInfo().animationCount === 0) {
      setAutoModalBetsVisible(true);
    }
  }, [autoModeIsStart, setAutoModeIsStart, setIsStartRequestStopAutoBets, getGameInfo, waitEndPlay, waitStartPlay, autoMode.acceleration, currentGame, animationDisabled]);

  // console.log('');
  // console.log('disabledAutoButton1:', (!autoModeIsStart && isPlay));
  // console.log('disabledAutoButton2:', (!autoModeIsStart && isDisableAutoBet));
  // console.log('disabledAutoButton3:', isStartRequestStopAutoBets);
  return (
    <>
      <StyledButton
        color="neutral"
        onClick={handleClickAuto}
        isMobile={isMobile}
        isAutoBet={true}
        disabled={(!autoModeIsStart && isPlay) || (!autoModeIsStart && isDisableAutoBet) || isStartRequestStopAutoBets}
        style={(isMobile && currentGame === GAMES.ROULETTE) ? {
          marginTop: '0px',
          marginBottom: '10px',
        } : {}}
      >
        {(!autoModeIsStart || isStartRequestStopAutoBets) ? t("auto") : t("stop")}
      </StyledButton>
      <Dialog
        visible={autoBetsModalVisible}
        wrapClassName="default-modal-wrapper"
        onClose={() => setAutoModalBetsVisible(false)}
        animation="zoom"
        maskAnimation="fade"
        title={t("autoBets")}
        footer={<StyledButton
          color="neutral"
          width="100"
          onClick={handleStartClick}
          disabled={autoModeIsStart || isPlay}
        >
          {t('start')}
        </StyledButton>}
        forceRender={false}
        className="default-modal-wider"
      >
        <StyledFlexInline>
          <AutoBetsByLoseOrWins
            game={currentGame}
            autoMode={values}
            setAutoMode={setValues}
            direction="lose"
          />
          <AutoBetsByLoseOrWins
            game={currentGame}
            autoMode={values}
            setAutoMode={setValues}
            direction="wins"
          />
        </StyledFlexInline>
        <AutoBetsAcceleration
          autoMode={values}
          setAutoMode={setValues}
        />
        <AutoBetsLimits
          autoMode={values}
          setAutoMode={setValues}
        />

        <AlertMessage
          type="warning"
          message={t("automatedBettingHelps") + " "}
          messageBold={t("pleaseUseIt")}
          mt="20"
        />
      </Dialog>
    </>
  );
};

export default AutoBetsContainer;
