const navbar = {
  ACCOUNT: "account:sidebar",
  GOODS: "goods:sidebar",
  PROJECTS: "projects:sidebar",
  CLIENTS: "clients:sidebar",
  BATCHES: "batches:sidebar",
  PAYMENT_SETTINGS: "payment_settings:sidebar",
  CURRENCIES: "currencies:sidebar",
  ACCOUNT_SETTINGS: "account_settings:sidebar",
  CASH_FLOW_STATEMENT: "cash_flow_statement:sidebar",
  PROFIT_REPORTS: "profit_reports:sidebar",
  NEWS: "news:sidebar",
  FEEDBACK: "feedback:sidebar",
  LOGS: "logs:sidebar",
  TRANSACTIONS: "transactions:sidebar"
};

const goods = {
  ADMIN: "goods:admin",
  USER: "goods:user"
};

const account = {
  CLIENT: "account:client",
};

const clients = {
  ADMIN: "clients:admin"
};

const cashFlow = {
  ADMIN: "cashFlow:admin",
  USER:  "cashFlow:user"
};

const feedbacks = {
  ACTIONS: "feedbacks:actions"
};

const logs = {
  ADMIN: "logs:admin"
};

const projects = {
  ADMIN: "projects:admin",
  CLIENT: "projects:client"
};

const paymentSettings = {
  ADMIN: "paymentSettings:admin",
};

const currencies = {
  ADMIN: "currencies:admin",
};

const transactions = {
  ADMIN: "transactions:admin",
};

const news = {
  ADMIN: "news:admin",
  CLIENT: "news:client",
};

const transactionAndRainHistory = {
  CLIENT: "transaction-and-rain-history:client",
};

export {
  navbar,
  goods,
  clients,
  account,
  feedbacks,
  projects,
  logs,
  cashFlow,
  paymentSettings,
  currencies,
  news,
  transactions,
  transactionAndRainHistory
};
