import styled from "styled-components";
import { StyledChooseStakingPeriod } from "../styledStakingPage";

export const StyledChoosePlanModal = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledChooseStakingPeriodExtended = styled(StyledChooseStakingPeriod)`
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
  
  & > div {
    min-width: 73px;
    min-height: 74px;
    background-color: ${({ theme }) => theme.staking.table.period.backgroundColor};
    ${({ theme }) => theme.staking.table.period.borderNone && 'border-color: transparent;'};

    & > span:first-child {
      font-size: 17px;
      font-weight: ${({ theme }) => theme.staking.table.mainTextFontWeight};
    }

    & > span:nth-child(2) {
      color: ${({ theme }) => theme.staking.table.textColor};
      font-size: 12px;
    }

    & > span:nth-child(3) {
      font-size: 16px;
      width: 61px;
      text-align: center;
      margin-top: 5px;
      padding-top: 2px;
      border-top: solid ${({ theme }) => theme.staking.table.period.borderColor} 1px;
      transition: all 0.3s linear 0s;
    }

    &.active > span:nth-child(3) {
      border-color: #307cad;
    }
  }
`;

export const StyledNextCycleProgress = styled.div`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100px;
  background-color: ${({ theme }) => theme.staking.progress.backgroundColor};
  border: solid ${({ theme }) => theme.staking.progress.borderColor} 1px;
  border-top-width: 3px;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;

  & > div, & > span {
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & > div {
    color: ${({ theme }) => theme.staking.progress.mainTextColor};
    font-size: 21px;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: -3px;
    bottom: 0;
    width: ${({ progress }) => progress !== undefined ? `${progress}%` : '100%'};
    transition: width 0.4s linear 0s;
    border-top: solid #2f7bad 3px;
    background-color: ${({ theme }) => theme.staking.progress.progressColor};
    z-index: 1;
  }
`;

export const StyledStakingProfit = styled.div`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  display: flex;
  flex-direction: column;

  & > span:first-child {
    font-size: 16px;
  }

  & > span:last-child {
    font-size: 24px;
    color: #2eac5b;
  }
`;