import React from 'react';
import { useTranslation } from "react-i18next";
import {
  StyledStatisticsBlock,
  StyledStatisticsBlockLabel,
  StyledStatisticsBlockItem,
} from "../styledStatistics";

const StatisticsResults = React.memo(({ countOfBet, countOfWins, countOfLose }) => {
  const { t } = useTranslation("leftSidebar");
  return (
    <StyledStatisticsBlock className="statistic-flex" flex>
      <StyledStatisticsBlockItem className="statistic-one-block">
        <div className="num">
          {countOfBet}
        </div>
        <StyledStatisticsBlockLabel mb="2">
          {t("countOfBet")}
        </StyledStatisticsBlockLabel>
      </StyledStatisticsBlockItem>
      <StyledStatisticsBlockItem className="statistic-one-block" color="wins">
        <div className="num">
          {countOfWins}
        </div>
        <StyledStatisticsBlockLabel mb="2">
          {t("countOfWins")}
        </StyledStatisticsBlockLabel>
      </StyledStatisticsBlockItem>
      <StyledStatisticsBlockItem className="statistic-one-block" color="lose">
        <div className="num">
          {countOfLose}
        </div>
        <StyledStatisticsBlockLabel mb="2">
          {t("countOfLose")}
        </StyledStatisticsBlockLabel>
      </StyledStatisticsBlockItem>
    </StyledStatisticsBlock>
  )
});

export default StatisticsResults;
