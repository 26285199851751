import React from "react";
import Nickname from "../elements/nickname/Nickname";

const LoginNicknameForm = ({ authData, setAuthData, loading }) => {

  const handleSubmit = (event) => {
    event.preventDefault();

    setAuthData(prevState => ({
      ...prevState,
      body: {
        nickname: event.target.nickname.value
      }
    }));
  };

  return (
    <Nickname handleSubmit={handleSubmit} loading={loading} />
  );
};

export default LoginNicknameForm;