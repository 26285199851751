import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as SessStorage from '../../../utils/sessionStorage';
import { StyledBetButton, StyledWrapperButtons } from "./styledBetAction";

const BetAction = React.memo(({
  isPlay,
  value,
  setValue,
  handleChange,
  min,
  max,
  isRoulette = false,
  submitData = null,
}) => {
  const { t } = useTranslation("games");

  const handleMulti = useCallback(() => {
    if (isPlay) {
      return;
    }
    if (value * 2 <= max && value > 0) {
      if (isRoulette) {
        let allBet = 0;
        let newObjectBets = {};
        const suggestedNumbers = submitData.suggestedNumbers;
        Object.entries(suggestedNumbers).map((array) => {
          newObjectBets[array[0]] = array[1] * 2;
          allBet += array[1] * 2;
        });

        setValue(allBet.toString());
        handleChange({
          suggestedNumbers: newObjectBets,
          bet: allBet.toString()
        }, true);
      } else {
        const stringValue = (value * 2).toFixed(8).toString();

        setValue(stringValue);
        handleChange && handleChange({
          name: "bet",
          value: stringValue
        }, true);
      }
    }
  }, [isRoulette, handleChange, max, setValue, submitData, value, isPlay]);

  const handleDivision = useCallback(() => {
    if (isPlay) {
      return;
    }
    if (value / 2 >= min) {
      if (isRoulette) {
        let allBet = 0;
        let newObjectBets = {};
        const suggestedNumbers = submitData.suggestedNumbers;
        Object.entries(suggestedNumbers).map((array) => {
          newObjectBets[array[0]] = array[1] / 2;
          allBet += array[1] / 2;
        });

        setValue(allBet.toString());
        handleChange({
          suggestedNumbers: newObjectBets,
          bet: allBet.toString()
        }, true);
      } else {
        const stringValue = (value / 2).toFixed(8).toString();

        setValue(stringValue);
        handleChange && handleChange({
          name: "bet",
          value: stringValue
        }, true);
      }
    }
  }, [isRoulette, handleChange, min, setValue, submitData, value, isPlay])

  const setMin = useCallback(() => {
    if (isPlay) {
      return;
    }
    const stringValue = min.toString();

    setValue(stringValue);
    handleChange && handleChange({
      name: "bet",
      value: stringValue
    }, true);
  }, [min, handleChange, setValue, isPlay]);

  const setMax = useCallback(() => {
    if (isPlay) {
      return;
    }
    if (window.confirm(t("confirmMaxBet"))) {
      const stringValue = max.toFixed(8).toString();

      setValue(stringValue);
      handleChange && handleChange({
        name: "bet",
        value: stringValue
      }, true);
    }
  }, [max, handleChange, setValue, t, isPlay]);

  const setRepeat = useCallback(() => {
    if (isPlay) {
      return;
    }
    // const suggestedNumbers = submitData.suggestedNumbers;
    // const doubledBet = Object.values(suggestedNumbers)[Object.values(suggestedNumbers).length - 1] * 2;
    // const allSuggestedNumbers = Object.keys(suggestedNumbers)[Object.keys(suggestedNumbers).length - 1];
    let prevBet = SessStorage.getItem("RoulettePrevBet");
    if (prevBet) {
      handleChange({
        suggestedNumbers: prevBet.suggestedNumbers, // {...suggestedNumbers, [allSuggestedNumbers]: doubledBet},
        bet: prevBet.bet.toFixed(8).toString(),
      }, true);
    }
  }, [handleChange, isPlay])

  const setClear = useCallback(() => {
    if (isPlay) {
      return;
    }
    const {suggestedNumbers} = submitData;
    if (suggestedNumbers) {
      let sum = 0;
      for (let bet of Object.values(suggestedNumbers)) {
        sum += bet;
      }
      if (sum > 0) {
        SessStorage.setItem("RoulettePrevBet", { suggestedNumbers,  bet: sum });
      }
    }

    handleChange({
      suggestedNumbers: {},
      bet: "0"
    }, true);
  }, [handleChange, submitData, isPlay]);

  if (isRoulette) {
    return (
      <StyledWrapperButtons>
        <StyledBetButton isPlay={isPlay} onClick={handleMulti}>x2</StyledBetButton>
        <StyledBetButton isPlay={isPlay} onClick={handleDivision}>1/2</StyledBetButton>
        <StyledBetButton isPlay={isPlay} onClick={setRepeat}>r</StyledBetButton>
        <StyledBetButton isPlay={isPlay} onClick={setClear}>c</StyledBetButton>
      </StyledWrapperButtons>
    );
  }

  return (
    <StyledWrapperButtons>
      <StyledBetButton
        onClick={handleMulti}
        isPlay={isPlay}
      >
        x2
      </StyledBetButton>
      <StyledBetButton
        onClick={handleDivision}
        isPlay={isPlay}
      >
        1/2
      </StyledBetButton>
      <StyledBetButton
        onClick={setMin}
        isPlay={isPlay}
      >
        min
      </StyledBetButton>
      <StyledBetButton
        onClick={setMax}
        isPlay={isPlay}
      >
        max
      </StyledBetButton>
    </StyledWrapperButtons>
  );
});

export default BetAction;
