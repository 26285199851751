import { useState } from "react";
import { lang } from "../../../utils/getLanguage";
import { listLanguage } from "../../../utils/listLanguage";

const LanguageStates = () => {
  const [isActiveLang, setIsActiveLang] = useState(false);
  const [activeLang, setActiveLang] = useState(lang() ? lang() : listLanguage.defaultLang);

  return {
    isActiveLang,
    setIsActiveLang,
    activeLang,
    setActiveLang
  };
}

export default LanguageStates;