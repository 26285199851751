import React from "react";
import { useTranslation } from 'react-i18next';
import { StyledAnimationToggler } from "./styledAnimationToggler";

const AnimationToggler = ({animationDisabled}) => {
  const { t } = useTranslation('siteOptions');

  return (
    <StyledAnimationToggler animationDisabled={animationDisabled}>
      <span className="icon animation-icon" />
      <span className="text">{t('animation')}</span>
    </StyledAnimationToggler>
  );
};

export default AnimationToggler;
