const checkObjectIsNullish = (obj) => {

  let $flag = false;

  for (const [key, value] of Object.entries(obj)) {
    if (value === null || value === "") {
      $flag = true;
    }
  }

  return $flag;

};

export default checkObjectIsNullish;