import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import BallItem from "./BallItem";
import useSound from "use-sound";
import { playSound } from "../../../utils/checkSound";
import { animated, easings, useSpring } from "@react-spring/web";

import { StyledBallsRow, StyledBallsSelectWrapper, StyledMagnet } from "./styledBalls";
import { useAnimationSync } from '../AnimationSyncDataStates';
// import { useBetween } from "use-between";
import { useAutoBetsStatesBalls } from "../../elements/autoBets/AutoBetsStates";
import winSound from "../../../assets/sounds/balls/balls-win.wav";
import loseSound from "../../../assets/sounds/balls/balls-lose.wav";

const BallsNumberSelection = ({
  submitData,
  setSubmitData,
  responseData,
  isRunMagnet,
  setIsRunMagnet,
  animationDisabled,
  autoMode,
  autoModeAcceleration,
  isMobile,
  windowWidth
}) => {
  const { gameInfoRef } = useAutoBetsStatesBalls();
  const magnetRef = useRef();
  const activeGameRef = useRef(false);
  const magnetDirectionRef = useRef("right");
  const [magnetPosition, setMagnetPosition] = useState(0);
  const magnetPositionRef = useRef(magnetPosition);
  const [activeBall, setActiveBall] = useState(null);
  const [playWinSound] = useSound(winSound);
  const [playLoseSound] = useSound(loseSound);
  const { stopAnimationSync } = useAnimationSync();

  const [magnetAnimStyle, magnetAnim] = useSpring(
    () => ({
      config: {
        duration: 200
      }
    }),
    []
  );

  const rowWidth = useMemo(() => windowWidth >= 1000 ? 960 : windowWidth - 40, [windowWidth]);
  const lastRowWidth = useRef(rowWidth);

  const positionToBackgroundX = useCallback((position) => {
    if (!isMobile) {
      return position * 90;
    }
    return (((rowWidth - 50) / 10) * position);
  }, [rowWidth, isMobile]);

  const backgroundXToPosition = useCallback((backgroundX) => {
    if (!isMobile) {
      return backgroundX / 90;
    }
    return (backgroundX) / ((rowWidth - 50) / 10);
  }, [rowWidth, isMobile]);

  const selectNumbers = useCallback((value) => {
    if (isRunMagnet || autoMode) {
      return;
    }
    let tmp = JSON.parse(submitData.suggestedNumbers);
    if (!Array.isArray(tmp)) {
      tmp = [];
    }
    let index = tmp.indexOf(value);
    if (index > -1) {
      tmp.splice(index, 1);
    } else {
      tmp.push(value);
    }

    if (tmp.length <= 10) {
      setSubmitData((prevState) => ({
        ...prevState,
        suggestedNumbers: JSON.stringify(tmp)
      }));
    }
  }, [setSubmitData, submitData, isRunMagnet, autoMode]);

  const handleGameEnding = useCallback(() => {
    if (responseData.lose > 0) {
      playSound(playLoseSound);
    } else {
      playSound(playWinSound);
    }
    setIsRunMagnet(false);
    activeGameRef.current = false;
    if (gameInfoRef.current.animationCount > 0) {
      gameInfoRef.current.animationCount--;
    }
    stopAnimationSync();
  }, [
    playLoseSound,
    playWinSound,
    responseData,
    setIsRunMagnet,
    stopAnimationSync,
    gameInfoRef,
  ]);

  const runMagnet = useCallback((positon) => new Promise((resolve) => {
    magnetAnim.stop();
    if (!magnetRef.current) {
      resolve();
    }
    const currentBackgroundPositionX = parseFloat(magnetRef.current.style.backgroundPositionX.replace("px", "")) || 0;
    const currentPosition = backgroundXToPosition(currentBackgroundPositionX); // currentBackgroundPositionX / 90;
    let gameDuration = 200;
    if (autoMode && autoModeAcceleration === 2) {
      gameDuration = 100;
    } else if (autoMode && autoModeAcceleration === 1) {
      gameDuration = 150;
    }
    const baseAnimData = {
      from: {
        backgroundPositionX: currentBackgroundPositionX
      },
      to: {
        backgroundPositionX: positionToBackgroundX(positon) //positon * 90,
      },
      config: {
        duration: gameDuration,
        easing: null
      }
    };

    const onUpdateBalls = (value) => {
      const ballIndex = magnetDirectionRef.current === "right"
        ? Math.ceil(backgroundXToPosition(value))
        : Math.ceil(backgroundXToPosition(value - (isMobile ? ((rowWidth - 50) / 10) : 90)));
      setActiveBall(ballIndex >= 0 ? ballIndex : 0);
    };

    if (magnetDirectionRef.current === "right" && positon > currentPosition) {
      baseAnimData.config.duration = (positon - currentPosition) * gameDuration;
      magnetAnim.start({
        ...baseAnimData,
        onResolve: () => {
          resolve();
        },
        onChange: (result, spring, item) => {
          onUpdateBalls(result.value.backgroundPositionX);
        }
      });
    } else if (magnetDirectionRef.current === "right" && positon <= currentPosition) {
      baseAnimData.to.backgroundPositionX = positionToBackgroundX(10);
      baseAnimData.config.duration = (10 - currentPosition) * gameDuration;
      magnetAnim.start({
        ...baseAnimData,
        onResolve: () => {
          magnetDirectionRef.current = "left";
          runMagnet(positon).then(() => {
            resolve();
          });
        },
        onChange: (result, spring, item) => {
          onUpdateBalls(result.value.backgroundPositionX);

        }
      });
    } else if (magnetDirectionRef.current === "left" && positon < currentPosition) {
      baseAnimData.config.duration = (currentPosition - positon) * gameDuration;
      magnetAnim.start({
        ...baseAnimData,
        onResolve: () => {
          resolve();
        },
        onChange: (result, spring, item) => {
          onUpdateBalls(result.value.backgroundPositionX);
        }
      });
    } else if (magnetDirectionRef.current === "left" && positon >= currentPosition) {
      baseAnimData.to.backgroundPositionX = 0;
      baseAnimData.config.duration = (currentPosition) * gameDuration;
      magnetAnim.start({
        ...baseAnimData,
        onResolve: () => {
          magnetDirectionRef.current = "right";
          runMagnet(positon).then(() => {
            resolve();
          });
        },
        onChange: (result, spring, item) => {
          onUpdateBalls(result.value.backgroundPositionX);
        }
      });
    }
  }), [magnetAnim, backgroundXToPosition, positionToBackgroundX, isMobile, rowWidth, autoMode, autoModeAcceleration]);

  useEffect(() => {
    const { drawnNumber } = responseData || {};

    if (activeGameRef.current || drawnNumber === undefined || !isRunMagnet) {
      return;
    }
    activeGameRef.current = true;
    gameInfoRef.current.animationCount++;
    if (animationDisabled || (autoMode && autoModeAcceleration === 3)) {
      magnetAnim.stop();
      const currentBackgroundPositionX = parseFloat(magnetRef.current.style.backgroundPositionX.replace("px", "")) || 0;

      const baseAnimData = {
        from: {
          backgroundPositionX: currentBackgroundPositionX
        },
        to: {
          backgroundPositionX: positionToBackgroundX(drawnNumber) // drawnNumber * 90,
        },
        config: {
          duration: 50,
          easing: easings.easeOutQuint
        }

      };
      stopAnimationSync();
      magnetAnim.start({
        ...baseAnimData,
        onResolve: () => {
          setMagnetPosition(drawnNumber);
          setActiveBall(drawnNumber);
          // if (!autoMode) {
          handleGameEnding();
          // }
          activeGameRef.current = false;
        }
      });
    } else {
      setActiveBall(null);
      runMagnet(drawnNumber).then(() => {
        setMagnetPosition(drawnNumber);
        setActiveBall(drawnNumber);
        handleGameEnding();
      });
    }
  }, [
    isRunMagnet,
    responseData,
    animationDisabled,
    handleGameEnding,
    activeGameRef,
    magnetPositionRef,
    autoMode,
    autoModeAcceleration,
    runMagnet,
    magnetAnim,
    positionToBackgroundX,
    gameInfoRef,
    stopAnimationSync
  ]);

  useEffect(() => {
    if (responseData?.error) {
      setActiveBall(null);
    }
  }, [responseData]);

  useEffect(() => {
    if (responseData?.error) {
      handleGameEnding();
      setMagnetPosition(0);
      setActiveBall(null);
    }
  }, [responseData?.error, handleGameEnding]);

  useEffect(() => {
    magnetPositionRef.current = magnetPosition;
  }, [magnetPosition]);

  useEffect(() => {
    if (isMobile && lastRowWidth.current !== rowWidth) {

      magnetAnim.stop();
      const currentBackgroundPositionX = parseFloat(magnetRef.current.style.backgroundPositionX.replace("px", "")) || 0;

      const baseAnimData = {
        from: {
          backgroundPositionX: currentBackgroundPositionX
        },
        to: {
          backgroundPositionX: positionToBackgroundX(magnetPositionRef.current) // drawnNumber * 90,
        },
        config: {
          duration: 50,
          easing: easings.easeOutQuint
        }

      };

      magnetAnim.start({
        ...baseAnimData,
        onResolve: () => {
        }
      });

      lastRowWidth.current = rowWidth;
    }

  }, [rowWidth, isMobile, lastRowWidth, magnetAnim, positionToBackgroundX]);

  return (
    <StyledBallsSelectWrapper isMobile={isMobile}>
      <StyledMagnet
        ref={magnetRef}
        animationDisabled={animationDisabled || (autoMode && autoModeAcceleration === 3)}
        isRunMagnet={isRunMagnet}
        as={animated.div}
        style={magnetAnimStyle}
        isMobile={isMobile}
      />
      <StyledBallsRow isMobile={isMobile}>
        {Array(11).fill(1).map((button, i) => (
          <BallItem
            key={i}
            index={i}
            selectNumbers={selectNumbers}
            submitData={submitData}
            isActiveBall={activeBall === i}
            isRunMagnet={isRunMagnet}
            autoMode={autoMode}
            animationDisabled={animationDisabled || (autoMode && autoModeAcceleration === 3)}
            isMobile={isMobile}
            rowWidth={rowWidth}
          />
        ))}
      </StyledBallsRow>
    </StyledBallsSelectWrapper>
  );
};

export default BallsNumberSelection;
