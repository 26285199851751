import axios from "axios";
import userAuthenticationConfig from "./userAuthenticationConfig";

export const checkCookiesAndSet = (trustedCookies, setCookie, history) => {
  let params = (new URL(document.location)).searchParams;

  trustedCookies.forEach(cookie => {
    if (!!params.get(cookie)) {
      setCookie(cookie, params.get(cookie), { path: "/", maxAge: process.env.REACT_APP_REF_TOKEN_EXPIRES });
      if (cookie === "ref") {
        axios.post("/api/user-referral-ips", userAuthenticationConfig()).then(() => { history.push("/");});
      }
    }
  });
};