import { useEffect, useState } from "react";

export const useLayout = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [orientation, setOrientation] =
    useState(window.matchMedia(
      "(orientation: landscape)").matches? "landscape" : "portrait"
    );

  useEffect(() => {
    const updateSize = () => {
      setWindowWidth(window.innerWidth);
      setIsMobile(window.innerWidth <= 1100);
    }
    updateSize();
    window.addEventListener("resize", updateSize);

    const isOrientationLandscape = window.matchMedia("(orientation: landscape)");
    isOrientationLandscape.onchange = () => {
      if (window.innerWidth <= 1100) {
        setOrientation(isOrientationLandscape.matches ? "landscape" : "portrait");
      } else {
        setOrientation("landscape");
      }
    }
    return () => {
      window.removeEventListener("resize", updateSize);
      isOrientationLandscape.onchange = null;
    }
  }, []);

  return { isMobile, windowWidth, orientation };
};
