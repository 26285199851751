import styled from "styled-components";
import { device } from "../styles/responsive/responsiveUtils";

export const StyledDetailsWrapper = styled.div`
  background: url("${({
    game,
    theme
  }) => game === 'dice' ? theme.bgIdDice : game === 'balls' ? theme.bgIdBalls : theme.bgIdRoulette}") no-repeat 50% 0;
  background-size: 100% auto;
  padding: 35px 0 35px;

  .discount, .verified {
    position: absolute;
    top: 0;
    font-size: 14px;
    background: #e21f3a;
    padding: 1px 5px;
    border-radius: 3px;
    text-transform: uppercase;
    line-height: normal;
    letter-spacing: normal;
  }

  .verified {
    background: #2eab5b;
    color: #ffffff;
    margin-left: 5px;
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > div:nth-child(4n) {
      margin-right: 0;
    }

    @media screen and ${device('desktop')} {
      justify-content: space-around;

      & > div {
        width: 190px;
      }
    }
    @media screen and (max-width: 992px) {
      & > div {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 540px) {
      & > div {
        width: 100%;
      }
    }
  }

  @media screen and ${device('desktop')} {
    padding: 65px 0 0 0;
  }
  
  @media screen and (max-width: 540px) {
    .discount, .verified {
      margin: auto;
      display: block;
      position: relative;
      width: fit-content;
    }
  }
`;

export const StyledProvablyFair = styled.div`
  margin: 10px 0 50px;
  padding: 20px 35px;
  background: ${({ theme }) => theme.alertMessageBg};

  .code-field {
    margin-top: 6px;
    font-size: 14px;
    padding: 7px 20px;
    background-color: ${({ theme }) => theme.inputBorderColorDisabled};
    color: ${({ theme }) => theme.readOnlyInput};
    width: 100%;
    border: 1px solid ${({ theme }) => theme.inputBorderColor};
    box-shadow: ${({ theme }) => theme.inputBoxShadow};
    border-radius: 3px;
    word-break: break-word;
  }
`;

export const StyledBetDetailsWrapper = styled.div`
    max-width: 1300px;
    margin: 0 auto;
    .custom-hint {
    position: relative;
    width: 0;
    transform: translate(15px, -30px);
    z-index: 100;
  }
`;