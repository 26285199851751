import { createGlobalStyle } from "styled-components";

import popupHeadBg from "../../assets/images/backgrounds/popup__head.webp";
import { device } from "./responsive/responsiveUtils";

const mainFont = "SegoeUI, sans-serif";
const mainFontSize = "16px";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: ${mainFont};
    font-size: ${mainFontSize};
    letter-spacing: 0.2px;
    line-height: 1.2;
    color: ${({ theme }) => theme.mainTextColor};
    background: ${({ theme }) => theme.body};

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 10px;
      height: 10px;
      background-color: ${({ theme }) => theme.body};
    }

    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.scrollTrack};
    }

    &::-webkit-scrollbar-thumb {
      height: 8px;
      background-color: ${({ theme }) => theme.scrollThumb};
    }

    &::-webkit-scrollbar-corner {
      background-color: ${({ theme }) => theme.body};
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: ${({ theme }) => theme.scrollThumbHover};
    }

    #root {
      transition: all 0.25s ease-in-out;
    }

    .landslideLeft {
      margin-left: 250px;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }

  div {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 10px;
      height: 10px;
      background-color: ${({ theme }) => theme.body};
    }

    &::-webkit-scrollbar-corner {
      background-color: ${({ theme }) => theme.body};
    }

    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.scrollTrack};
    }

    &::-webkit-scrollbar-thumb {
      height: 8px;
      background-color: ${({ theme }) => theme.scrollThumb};

      &:hover {
        background-color: ${({ theme }) => theme.scrollThumbHover};
      }
    }
  }

  h1, h2, h3, h4, h5, h6, p, span, select, input {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  }

  ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  input::-webkit-search-decoration,
  input::-webkit-search-cancel-button,
  input::-webkit-search-results-button,
  input::-webkit-search-results-decoration {
    display: none;
  }

  input, textarea {
    color: #555555;
  }

  button {
    padding: 0;
    color: inherit;
    font: inherit;
    background-color: transparent;
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: all 0.15s ease-in-out;
  }

  a:hover, a:focus, a:active {
    text-decoration: none;
  }

  .default-link {
    &._blue {
      color: #058bd8;
      border-bottom: 1px dashed #058bd8;
    }

    &:hover {
      color: #058bd8;
      border: none;
    }
  }

  //Progressbar custom style
  #nprogress .bar {
    height: 2px;
    background: #058bd8;
    z-index: 1999;
  }

  #nprogress .peg {
    box-shadow: 0 0 2px #058bd8, 0 0 2px #058bd8;
  }

  #nprogress .spinner-icon {
    display: none;
    border-top-color: #058bd8;
    border-left-color: #058bd8;
  }

  .rc-dialog-mask {
    z-index: 999;
    background: ${({ theme }) => theme.dialogMask.backgroundColor};
  }

  //AntDesign dialog window
  .default-modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    .rc-dialog-close {
      width: 28px;
      height: 28px;
      padding: 7px;
      color: #FFFFFF;
      font-size: 12px;
      text-shadow: none;
      border: 1px solid #FFFFFF;
      border-radius: 50%;
      top: 15px;
      outline: none;
      z-index: 30;
      transition: all .3s ease;
      opacity: 1;

      &-x:after {
        content: "\\e910";
        font-size: 12px;
        font-family: 'cryptomc-icons', serif;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .rc-dialog-content {
      border-radius: 5px;
      background-color: ${({ theme }) => theme.elementBg};
    }

    .rc-dialog-header {
      padding: 15px 20px;
      background-image: url(${popupHeadBg}), linear-gradient(90deg, rgba(56, 118, 101, 1) 0%, rgba(118, 175, 113, 1) 65%, rgba(77, 129, 73, 1) 100%);
      background-size: 100% 100%;
      background-position: top center;
      background-repeat: no-repeat;
      border-radius: 5px 5px 0 0;
      border: none;

      .rc-dialog-title {
        width: 90%;
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        z-index: 1;
        position: relative;
      }
    }

    .rc-dialog-footer {
      background-color: ${({ theme }) => theme.elementBg};
      text-align: unset;
      padding: 20px;
      border: unset;
      border-radius: 0 0 5px 5px;
    }

    .rc-dialog-body {
      padding: 20px;
      background-color: ${({ theme }) => theme.elementBg};
      max-height: calc(100vh - 80px);
      overflow-y: auto;
      border-radius: 0 0 5px 5px;
    }

    &:has(.rc-dialog-footer) {
      .rc-dialog-body {
        padding: 20px 20px 0;
        max-height: calc(100vh - 160px);
        border-radius: 0;
      }
    }

    .default-modal {
      max-width: 500px;
      width: 86%;
      top: 0;

      @media screen and ${device('xs')} {
        width: 100%;
        margin: 0;

        .rc-dialog-body {
          padding: 20px;
          background-color: ${({ theme }) => theme.elementBg};
          max-height: calc(100vh - 120px);
          overflow-y: auto;
          border-radius: 0 0 5px 5px;
        }

        &:has(.rc-dialog-footer) {
          .rc-dialog-body {
            padding: 20px 20px 0;
            max-height: calc(100vh - 160px);
            border-radius: 0;
          }
        }
      }

      &-wider {
        max-width: 600px;
        width: 100%;
        margin: 0 auto;

        & .rc-dialog-body {
          overflow-y: auto;
          max-height: calc(92vh - 120px);
          padding: 20px 20px 0;
          border-radius: 0;
        }

        @media screen and ${device('md')} {
          width: 100%;
          margin: 0;

          & .rc-dialog-body {
            overflow-y: auto;
            height: calc(100vh - 160px);
            padding: 10px 14px;
          }

          & .rc-dialog-footer {
            padding: 10px 25px 10px 14px;
          }
        }
      }

      &-jackpot {
        margin: 0 20px;

        @media screen and ${device('md')} {
          width: 100%;
          margin: 0;

          & .rc-dialog-body {
            overflow-y: auto;
            height: calc(100vh - 160px);
            padding: 10px 14px;
          }

          & .rc-dialog-footer {
            padding: 10px 25px 10px 14px;
          }
        }

        .rc-dialog-body {
          max-height: calc(92vh - 120px);
          overflow: auto;

          &::-webkit-scrollbar {
            width: 6px;
            background-color: ${({ theme }) => theme.scrollbar};
            border-radius: 0;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background-color: ${({ theme }) => theme.scrollbarThumb};
            border: unset;
            border-radius: 0;
          }
        }

        .rc-dialog-header {
          background: none;
          border-bottom: 1px solid rgba(0, 0, 0, 0.3);
          position: relative;
          z-index: 2;

          .rc-dialog-title {
            color: ${({ theme }) => theme.subTitleColor};
          }
        }

        .rc-dialog-close {
          color: ${({ theme }) => theme.subTitleColor};
          border: 1px solid ${({ theme }) => theme.subTitleColor};
          z-index: 3;
        }
      }

      &__content {
        p {
          &:not(:last-child) {
            padding-bottom: 15px;
          }
        }
      }

      &__footer {
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100%, max-content));
        grid-gap: 15px;
        justify-content: end;
      }
    }
    
    .rain-modal {
      max-width: 510px;
    }
  }

  .default-modal-wrapper_confirm {
    .rc-dialog-close {
      display: none;
    }

    .confirm-modal {
      max-width: 400px;
      margin: 10px auto;

      &__content {
        padding-top: 70px;
        position: relative;

        &:before {
          content: "\\e957";
          width: 50px;
          height: 50px;
          color: #FEB967;
          font-size: 50px;
          font-family: 'cryptomc-icons', serif;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      @media screen and (max-width: 576px) {
        max-width: inherit;
        margin: 10px;
      }
    }
  }

  //AntDesign drawer custom style
  //.drawer.drawer-open .drawer-mask {
  //  opacity: 0.45;
  //}
  //
  //.drawer-left .drawer-content {
  //  background-color: #244D4D;
  //}
  //
  //.drawer-right .drawer-content {
  //  background-color: #244D4D;
  //}

  //AntDesign notifications
  .rc-notification {
    max-width: 550px;
    width: 100%;
    padding: 0;
    top: 35px !important;
    transform: translateX(-50%);
    z-index: 1055;
    border-radius: 4px;

    @media screen and (max-width: 576px) {
      width: 100%;
      padding: 0 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: inherit;
      left: 0 !important;
    }

    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px
    }

    .rc-notification-fade {
      overflow: hidden;
      -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.75);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.75);
      -webkit-transition: all 300ms cubic-bezier(0.175, 0.885, 0.315, 1.390);
      -moz-transition: all 300ms cubic-bezier(0.175, 0.885, 0.315, 1.390);
      -o-transition: all 300ms cubic-bezier(0.175, 0.885, 0.315, 1.390);
      transition: all 300ms cubic-bezier(0.175, 0.885, 0.315, 1.390);
      -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.315, 1);
      -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.315, 1.390);
      -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.315, 1.390);
      -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.315, 1.390);
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.315, 1.390);
    }

    .rc-notification-fade-appear-start {
      transform: scale(0.2) translateY(-200%);
      opacity: 0;
    }

    .rc-notification-fade-appear-active {
      transform: scale(1) translateY(0);
      opacity: 1;
    }

    .rc-notification-notice-close {
      width: 26px;
      height: 26px;
      padding: 2px 0 0 1px;
      color: ${({ theme }) => theme.notification.closeBtnColor};
      font-weight: 300;
      text-align: center;
      border: 1px solid ${({ theme }) => theme.notification.closeBtnColor};
      border-radius: 50%;
      top: 50%;
      transform: translateY(-12px);
      right: 20px;
      opacity: 1;
      transition: all .3s ease;

      &:hover {
        transform: translateY(-12px) rotate(180deg);
      }
    }

    .rc-notification-notice {
      max-width: 550px;
      width: 100%;
      margin: 0;
      padding: 0;
      background-color: transparent;
      border-radius: 0;
      box-shadow: none;
    }

    .message-with-icon {
      max-width: 550px;
      width: 100%;
      min-height: 60px;
      padding: 15px 60px 15px 75px;
      color: ${({ theme }) => theme.notification.fontColor};
      text-align: center;
      background-color: ${({ theme }) => theme.notification.bgColor};
      box-shadow: ${({ theme }) => theme.notification.shadowColor};
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      position: relative;
      overflow: hidden;

      &:before {
        width: 60px;
        height: 100%;
        font-size: 28px;
        font-weight: 700;
        font-family: 'cryptomc-icons', serif;
        text-align: center;
        background-color: ${({ theme }) => theme.notification.iconColor.bgColor};
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .message-with-icon.success {
      &:before {
        content: '\\e91a';
        color: ${({ theme }) => theme.notification.iconColor.success};
      }
    }

    .message-with-icon.error {
      &:before {
        content: '\\e905';
        color: ${({ theme }) => theme.notification.iconColor.error};
      }
    }

    .message-with-icon.info {
      &:before {
        content: '\\e930';
        color: ${({ theme }) => theme.notification.iconColor.info};
      }
    }

    .message-with-icon.warning {
      &:before {
        content: '\\e957';
        color: ${({ theme }) => theme.notification.iconColor.warning};
      }
    }
  }

  //AntDesign custom tabs
  //.rc-tabs-dropdown {
  //color: #FFFFFF;
  //background-color: rgba(160, 150, 130, 0.15);
  //border: 1px solid #193636;
  //}

  .default-tabs {
    border: none;
    grid-gap: 20px;

    .rc-tabs-nav-more {
      display: none;
    }

    .rc-tabs-nav-list {
      .rc-tabs-tab {
        font-size: 26px;
        border-bottom: 1px solid transparent;

        &:hover {
          opacity: 1;
        }

        .rc-tabs-tab-btn {
          outline: none;
          @media screen and (max-width: 450px) {
            font-size: 20px;
          }
        }
      }

      .rc-tabs-tab-active {
        font-weight: 400;
        opacity: 1;
      }

      .rc-tabs-ink-bar {
        display: none;
      }

      //@media screen and (max-width: 576px) {
      //  display: grid;
      //  grid-template-rows: auto;
      //  .rc-tabs-tab {
      //    margin: 0;
      //    padding: 5px 15px;
      //  }
      //  .rc-tabs-ink-bar {
      //    display: none;
      //  }
      //  .rc-tabs-tab-active {
      //    color: #FFFFFF;
      //    font-weight: 700;
      //    background-color: rgba(25, 54, 54, 1);
      //    border: 1px solid #193636;
      //    border-radius: 5px;
      //  }
      //}
    }

    .rc-tabs-content-holder {
      .rc-tabs-tabpane {
        outline: none;
      }

      overflow-x: auto;
    }

    //@media screen and (max-width: 992px) {
    //  grid-template-columns: 100%;
    //  grid-template-rows: repeat(2, auto);
    //}
  }

  .default-tabs-left {
    display: grid;
    grid-template-columns: 256px auto;

    .rc-tabs-tab {
      margin: 0;
      padding: 5px 15px;
    }

    .rc-tabs-ink-bar {
      display: none;
    }

    .rc-tabs-tab-active {
      color: #FFFFFF;
      font-weight: 700;
      background-color: rgba(25, 54, 54, 1);
      border: 1px solid #193636;
      border-radius: 5px;
    }

    @media screen and (max-width: 992px) {
      grid-template-columns: 100%;
      grid-template-rows: auto;
    }
  }

  .default-tabs-top {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content auto;
    grid-gap: 40px;
    overflow: inherit;

    .rc-tabs-nav-list {
      width: 100%;

      .rc-tabs-tab {
        margin-right: 40px;
        font-size: 26px;
        background: transparent;

        &:last-child {
          margin: 0;
        }
      }

      .rc-tabs-tab-active {
        color: ${({ theme }) => theme.tabs.textColorActive};
        border-bottom: 1px solid ${({ theme }) => theme.tabs.textColorActive};
        user-select: text;
      }
    }

    .rc-tabs-nav-more {
      min-width: 25px;
    }

    &_centered {
      .rc-tabs-nav-list {
        justify-content: center;

        .rc-tabs-tab {
          margin: 0 20px;
        }
      }
    }

    @media screen and (max-width: 450px) {
      grid-gap: 25px;
    }
    //@media screen and (max-width: 576px) {
    //  grid-template-columns: 100%;
    //  grid-template-rows: minmax(40px, auto) auto;
    //}
  }

  //AntDesign select
  .rc-select-item-empty {
    padding: 16px 22px;
  }

  .rc-select-selector {
    min-height: 40px;

    .rc-select-selection-overflow {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &-item {
        display: inline-flex;
      }
    }

    span.rc-select-selection-item, span.rc-select-selection-placeholder {
      padding: 10px 22px;
      display: flex;
    }
  }

  .rc-select-dropdown {
    border: 1px solid ${({ theme }) => theme.inputBorderColor};
    border-radius: 3px;
    box-shadow: none;
    cursor: pointer;

    .rc-select-item-option {
      padding: 10px 22px;
      background: ${({ theme }) => theme.inputBgColor};
      color: ${({ theme }) => theme.inputColor};
      cursor: pointer;
      border-bottom: 1px solid ${({ theme }) => theme.lightBackground};

      &:hover {
        background: ${({ theme }) => theme.inputBorderColor};
      }

      .option-select-item {
        display: flex;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        span {
          padding-right: 20px;
          font-size: 22px;
        }

        img {
          height: 22px;
          width: 22px;
          margin-right: 20px;
          object-fit: contain;
          object-position: center;
        }
      }
    }

    .rc-select-item-option-selected {
      background: ${({ theme }) => theme.inputBorderColor};

      .rc-select-item-option-state {
        display: none;
      }

    }
  }

  //AntDesign custom checkbox
  .default-checkbox {
    input {
      width: 17px;
      height: 17px;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }

    .rc-checkbox-inner {
      width: 17px;
      height: 17px;
      border-color: transparent;
      background-color: #fff;
      box-shadow: 0 1px 4px rgb(25 54 54 / 50%);

      &:after {
        border: none
      }
    }
  }

  .rc-checkbox-checked {
    .rc-checkbox-inner {
      background-color: #193636;

      &:after {
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
      }
    }
  }

  //AntDesign custom switch
  .default-switch {
    border: 1px solid #193636;
    background-color: #FFFFFF;

    &:after {
      background-color: #193636;
    }
  }

  .rc-switch-checked {
    border: 1px solid #193636;
    background-color: #193636;

    &:after {
      background-color: #FFFFFF;
    }
  }

  //AntDesign custom pagination
  .default-pagination {
    padding: 30px 0;
    display: flex;
    justify-content: flex-end;

    .rc-pagination-item, .rc-pagination-item-link {
      color: rgba(140, 155, 155, 0.5);;
      background-color: transparent;
      border: none;
      border-radius: 5px;
      outline: none;

      a {
        color: #193636;
      }

      &:hover {
        color: #193636;
      }
    }

    .rc-pagination-item-active {
      color: #fff;
      background: #193636;
      border-radius: 5px;

      a {
        color: #fff;
      }

      &:hover a {
        color: #fff;
      }
    }

    @media screen and (max-width: 576px) {
      justify-content: center;
    }
  }

  .tag {
    position: relative;
    color: ${({ theme }) => theme.colorsChat.tag.color};
    border-bottom: 1px ${({ theme }) => theme.colorsChat.tag.color} dashed;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    text-decoration: none;
    white-space: nowrap;
    word-break: keep-all;

    &.has-icon {
      padding-left: 17px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        width: 11px;
        height: 11px;
      }

      &.user-tag::before {
        background: ${({ theme }) => theme.colorsChat.tag.userImage};
        background-size: 11px 11px;
      }

      &.bet-tag::before {
        background: ${({ theme }) => theme.colorsChat.tag.betImage};
        background-size: 11px 11px;
      }
    }

    &.current {
      color: ${({ theme }) => theme.colorsChat.tag.currentColor};
    }

    &:hover {
      color: ${({ theme }) => theme.colorsChat.tag.hoverColor};
      border-color: ${({ theme }) => theme.colorsChat.tag.hoverColor};
    }
  }
`;
