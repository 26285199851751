import styled, { keyframes } from "styled-components";

export const StyledRightSidebar = styled.div`
  position: fixed;
  bottom: 20px;
  right: 25px;
  width: 280px;
  display: flex;
  flex-direction: column;
  z-index: 10;
`;

export const StyledNotificationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  gap: 5px;
`;

const notificationShowAnimation = keyframes`
  0% {
    transform: translateX(100%);
    max-height: 0;
  }
  100% {
    transform: translateX(0);
    max-height: 500px;
  }
`;

const notificationHideAnimation = keyframes`
  0% {
    transform: translateX(0);
    max-height: 100px;
  }
  100% {
    transform: translateX(100%);
    max-height: 0;
  }
`;

export const StyledNotification = styled.div.attrs(
  ({ animationDuration }) => ({ animationDuration: animationDuration ?? 300 }))`
  ${({ theme }) => theme.notifications.body}

  display: flex;
  flex-direction: column;
  border-width: 0 0 0 10px;
  border-style: solid;
  border-radius: 3px;
  padding: 10px;
  gap: 8px;

  -webkit-transition: all ${({ animationDuration }) => animationDuration}ms cubic-bezier(0.175, 0.885, 0.315, 1.390);
  -moz-transition: all ${({ animationDuration }) => animationDuration}ms cubic-bezier(0.175, 0.885, 0.315, 1.390);
  -o-transition: all ${({ animationDuration }) => animationDuration}ms cubic-bezier(0.175, 0.885, 0.315, 1.390);
  transition: all ${({ animationDuration }) => animationDuration}ms cubic-bezier(0.175, 0.885, 0.315, 1.390);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.315, 1.390);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.315, 1.390);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.315, 1.390);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.315, 1.390);
  opacity: 1;

  &.new {
    animation: ${notificationShowAnimation} ${({ animationDuration }) => animationDuration}ms forwards alternate ease-in-out;
  }

  &.hidden {
    animation: ${notificationHideAnimation} ${({ animationDuration }) => animationDuration}ms backwards ease-in-out;
    transform: translateX(150%);
    max-height: 0;
  }

  & > .notification-title {
    font-size: 17px;
    letter-spacing: normal;
    color: ${({ theme }) => theme.text};
    cursor: default;
    padding-right: 30px;
  }

  &.minimized > .notification-content {
    display: none;
  }

  &.minimized > .notification-time {
    display: none;
  }

  & > .notification-content {
    font-size: 14px;
    color: ${({ theme }) => theme.mainTextColor};
    line-height: 1.2;
    cursor: default;
  }

  & > .notification-time {
    ${({ theme }) => theme.notifications.time}
    padding: 3px 8px;
    text-align: center;
    font-size: 11px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    cursor: default;
  }

  & > .options {
    display: flex;
    align-items: flex-end;

    position: absolute;
    right: 10px;

    & > div {
      display: inline-block;
      margin-right: 8px;
      cursor: pointer;
      line-height: 20px;
      color: #9f9f9f;
      text-decoration: none;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;

      &:last-child {
        margin: 0;
        padding-bottom: 2px;
      }
    }
  }
`;

export const StyledNotificationsButton = styled.button`
  color: ${({ theme }) => theme.mainTextColor};
  padding: 5px;
  font-size: 14px;
  background-color: #222425;
  border-radius: 3px;
  cursor: pointer;
  opacity: 0.6;
  border: none;

  -webkit-transition: all 300ms cubic-bezier(0.175, 0.885, 0.315, 1.390);
  -moz-transition: all 300ms cubic-bezier(0.175, 0.885, 0.315, 1.390);
  -o-transition: all 300ms cubic-bezier(0.175, 0.885, 0.315, 1.390);
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.315, 1.390);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.315, 1.390);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.315, 1.390);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.315, 1.390);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.315, 1.390);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;

  &:hover, &.active {
    opacity: 1;
    color: ${({ theme }) => theme.text};
  }

  & > .notifications-counter {
    position: absolute;
    background: #e21f3a;
    color: #ffffff;
    padding: 0 4px 1px;
    font-size: 14px;
    -webkit-border-radius: 0 3px 0 3px;
    -moz-border-radius: 0 3px 0 3px;
    border-radius: 0 3px 0 3px;
    transform: scale(0.8);
    right: 10px;
  }

  ${({ theme }) => theme.notifications.button}
`;
