import React, { useCallback } from "react";
import {
  StyledRadioBox,
  StyledTitle,
  StyledSlider,
  StyledSliderBackBar,
  StyledSliderSelectedBar,
  StyledSliderPointer,
  StyledSliderClickableDummy,
} from "./styledAutoBets";
// import InputGroup from "../inputGroup/InputGroup";
import { useTranslation } from "react-i18next";

const AutoBetsAcceleration = React.memo(({ autoMode, setAutoMode }) => {
  const { t } = useTranslation("games");
  const handleSliderClick = useCallback((event) => {
    const rect = event.target.getBoundingClientRect();
    const localX = event.clientX - rect.x;
    const value = Math.round((localX / rect.width) * 3);
    setAutoMode((prevState) => ({
      ...prevState,
      acceleration: value,
    }));
  }, [setAutoMode]);
  return (
    <StyledRadioBox>
      <StyledTitle>{t("acceleration")}</StyledTitle>
      <div className="flex-inline">
        <StyledSlider
        >
          <StyledSliderBackBar>
            <StyledSliderSelectedBar
              value={autoMode.acceleration}
            />
            <StyledSliderPointer
              value={autoMode.acceleration}
            />
            <StyledSliderClickableDummy
              onClick={handleSliderClick}
            />
          </StyledSliderBackBar>
        </StyledSlider>
      </div>
    </StyledRadioBox>
  );
});

export default AutoBetsAcceleration;
