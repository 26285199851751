import styled from "styled-components";

export const StyledPageTemplateWrapper = styled.div`
  padding: 35px 0 35px;
  min-height: 470px;
  background-image: url("${({ theme }) => theme.bgTemplatePage}");
  background-position-x: center;
  background-position-y: top;
  background-size: 100% auto;
  background-repeat: no-repeat;

  .page-title {
    color: ${({ theme }) => theme.subTitleColor};
  }
`;