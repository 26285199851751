import React from "react";
import { useTranslation } from "react-i18next";
import {
  StyledChatConversations,
  StyledChatConversationsBody,
  StyledChatConversationsCloseIcon
} from "./styledChatConversations";
import { StyledChatHeader, StyledChatTitle } from "../styledSidebarChat";
import { StyledChatFriendsSearch } from "../ChatFriends/styledChatFriends";

export default function ChatConversations({ active, closeConversations }) {
  const { t } = useTranslation("leftSidebar");

  return (
    <StyledChatConversations active={active} className="chat-rooms">
      <StyledChatHeader className="chat-header">
        <StyledChatTitle>{t("conversations")}</StyledChatTitle>
        <StyledChatConversationsCloseIcon>
          <button onClick={() => closeConversations()}>✕</button>
        </StyledChatConversationsCloseIcon>
      </StyledChatHeader>
      <StyledChatConversationsBody>
        <StyledChatFriendsSearch placeholder={t("friendsSearchPlaceholder")} className={'search-input'}/>
        <div className={'content'}>
          <div className={'content__title'}>My conversations (0)</div>
          <div className={'content__results'}>No conversations found</div>
        </div>
      </StyledChatConversationsBody>
    </StyledChatConversations>
  );
}
