import {useGoogleLogin} from "@react-oauth/google";

import {StyledGoogleButton, StyledSocialButtonWrapper} from "./styledLogin";
import googleAuthenticationConfig from "../../utils/googleAuthenticationConfig";

import googleIcon from "../../assets/images/google-button.svg";

const LoginGoogleForm = ({authData, setAuthData}) => {

    const googleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => {
            setAuthData({
                ...authData,
                headers: googleAuthenticationConfig(tokenResponse.access_token)
            });
        },
    })

    return (
        <StyledSocialButtonWrapper>
            <StyledGoogleButton onClick={() => googleLogin()}>
                <img src={googleIcon} alt={"google icon"}/>
            </StyledGoogleButton>
        </StyledSocialButtonWrapper>
    );
};

export default LoginGoogleForm;