import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyledBlockContent } from "../../banners/styledBannersPage";

export const HistoryTab = ({ className, stakingData, setStakingData, active = false }) => {
  const { t } = useTranslation('staking');
  const { t: gT } = useTranslation("games");

  const fetchData = async () => {
    await new Promise(rs => setTimeout(rs, 1000)); // TODO TEST ONLY

    return [];
  }

  useEffect(() => {
    if (active) {
      setStakingData(old => ({ ...old, loading: true }));
      fetchData().then(history => {
        const newData = { history };
        if (active) {
          newData.loading = false;
        }
        setStakingData(old => ({ ...old, ...newData }))
      }).catch(error => {
        if (active) {
          setStakingData(old => ({ ...old, loading: false }));
        }
      })
    }
  }, [active, setStakingData]);

  const content = useMemo(() => {
    if (stakingData.loading) {
      return <StyledBlockContent className={'uppercase'}>{gT('loading')}</StyledBlockContent>
    }

    if (!stakingData.loading && (!stakingData.history || stakingData.history.length === 0)) {
      return <StyledBlockContent className={'uppercase'}>{gT('nothingFound')}</StyledBlockContent>
    }

    return <div>HistoryTab</div>
  }, [gT, stakingData.history, stakingData.loading]);

  return <div className={'staking-tab-content' + (active ? ' active' : '')}>
    {content}
  </div>
}

export default HistoryTab;