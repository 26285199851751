import React, {useCallback, useContext, useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import HelpContainer from "../../components/home/helpSection/HelpContainer";
import { StyledContainer, StyledContentWrapper } from "../../components/styles/styledContainer";
import {StyledMainTitle} from "../../components/styles/styledTitle";
import {
  StyledFlexWrapper,
  StyledGameItem,
  StyledGamesItemTitle, StyledHeaderLeftSidebar,
  StyledLeftSidebarGamesWrapper
} from "../../components/elements/leftSidebar/Games/styledLeftSidebarGames";
import {NavLink} from "react-router-dom";
import {generatePath} from "../../utils/getLanguage";
import {defaultCurrentStatistics, games, responseStatus, TOPICS_LIST} from "../../utils/consts";
import {useBetween} from "use-between";
import BurgerStates from "../../components/elements/mobileNavigation/BurgerStates";
import axios from "axios";
import {closableNotification} from "../../components/elements/notification/ClosableNotification";
import ucFirst from "../../utils/ucFirst";
import SiteOptions from "../../components/elements/siteOptions/SiteOptions";
import {generateJWSToken} from "../../utils/mercureAuth";
import {AppContext, MercureUrl} from "../../App";
import Cookies from "js-cookie";
import Dialog from "rc-dialog";
import {StyledButton} from "../../components/styles/styledButton";
import PaymentInvoice from "../../components/elements/payment/PaymentInvoice";
import PayoutInvoice from "../../components/elements/payout/PayoutInvoice";
import BalanceStates from "../../components/games/BalanceStates";
import http from "../../http";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";
import CurrentStatisticStates from "../../components/games/CurrentStatisticStates";
import DiceStates from "../../components/games/dice/DiceStates";

const HomePage = () => {
  const { t } = useTranslation("home");
  const { t: gT } = useTranslation("games");
  const { t: sT } = useTranslation("siteOptions");

  const {
    paymentMethods,
    setPaymentMethods,
    balance,
    setBalance,
    setActiveCurrency,
    paymentDialogVisible,
    setPaymentDialogVisible,
    payoutDialogVisible,
    setPayoutDialogVisible,
  } = useBetween(BalanceStates);

  const {
    submitData,
    setSubmitData,
  } = useBetween(DiceStates);

  const { setCurrency } = useBetween(CurrentStatisticStates);
  const [gameOnlineData, setGameOnlineData] = useState(null);
  const [sortPaymentMethods, setSortPaymentMethods] = useState(paymentMethods);
  const { authenticated } = useContext(AppContext);

  const fetchOnlineData = () => {
    axios.get("/api/game-online-count").then(response => {
      if (response.status === 200) {
        setGameOnlineData(response.data);
      }
    }).catch(error => {
      closableNotification(error.response.data.detail, "error");
    });
  };

  const getActuallyPaymentData = (paymentMethodData) => {
    let paymentMethod = JSON.parse(localStorage.getItem("paymentMethod"));

    paymentMethodData.forEach(actuallyPaymentMethod => {
      if (paymentMethod.id === actuallyPaymentMethod.id) {
        paymentMethod.balances = actuallyPaymentMethod.balances;
      }
    });

    return paymentMethod;
  };

  const getGameOnlineCount = (game) => {
    if (!Array.isArray(gameOnlineData) || gameOnlineData.length === 0) {
      return 0;
    }

    return gameOnlineData.find(item => item.game === ucFirst(game))?.count ?? 0;
  };

  const handleFillIn = useCallback(() => {
    if (authenticated) {
      setPaymentDialogVisible(true);
    } else {
      console.log(new Error().stack);
      closableNotification("Full authentication is required to access this resource.", "error");
    }
  }, [authenticated, setPaymentDialogVisible]);

  const handleBringOut = useCallback(() => {
    if (authenticated) {
      setPayoutDialogVisible(true);
    } else {
      console.log(new Error().stack);
      closableNotification("Full authentication is required to access this resource.", "error");
    }
  }, [authenticated, setPayoutDialogVisible]);

  useEffect(() => {
    fetchOnlineData();

  }, []);

  const topic = TOPICS_LIST.ONLINE.GAME;
  const token = generateJWSToken(topic);

  useEffect(() => {
    MercureUrl.searchParams.delete("topic");

    MercureUrl.searchParams.append("topic", topic);

    Cookies.set("mercureAuthorization", token, { path: "" });

    const es = new EventSource(MercureUrl, { withCredentials: true });
    const handleMessage = (data) => {
      const responseData = JSON.parse(data.data);
      setGameOnlineData(responseData);
    };

    es.addEventListener("message", handleMessage);

    return () => {
      es.removeEventListener("message", handleMessage);
      es.close();
    };
  }, []);

  const selectPaymentMethod = useCallback((value) => {
    if (authenticated) {
      localStorage.setItem("paymentMethod", JSON.stringify(value));
      localStorage.setItem("currentStatistics", JSON.stringify(defaultCurrentStatistics));
    }
    setCurrency(value.currency.asset);
    setActiveCurrency(value.currency.asset);

    setBalance(value.balances ? value.balances.amount : 0);

    setSubmitData((prevState) => ({
      ...prevState,
      paymentMethod: value
    }));
  }, [authenticated, setActiveCurrency, setBalance, setCurrency, setSubmitData]);

  const getPaymentMethod = useCallback(() => {
    http.get("/api/payment-methods", userAuthenticationConfig()).then((response) => {
      if (response.status === responseStatus.HTTP_OK) {
        setPaymentMethods(response.data["hydra:member"]);
        setSortPaymentMethods(response.data["hydra:member"]);

        if (!localStorage.getItem("paymentMethod")) {
          selectPaymentMethod(response.data["hydra:member"][0]);
        } else {
          selectPaymentMethod(getActuallyPaymentData(response.data["hydra:member"]));
        }
      }
    }).catch((error) => {
      if (error?.response?.status === responseStatus.HTTP_BAD_REQUEST) {
        console.log(new Error().stack);
        closableNotification(error.response.data.error, "error");
      }
    });
  }, [selectPaymentMethod, setPaymentMethods]);

  useEffect(() => {
    getPaymentMethod();
  }, []);

  return (
      <StyledContainer>
        <Helmet>
          <title>{t("meta.title")}</title>
          <meta
              name="description"
              content={t("meta.description")}
          />
          <link
              rel="canonical"
              href={"https://" + window.location.hostname}
          />
          <link
              rel="alternate"
              href={"https://" + window.location.hostname + "/"}
              hrefLang="en-US"
          />
          <link
              rel="alternate"
              href={"https://" + window.location.hostname + "/ru/"}
              hrefLang="ru-RU"
          />
        </Helmet>
        <HelpContainer/>
        <StyledContentWrapper>
          <SiteOptions
              isHome={true}
          />
          <StyledMainTitle className="page-title" position="center" mb="35">
            {t('gameContainer')}
          </StyledMainTitle>
          <StyledFlexWrapper>
            <StyledGameItem
                game="balls"
                as={NavLink}
                to={generatePath("/balls")}
                onClick={() => {
                  localStorage.setItem("gamePage", "/balls");
                }}
            >
              <StyledGamesItemTitle className="game-name" top="26" left="120">
                {t("balls")}
              </StyledGamesItemTitle>
              <span>{getGameOnlineCount(games.balls)} {t("users")}</span>
            </StyledGameItem>
            <StyledGameItem
                game="dice"
                as={NavLink}
                to={generatePath("/dice")}
                onClick={() => {
                  localStorage.setItem("gamePage", "/dice");
                }}
            >
              <StyledGamesItemTitle className="game-name" top="26" left="-3">
                {t("dice")}
              </StyledGamesItemTitle>
              <span>{getGameOnlineCount(games.dice)} {t("users")}</span>
            </StyledGameItem>
            <StyledGameItem
                game="roulette"
                as={NavLink}
                to={generatePath("/roulette")}
                onClick={() => {
                  localStorage.setItem("gamePage", "/roulette");
                }}
            >
              <StyledGamesItemTitle className="game-name" top="26" left="55" letterSpacing="2">
                {t("roulette")}
              </StyledGamesItemTitle>
              <span>{getGameOnlineCount(games.roulette)} {t("users")}</span>
            </StyledGameItem>
          </StyledFlexWrapper>
        </StyledContentWrapper>
        <div className="balance-actions">
          <Dialog
              visible={paymentDialogVisible}
              wrapClassName="default-modal-wrapper"
              onClose={() => setPaymentDialogVisible(false)}
              animation="zoom"
              maskAnimation="fade"
              footer={<StyledButton
                  onClick={() => setPaymentDialogVisible(false)}
                  color="neutral"
                  type="submit"
                  width="100"
              >
                {sT("close")}
              </StyledButton>}
              title={gT("titleFillIn")}
              forceRender={false}
              className="default-modal"
          >
            <PaymentInvoice
                paymentMethod={submitData?.paymentMethod}
                selectPaymentMethod={selectPaymentMethod}
                getPaymentMethod={getPaymentMethod}
                setVisible={setPaymentDialogVisible}
                visible={paymentDialogVisible}
            />
          </Dialog>
          {/*<StyledButton*/}
          {/*    color="success"*/}
          {/*    onClick={handleFillIn}*/}
          {/*>*/}
          {/*  {t("fillIn")}*/}
          {/*</StyledButton>*/}
          <Dialog
              visible={payoutDialogVisible}
              wrapClassName="default-modal-wrapper"
              onClose={() => setPayoutDialogVisible(false)}
              animation="zoom"
              maskAnimation="fade"
              title={gT("titleBringOut")}
              forceRender={false}
              className="default-modal"
          >
            <PayoutInvoice
                balance={balance}
                paymentMethod={submitData?.paymentMethod}
                setVisible={setPayoutDialogVisible}
            />
          </Dialog>
          {/*<StyledButton*/}
          {/*    color="danger"*/}
          {/*    onClick={handleBringOut}*/}
          {/*>*/}
          {/*  {t("bringOut")}*/}
          {/*</StyledButton>*/}
        </div>
      </StyledContainer>
  );
};

export default HomePage;
