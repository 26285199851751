import { StyledMenuHeader, StyledMenuHeaderItem } from "../../games/styledPaymentMethod";
import { StyledMobileNav } from "../header/styledHeader";
import React, {useCallback, useContext} from "react";
import { useBetween } from "use-between";
import BurgerStates from "./BurgerStates";
import BalanceStates from "../../games/BalanceStates";
import { useTranslation } from "react-i18next";
import { StyledBalanceNavContent } from "./styledMobileNavigation";
import {defaultCurrentStatistics} from "../../../utils/consts";
import CurrentStatisticStates from "../../games/CurrentStatisticStates";
import {AppContext} from "../../../App";

export const BalanceNav = ({setSubmitData}) => {
  const {
    isBalanceOpen,
    setIsOpenBalance
  } = useBetween(BurgerStates);

  const {
    paymentMethods,
    selectedPaymentMethod,
    setPaymentDialogVisible,
    setPayoutDialogVisible,
    setActiveCurrency,
    setBalance
  } = useBetween(BalanceStates);

  const { t } = useTranslation('games');

  const { authenticated } = useContext(AppContext);
  const { setCurrency } = useBetween(CurrentStatisticStates);

  const selectPaymentMethod = useCallback((value) => {
    if (authenticated) {
      localStorage.setItem("paymentMethod", JSON.stringify(value));
      localStorage.setItem("currentStatistics", JSON.stringify(defaultCurrentStatistics));
    }
    setCurrency(value.currency.asset);
    setActiveCurrency(value.currency.asset);

    setBalance(value.balances ? value.balances.amount : 0);

    setSubmitData((prevState) => ({
      ...prevState,
      paymentMethod: value
    }));
  }, [authenticated, setActiveCurrency, setBalance, setCurrency, setSubmitData]);

  return <StyledMobileNav isOpen={isBalanceOpen} fullscreen={true}>
    <StyledMenuHeader>
      <StyledMenuHeaderItem
        className={'nav-title'}>{selectedPaymentMethod?.currency.asset} {t('balance').toLowerCase()}</StyledMenuHeaderItem>
      <StyledMenuHeaderItem>
        <section
          className="close"
          onClick={() => setIsOpenBalance(false)}
        ></section>
      </StyledMenuHeaderItem>
    </StyledMenuHeader>
    <StyledBalanceNavContent>
      <button className="ton-payment-button" onClick={() => {
        const tonPaymentMethod = paymentMethods.find(
            (method) => method.currency.asset === 'TON'
        );
        selectPaymentMethod(tonPaymentMethod);
        setPaymentDialogVisible(true);
        setIsOpenBalance(false);
      }}>{t('tonDepositInfo')}</button>
      <button onClick={() => {
        setPaymentDialogVisible(true);
        setIsOpenBalance(false);
      }}>{t('fillIn')}</button>
      <button onClick={() => {
        setPayoutDialogVisible(true);
        setIsOpenBalance(false);
      }}>{t('bringOut')}</button>
    </StyledBalanceNavContent>
  </StyledMobileNav>
}