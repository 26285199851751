import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import { MERCURE_AUTHORIZATION_COOKIE_NAME, responseStatus, TOPICS_LIST } from "../../../../utils/consts";
import { closableNotification } from "../../notification/ClosableNotification";
import Notification from "rc-notification";
import { AppContext, MercureUrl } from "../../../../App";
import { generateJWSToken } from "../../../../utils/mercureAuth";
import Cookies from "js-cookie";
import { lang } from "../../../../utils/getLanguage";
import Rain from "../../rain/Rain";
import Dialog from "rc-dialog";
import {
  StyledChatBetExample,
  StyledChatBottomActions,
  StyledChatBottomButtons,
  StyledChatBottomImage,
  StyledChatBottomInfo,
  StyledChatBottomInfoText,
  StyledChatBottomInput,
  StyledChatEmojiBlock,
  StyledChatEmojiIcon,
  StyledChatHeader,
  StyledChatHeaderActions,
  StyledChatHeaderLang,
  StyledChatHeaderMailImg,
  StyledChatHeaderUsersImg,
  StyledChatInfoBlock,
  StyledChatInfoColouredBox,
  StyledChatInfoRow,
  StyledChatInfoRowRight,
  StyledChatLoader,
  StyledChatNoUserOrRoomWrapper,
  StyledChatText,
  StyledChatTitle,
  StyledChatWrapper,
  StyledLeftSidebarChatWrapper
} from "./styledSidebarChat";
import infoImg from "../../../../assets/images/chat/info-icon.svg";
import rainImg from "../../../../assets/images/chat/rain_icon.svg";
import smileImg from "../../../../assets/images/chat/emoji_icon.svg";
import loadingGif from "../../../../assets/images/loading.gif";

import ChatMessages from "./ChatMessages/ChatMessages";
import { StyledButton } from "../../../styles/styledButton";
import { chatEmoji } from "./chatEmoji";
import ChatRooms from "./ChatRooms/ChatRooms";
import { languages } from "../../../../utils/languages";
import { encodeMessage } from "./utils";
import AlertMessage from "../../alert/Alert";
import http from "../../../../http";
import ChatFriends from "./ChatFriends/ChatFriends";
import ChatConversations from "./ChatConversations/ChatConversations";
import { useBetween } from "use-between";
import { VisibleLeftSidebarStates } from "../VisibleLeftSidebarStates";
import CurrentStatisticsStates from "../../../games/CurrentStatisticStates";

const LeftSidebarChat = ({ visible, setVisible, setMenu, isNavigation }) => {
  const [room, setRoom] = useState(null);
  const [showEmoji, setShowEmoji] = useState(false);
  const [showRain, setShowRain] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showRooms, setShowRooms] = useState(false);
  const [showFriends, setShowFriends] = useState(false);
  const [showConversations, setShowConversations] = useState(false);
  const [messages, setMessages] = useState(null);
  const [langIndex, setLangIndex] = useState(localStorage.getItem("langIndexForChatRoom") ?? languages.indexOf(lang()));
  const [loading, setLoading] = useState(false);
  const textRef = useRef();
  const messagesContainerRef = useRef();
  const {
    message,
    setMessage,
    focus,
    setFocus
  } = useBetween(VisibleLeftSidebarStates);
  const [onlineCount, setOnlineCount] = useState(0);

  const { incrementMessages } = useBetween(CurrentStatisticsStates);

  const { user, authenticated } = useContext(AppContext);

  let topic = room?.id ? TOPICS_LIST.ROOMS.BOT_MESSAGE_TOPIC + room?.id : null;

  const [notification, setNotification] = useState({
    visible: false,
    type: "",
    message: ""
  });

  const itemsPerPage = 200;

  const { t } = useTranslation("leftSidebar");
  const { t: errorsT } = useTranslation("errors");
  const fetchRoom = async (index) => {
    return http.get(
      `/api/rooms?language.language=${languages[index]}`,
      userAuthenticationConfig()
    ).then((response) => {
      if (response.status === responseStatus.HTTP_OK) {
        return response.data["hydra:member"]?.[0] ?? null;
      }
    }).catch((error) => {
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error.response.data.error, "error");
      }
    });
  };

  const fetchMessages = async (roomId) => {
    return http.get("/api/messages?room.id=" + roomId, userAuthenticationConfig()).then((response) => {
      if (response.status === responseStatus.HTTP_OK) {
        return response.data;
      }
    }).catch((error) => {
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error.response.data.error, "error");
      }
    });
  };

  const getMessages = async () => {
    setLoading(true);

    let roomData = await fetchRoom(langIndex);

    let fetchingMessages = await fetchMessages(roomData.id);

    setRoom(roomData);
    setMessages(fetchingMessages);
    setLoading(false);

    messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
  };

  const sendMessage = () => {
    if (!authenticated) {
      closableNotification(errorsT("Full authentication is required to access this resource."), "error");
      return;
    }
    const value = textRef.current.innerHTML.replaceAll("<br>", " ").trim();

    let data = {
      value: encodeMessage(value),
      room: room.id
    };

    if (data.value.length) {
      axios.post("/api/messages", data, userAuthenticationConfig(false)).then((response) => {
        if (response.status === responseStatus.HTTP_CREATED) {
          incrementMessages();
          updateMessages(response.data);
        }
      }).catch((error) => {
        if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
          closableNotification(error.response.data.error, "error");
        }
      });
      setMessage("");
    }
  };

  const updateMessages = useCallback((message) => {
    if (!room || room.id !== parseInt(message.room.slice(message.room.lastIndexOf("/") + 1))) {
      return;
    }

    const index = messages?.findIndex((p) => p.id === message.id);

    let values = messages ?? [];

    if (index >= 0) {
      if (message.user?.mediaObject) {
        values[index].user = { ...message.user };
      } else {
        return;
      }
    } else {
      values.push(message);
    }

    setMessages([...values]);

    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages, room]);

  const resizeTextInput = () => {
    if (textRef.current) {
      textRef.current.style.height = 0;
      textRef.current.style.height = textRef.current.scrollHeight + "px";
    }
  };

  useEffect(() => {
    setLoading(true);

    MercureUrl.searchParams.delete("topic");

    MercureUrl.searchParams.append(
      "topic",
      TOPICS_LIST.MESSAGES.UPDATE_EVENT_TOPIC
    );

    const token = generateJWSToken(
      TOPICS_LIST.MESSAGES.UPDATE_EVENT_TOPIC
    );

    Cookies.set(MERCURE_AUTHORIZATION_COOKIE_NAME, token, { path: "" });

    let es = new EventSource(MercureUrl, { withCredentials: true });

    const onmessage = (event) => {
      let dataMercure = JSON.parse(event.data ?? null);
      updateMessages(dataMercure);
    };

    es.addEventListener("message", onmessage);

    setLoading(false);

    return () => {
      es.removeEventListener("message", onmessage);
      es.close();
    };
  }, [messages, room, updateMessages, user]);

  const loadRoom = async () => {
    const newLangIndex = langIndex + 1;

    const tmpLangIndex = newLangIndex === languages.length ? 0 : newLangIndex;

    localStorage.setItem("langIndexForChatRoom", tmpLangIndex);

    setLangIndex(tmpLangIndex);
  };

  useEffect(() => {
    setLoading(true);

    if (visible) {
      fetchRoom(langIndex).then(async (roomData) => {
        setRoom(roomData);
        setOnlineCount(roomData?.totalCount);
        let fetchingMessages = await fetchMessages(roomData?.id);
        setMessages(fetchingMessages);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [langIndex, visible]);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current?.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (!topic) {
      return;
    }
    MercureUrl.searchParams.delete("topic");
    MercureUrl.searchParams.append("topic", topic);
    let roomMercureToken = generateJWSToken(topic);
    Cookies.set("mercureAuthorization", roomMercureToken, { path: "" });
    const es = new EventSource(MercureUrl, { withCredentials: true });
    es.onmessage = (event) => {
      let dataMercure = JSON.parse(event.data ?? null);
      updateMessages(dataMercure);
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop = messagesContainerRef.current?.scrollHeight;
      }
    };

    return () => {
      es.close();
    };
  }, [user, room, topic, updateMessages]);

  const toggleConversations = useCallback((value = true) => {
    if (authenticated) {
      setShowConversations(value);
    } else {
      closableNotification(errorsT("Full authentication is required to access this resource."), "error");
    }
  }, [authenticated, errorsT]);

  const toggleFriends = useCallback((value = true) => {
    if (authenticated) {
      setShowFriends(value);
    } else {
      closableNotification(errorsT("Full authentication is required to access this resource."), "error");
    }
  }, [authenticated, errorsT]);

  const toggleRain = useCallback((value = true) => {
    if (authenticated) {
      setShowRain(value);
    } else {
      closableNotification(errorsT("Full authentication is required to access this resource."), "error");
    }
  }, [authenticated, errorsT]);

  useEffect(() => {
    if (textRef.current && textRef.current.innerHTML !== message) {
      textRef.current.innerHTML = message;
      resizeTextInput();
      textRef.current.focus();
    }
  }, [message, textRef.current]);

  useEffect(() => {
    if (textRef.current && focus) {
      const range = document.createRange();
      const selection = window.getSelection();

      range.selectNodeContents(textRef.current);
      range.collapse(false);

      selection.removeAllRanges();

      selection.addRange(range);
      setFocus(false);
    }
  }, [visible, textRef.current, focus]);

  useEffect(() => {

    if (!user || !room) {
      return;
    }

    let data = {
      field: "chatRoom",
      value: room?.id
    };

    axios.post("/api/update-online-data", data, userAuthenticationConfig(false)).then().catch((error) => {
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error.response.data.error, "error");
      }
    });

  }, [room]);

  const onlineTopic = TOPICS_LIST.ONLINE.ROOM;
  const onlineToken = generateJWSToken(onlineTopic);

  useEffect(() => {
    MercureUrl.searchParams.delete("topic");

    MercureUrl.searchParams.append("topic", onlineTopic);

    Cookies.set("mercureAuthorization", onlineToken, { path: "" });

    const es = new EventSource(MercureUrl, { withCredentials: true });

    es.addEventListener("message", (event) => {
      let dataMercure = JSON.parse(event.data ?? null);
      if (dataMercure['totalCount']) {
        setOnlineCount(dataMercure['totalCount']);
      }
    });

    return () => {
      es.close();
    };
  }, [room])

  const onInput = (event) => {
    resizeTextInput();

    if (textRef.current) {
      setMessage(textRef.current.innerHTML);
    }
  };

  const onKeyDown = (event) => {
    /* Any Shortcut except Ctrl + V */
    const isValidShortcut = (event.ctrlKey && event.keyCode !== 86);

    const isValidKeyCode = [8, 16, 17, 37, 38, 39, 40, 46].includes(event.keyCode);

    const text = event.target.innerText;

    if (text.length >= 200 && !isValidKeyCode && !isValidShortcut) {
      event.preventDefault();
    }
    if (textRef.current && event.key === "Enter" && event.ctrlKey) {
      sendMessage();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    let text = "";
    if (event.clipboardData || event.originalEvent.clipboardData) {
      text = (event.originalEvent || event).clipboardData.getData("text/plain");
    } else if (window.clipboardData) {
      text = window.clipboardData.getData("Text");
    }
    if (document.queryCommandSupported("insertText")) {
      document.execCommand("insertText", false, text);
    } else {
      document.execCommand("paste", false, text);
    }
  };

  const insertEmoji = (el) => {
    const img = document.createElement("img");
    img.src = el.icon;
    img.width = 24;
    img.setAttribute("data-code", el.code);
    setMessage(oldMessage => oldMessage + img.outerHTML);
  };

  const styledHeader = useMemo(() => {
    return <StyledChatHeader className="chat-header">
      <StyledChatTitle>{t("chat")}</StyledChatTitle>
      <StyledChatHeaderActions className="chat-header-actions">
        <div className={"chat-header__option mail-icon"} onClick={() => toggleConversations(true)}>
          <StyledChatHeaderMailImg alt={"mail"}/>
        </div>
        <div className={"chat-header__option"} onClick={() => toggleFriends(true)}>
          <StyledChatHeaderUsersImg alt={"users"}/>
        </div>
        <div className={"chat-header__option"} onClick={() => setShowRooms(true)}>
          <StyledChatHeaderLang>
            {languages[langIndex]}
          </StyledChatHeaderLang>
        </div>
        <div className={"chat-header__option close-d"}>
          <span
              onClick={() => {
                setVisible(false);
                setMenu("");
              }}
          >✕</span>
        </div>
      </StyledChatHeaderActions>
    </StyledChatHeader>;
  }, [t, langIndex, toggleConversations, toggleFriends]);


  if (loading) {
    return (
        <StyledLeftSidebarChatWrapper
            className={`chat-wrapper ${isNavigation ? "navigation" : ""}`}
        visible={visible}
      >
        <Notification
          notification={notification}
          setNotification={setNotification}
        />
        <section
          className="close"
          onClick={() => {
            setVisible(false);
            setMenu("");
          }}
        ></section>
        <ChatRooms
          active={showRooms}
          closeRooms={() => setShowRooms(false)}
        />
        <ChatFriends active={showFriends} closeFriends={() => toggleFriends(false)}/>
        <ChatConversations active={showConversations} closeConversations={() => toggleConversations(false)}/>
        <StyledChatWrapper>
          {styledHeader}
          <StyledChatLoader>
            <img src={loadingGif} alt={"loading..."}/>
          </StyledChatLoader>
        </StyledChatWrapper>
      </StyledLeftSidebarChatWrapper>
    );
  }

  if (!room) {
    return (
      <StyledChatNoUserOrRoomWrapper
        className={isNavigation ? "navigation" : ""}
        visible={visible}
      >
        <h3>
          {t("chat")}
        </h3>
        <section
          className="close"
          onClick={() => {
            setVisible(false);
            setMenu("");
          }}
        ></section>
        <AlertMessage
          type="warning"
          message={t("notFoundRoom")}
        />
      </StyledChatNoUserOrRoomWrapper>
    );
  }

  return (
    <StyledLeftSidebarChatWrapper
      className={`chat-wrapper ${isNavigation ? "navigation" : ""}`}
      visible={visible}
    >
      <Notification
        notification={notification}
        setNotification={setNotification}
      />
      <section
        className="close"
        onClick={() => {
          setVisible(false);
          setMenu("");
        }}
      ></section>
      <ChatRooms
        active={showRooms}
        closeRooms={() => setShowRooms(false)}
        setLangIndex={setLangIndex}
      />
      <ChatFriends active={showFriends && authenticated} closeFriends={() => setShowFriends(false)}/>
      <ChatConversations active={showConversations && authenticated}
                         closeConversations={() => setShowConversations(false)}/>
      <StyledChatWrapper>
        {styledHeader}
        <Dialog
          visible={showRain && authenticated}
          wrapClassName="default-modal-wrapper"
          onClose={() => toggleRain(false)}
          animation="zoom"
          maskAnimation="fade"
          title={t("letItRain")}
          forceRender={false}
          className="default-modal rain-modal"
        >
          <Rain
            room={room}
            setVisible={toggleRain}
          />
        </Dialog>

        <ChatMessages
          messages={messages}
          ref={messagesContainerRef}
          room={room?.id}
        />

        <div>
          <StyledChatBottomActions className="chat-bottom-actions">
            <StyledChatBottomInput
              ref={textRef}
              contentEditable
              maxlength={200}
              placeholder={t("chatPlaceholder")}
              onInput={onInput}
              onKeyDown={onKeyDown}
              onPaste={handlePaste}
              onChange={(e) => {
                if (e.currentTarget) {
                  setMessage(e.currentTarget.innerHTML);
                }
              }}
            >
            </StyledChatBottomInput>

            <StyledChatBottomButtons>
              <StyledChatBottomImage
                src={infoImg}
                onClick={() => setShowInfo(!showInfo)}
                width={20}
                height={20}
              />
              <StyledChatBottomImage
                src={rainImg}
                onClick={() => toggleRain(true)}
                width={20}
                height={20}
              />
              <StyledChatBottomImage
                src={smileImg}
                onClick={() => setShowEmoji(!showEmoji)}
                width={20}
                height={20}
              />
            </StyledChatBottomButtons>
          </StyledChatBottomActions>

          <StyledChatEmojiBlock
            active={showEmoji}
            className="chat-emoji-block"
          >
            {chatEmoji.map((el) => (
              <StyledChatEmojiIcon
                key={el.code}
                src={el.icon}
                // src={`${process.env.PUBLIC_URL || ""}${el}`}
                onClick={() => insertEmoji(el)}
              />
            ))}
          </StyledChatEmojiBlock>

          <StyledChatInfoBlock
            active={showInfo}
            className="chat-info-block"
          >
            <StyledChatInfoRow>
              <StyledChatBottomInfoText bold={true}>
                {t("sendMessage")}:
              </StyledChatBottomInfoText>
              <StyledChatInfoRowRight>
                <StyledChatInfoColouredBox capitalize>
                  Ctrl+Enter
                </StyledChatInfoColouredBox>
              </StyledChatInfoRowRight>
            </StyledChatInfoRow>

            <StyledChatInfoRow>
              <StyledChatBottomInfoText bold={true}>
                {t("addUserLink")}:
              </StyledChatBottomInfoText>
              <StyledChatInfoRowRight>
                <StyledChatInfoColouredBox>
                  {t("user")}:{t("userNameBrackets")}
                </StyledChatInfoColouredBox>
                <StyledChatBetExample>
                  {t("example")} - {t("user")}:
                  <StyledChatText>p1AYER</StyledChatText>
                </StyledChatBetExample>
              </StyledChatInfoRowRight>
            </StyledChatInfoRow>

            <StyledChatInfoRow>
              <StyledChatBottomInfoText bold={true}>
                {t("linkToAddBet")}:
              </StyledChatBottomInfoText>
              <StyledChatInfoRowRight>
                <StyledChatInfoColouredBox>
                  {t("bet")}:(id)
                </StyledChatInfoColouredBox>
                <StyledChatBetExample>
                  {t("example")} - {t("bet")}:123456
                </StyledChatBetExample>
              </StyledChatInfoRowRight>
            </StyledChatInfoRow>
          </StyledChatInfoBlock>

          <StyledChatBottomInfo className="chat-bottom-info">
            <StyledChatBottomInfoText>
              {t("onlineUsersCount", { n: onlineCount ?? 0 })}
            </StyledChatBottomInfoText>
            <StyledButton
              className={"chat_send-btn"}
              color="neutral"
              onClick={sendMessage}
              disabled={!authenticated}
            >
              {t("send")}
            </StyledButton>
          </StyledChatBottomInfo>
        </div>
      </StyledChatWrapper>

    </StyledLeftSidebarChatWrapper>
  );
};

export default LeftSidebarChat;
