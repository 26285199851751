import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import HelpContainer from "../../components/home/helpSection/HelpContainer";
import { StyledContainer, StyledContentWrapper } from "../../components/styles/styledContainer";
import { StyledPageTemplateWrapper } from "../../components/styles/styledPageTemplate";
import { StyledMainTitle } from "../../components/styles/styledTitle";
import notFoundGif from "../../assets/images/404.webp";
import { StyledNotFoundPage } from "./styledNotFoundPage";
import { generatePath, NavLink } from "react-router-dom";
import { StyledLink } from "../faq/styledFaqPage";

const NotFoundPage = () => {
  const { t } = useTranslation("siteOptions");

  return (
    <StyledContainer>
      <Helmet>
        <title>{t("meta.politic.title")}</title>
        <meta
          name="description"
          content={t("meta.politic.description")}
        />
        <link
          rel="canonical"
          href={"https://" + window.location.hostname + "/politic"}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/politic"}
          hrefLang="en-US"
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ru/politic"}
          hrefLang="ru-RU"
        />
      </Helmet>
      <HelpContainer/>
      <StyledPageTemplateWrapper>
        <StyledContentWrapper width="800">
          <StyledMainTitle className="page-title" position="center" mb="35">
            Oops! Page Not Found
          </StyledMainTitle>
          <StyledNotFoundPage position={'center'}>
            <div>The page you are looking for does not exist; it may have been moved, or removed altogether.</div>
            <div>Perhaps you can return back to the <StyledLink as={NavLink} to={generatePath('/')}>homepage</StyledLink> and see if you can find what you are looking for.</div>

            <img src={notFoundGif}/>
          </StyledNotFoundPage>
        </StyledContentWrapper>
      </StyledPageTemplateWrapper>
    </StyledContainer>
  );
};

export default NotFoundPage;