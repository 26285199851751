import { createRef } from 'react';
export const RED = [
  "1,3,5,7,9,12,14,16,18,19,21,23,25,27,30,32,34,36"
];

export const BLACK = [
  "2,4,6,8,10,11,13,15,17,20,22,24,26,28,29,31,33,35"
];

export const FIRST_TWELVE = [
  "1,2,3,4,5,6,7,8,9,10,11,12"
];

export const SECOND_TWELVE = [
  "13,14,15,16,17,18,19,20,21,22,23,24"
];

export const THIRD_TWELVE = [
  "25,26,27,28,29,30,31,32,33,34,35,36"
];

export const FIRST_HALF = [
  "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18"
];

export const SECOND_HALF = [
  "19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36"
];

export const EVEN = [
  "2,4,6,8,10,12,14,16,18,20,22,24,26,28,30,32,34,36"
];

export const ODD = [
  "1,3,5,7,9,11,13,15,17,19,21,23,25,27,29,31,33,35"
];

export const FIRST_LINE = [
  "3,6,9,12,15,18,21,24,27,30,33,36"
];

export const SECOND_LINE = [
  "2,5,8,11,14,17,20,23,26,29,32,35"
];

export const THIRD_LINE = [
  "1,4,7,10,13,16,19,22,25,28,31,34"
];

export const SPLIT_FIRST_LINE = [
  "0,3",
  "3,6",
  "6,9",
  "9,12",
  "12,15",
  "15,18",
  "18,21",
  "21,24",
  "24,27",
  "27,30",
  "30,33",
  "33,36"
];

export const SPLIT_SECOND_LINE = [
  "0,2,3",
  "2,3",
  "2,3,5,6",
  "5,6",
  "5,6,8,9",
  "8,9",
  "8,9,11,12",
  "11,12",
  "11,12,14,15",
  "14,15",
  "14,15,17,18",
  "17,18",
  "17,18,20,21",
  "20,21",
  "20,21,23,24",
  "23,24",
  "23,24,26,27",
  "26,27",
  "26,27,29,30",
  "29,30",
  "29,30,32,33",
  "32,33",
  "32,33,35,36",
  "35,36"
];

export const SPLIT_THIRD_LINE = [
  "0,2",
  "2,5",
  "5,8",
  "8,11",
  "11,14",
  "14,17",
  "17,20",
  "20,23",
  "23,26",
  "26,29",
  "29,32",
  "32,35"
];

export const SPLIT_FOUR_LINE = [
  "0,1,2",
  "1,2",
  "1,2,4,5",
  "4,5",
  "4,5,7,8",
  "7,8",
  "7,8,10,11",
  "10,11",
  "10,11,13,14",
  "13,14",
  "13,14,16,17",
  "16,17",
  "16,17,19,20",
  "19,20",
  "19,20,22,23",
  "22,23",
  "22,23,25,26",
  "25,26",
  "25,26,28,29",
  "28,29",
  "28,29,31,32",
  "31,32",
  "31,32,34,35",
  "34,35"
];

export const SPLIT_FIVE_LINE = [
  "0,1",
  "1,4",
  "4,7",
  "7,10",
  "10,13",
  "13,16",
  "16,19",
  "19,22",
  "22,25",
  "25,28",
  "28,31",
  "31,34"
];

export const SPLIT_SIX_LINE = [
  "0,1,2,3",
  "1,2,3",
  "1,2,3,4,5,6",
  "4,5,6",
  "4,5,6,7,8,9",
  "7,8,9",
  "7,8,9,10,11,12",
  "10,11,12",
  "10,11,12,13,14,15",
  "13,14,15",
  "13,14,15,16,17,18",
  "16,17,18",
  "16,17,18,19,20,21",
  "19,20,21",
  "19,20,21,22,23,24",
  "22,23,24",
  "22,23,24,25,26,27",
  "25,26,27",
  "25,26,27,28,29,30",
  "28,29,30",
  "28,29,30,31,32,33",
  "31,32,33",
  "31,32,33,34,35,36",
  "34,35,36"
];

export const TEXT_RATES = {
  "RED": RED,
  "BLACK": BLACK,
  "FIRST_TWELVE": FIRST_TWELVE,
  "SECOND_TWELVE": SECOND_TWELVE,
  "THIRD_TWELVE": THIRD_TWELVE,
  "FIRST_HALF": FIRST_HALF,
  "SECOND_HALF": SECOND_HALF,
  "EVEN": EVEN,
  "ODD": ODD,
  "FIRST_LINE": FIRST_LINE,
  "SECOND_LINE": SECOND_LINE,
  "THIRD_LINE": THIRD_LINE,
  "SPLIT_FIRST_LINE": SPLIT_FIRST_LINE,
  "SPLIT_SECOND_LINE": SPLIT_SECOND_LINE,
  "SPLIT_THIRD_LINE": SPLIT_THIRD_LINE,
  "SPLIT_FOUR_LINE": SPLIT_FOUR_LINE,
  "SPLIT_FIVE_LINE": SPLIT_FIVE_LINE,
  "SPLIT_SIX_LINE": SPLIT_SIX_LINE
};


export const WHEEL_STOPS = {
    0: 0,
    32: -10,
    15: -19.5,
    19: -29.5,
    4: -39,
    21: -49,
    2: -59,
    25: -68.7,
    17: -78.4,
    34: -88.1,
    6: -97.8,
    27: -107.7,
    13: -117.5,
    36: -127.2,
    11: -136.9,
    30: -146.7,
    8: -156.5,
    23: -166.2,
    10: -175.9,
    5: 174.5,
    24: 164.9,
    16: 155.3,
    33: 145.4,
    1: 135.8,
    20: 126.1,
    14: 116.5,
    31: 106.9,
    9: 97,
    22: 87.4,
    18: 77.7,
    29: 68,
    7: 58.2,
    28: 48.6,
    12: 38.9,
    35: 29.1,
    3: 19.3,
    26: 9.7
};

export const chipsRef = createRef({});
chipsRef.current = {};
