import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import { responseStatus } from "../../../utils/consts";
import InputGroup from "../inputGroup/InputGroup";
import AlertMessage from "../alert/Alert";
import Select, { Option } from "rc-select";
import { StyledSelect, StyledSelectLabel } from "../../styles/styledSelect";
import { StyledFlexAlign, StyledHintText } from "../inputGroup/styledInputGroup";
import { StyledModalLoader, StyledTonConnectBlock } from "./styledPaymentInvoice";
import loadingGif from "../../../assets/images/loading.gif";
import Maintenance from "../maintenance/Maintenance";
import QRCode from "qrcode.react";
import {TonConnectButton, TonConnectUIProvider, useTonConnectUI } from "@tonconnect/ui-react";
import { toNano, beginCell } from '@ton/ton'

const PaymentInvoice = ({ paymentMethod, selectPaymentMethod, getPaymentMethod }) => {

  const [tonConnectUI, setOptions] = useTonConnectUI();

  const { t } = useTranslation("siteOptions");

  const [paymentData, setPaymentData] = useState({
    loading: false,
    invoice: null,
    methods: [],
    maintenance: false,
  });
  const [authenticatorSecret, setAuthenticatorSecret] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethod);

  // const getAuthenticatorSecretUser = () => {
  //   axios.get("/api/users/authenticator-secret/" + user.userId, userAuthenticationConfig()).then(response => {
  //     if (response.status === responseStatus.HTTP_OK) {
  //       setAuthenticatorSecret(response.data);
  //     }
  //   }).catch(error => {
  //     closableNotification(error.response.data.error, "error");
  //   });
  // };
  //
  // const createPaymentInvoice = () => {
  //   axios.post("/api/user-wallets", {
  //     paymentMethod: selectedPaymentMethod
  //   }, userAuthenticationConfig()).then(response => {
  //     if (response.status === responseStatus.HTTP_CREATED) {
  //       setPaymentInvoice(response.data);
  //       setError(null);
  //
  //       if (!paymentMethod.userWallets) {
  //         getPaymentMethod();
  //         selectPaymentMethod({ ...paymentMethod, userWallets: response.data });
  //       }
  //     }
  //   }).catch(error => {
  //     setError(error.response.data.error);
  //   });
  // };
  //
  // const getPaymentMethodsSystem = () => {
  //   http.get(`/api/payment-methods?currency.asset=${selectedPaymentMethod?.currency.asset}`,
  //     userAuthenticationConfig()).
  //     then(response => {
  //       if (response.status === responseStatus.HTTP_OK) {
  //         setPaymentMethods(response.data["hydra:member"]);
  //       }
  //     }).
  //     catch(error => {
  //       closableNotification(error.response.data.error, "error");
  //     });
  // };

  const getPaymentInvoiceData = () => {
    setPaymentData((prev) => {
      return {
        ...prev,
        invoice: null,
        loading: true
      }
    })

    axios.get(`/api/payment/invoice-data/${selectedPaymentMethod.id}`, userAuthenticationConfig())
      .then(response => {
        if (response.status === responseStatus.HTTP_OK) {
          if (!paymentMethod.userWallets) {
            getPaymentMethod();
            selectPaymentMethod({ ...paymentMethod, userWallets: response.data });
            return;
          }
          setPaymentData({
            loading: false,
            methods: response.data?.paymentMethods,
            invoice: response.data?.userWallet,
            confirmations: response.data?.confirmations
          })
          setAuthenticatorSecret(response.data?.tempQrSecret);
        }
      })
      .catch(error => {
        setPaymentData((prev) => {
          return {
            ...prev,
            loading: false,
            maintenance: true
          }
        })
      })
  };

  useEffect(() => {
    setSelectedPaymentMethod(paymentMethod);
  }, [paymentMethod]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setAuthenticatorSecret(false);
    getPaymentInvoiceData();
  }, [selectedPaymentMethod]);

  const handleChangePaymentSystem = (value) => {
    setSelectedPaymentMethod(paymentData.methods.find((paymentMethod) => paymentMethod.id === value));
  };

  if (paymentData.loading) {
    return <StyledModalLoader>
      <img src={loadingGif} alt={'loading...'}/>
    </StyledModalLoader>;
  }

  return (
      <div id="payment-invoice">
        {paymentData.invoice?.value?.address && paymentData.invoice.value?.tag && selectedPaymentMethod.currency.name === 'Toncoin'?
            <StyledTonConnectBlock>
              <div className="ton-input-block">
                <input id="ton-input" placeholder={t('amount')}/>
                <div className="ton-send-button" onClick={() => {
                  if (tonConnectUI.wallet) {
                    const amount = document.getElementById('ton-input').value;
                    // const amountInNanoTons = (amount * 1e9).toFixed(0);
                    const amountInNanoTons = toNano(amount).toString();

                    const body = beginCell()
                        .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
                        .storeStringTail(paymentData.invoice.value.tag) // write our text comment
                        .endCell();

                    const transaction = {
                      validUntil: Date.now() + 10 * 60 * 1000, // 5 minutes
                      messages: [
                        {
                          address: paymentData.invoice.value.address,
                          amount: amountInNanoTons,
                          payload: body.toBoc().toString("base64") // payload with comment in body
                        },
                      ],
                    };
                    tonConnectUI.sendTransaction(transaction)
                  } else {
                    tonConnectUI.openModal()
                  }
                }}>
                  {t("send")}
                </div>
              </div>
              <TonConnectButton/>
            </StyledTonConnectBlock>
            : ''
        }
        {paymentData.invoice?.value?.address ?
            <InputGroup
                autocomplete="off"
                id="wallet"
                type="text"
                label={t("yourPersonal") + " " + selectedPaymentMethod.currency.name + " (" +
                    selectedPaymentMethod.paymentSystem.name + ") " +
                    t("address")}
                name="wallet"
                value={paymentData.invoice?.value?.address ?? ''}
                readonly
                onClick={(e) => e.target.select()}
            /> : <InputGroup
                autocomplete="off"
                id="wallet"
                type="text"
                label={t("yourPersonal") + " " + selectedPaymentMethod.currency.name + " (" +
                    selectedPaymentMethod.paymentSystem.name + ") " +
                    t("address")}
                style={{textAlign: 'center'}}
                name="wallet"
                value={t('Wallet Maintenance')}
                onClick={(e) => e.target.select()}
            />
        }

        {paymentData.invoice ?
            <>
              {paymentData.invoice.value?.tag && <InputGroup
                  autocomplete="off"
                  id="memo"
                  type="text"
                  label={t("memo")}
                  name="memo"
                  value={paymentData.invoice.value?.tag ?? ''}
                  readonly
                  onClick={(e) => e.target.select()}
              />}
              <StyledFlexAlign mb="15">
                <StyledHintText>{t('minimumAmount')}: {selectedPaymentMethod?.paymentFee?.min} {selectedPaymentMethod.currency.name}</StyledHintText>
                <StyledHintText>{t('commission')}: {Number(0).toFixed(8)} {selectedPaymentMethod.currency.name}</StyledHintText>
              </StyledFlexAlign>
              <QRCode
                  size={200}
                  bgColor="transparent"
                  fgColor="#ff7d31"
                  className="flow-data__qrcode"
                  style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
                  value={paymentData.invoice?.value?.address ?? ''}
              />
            </> :
            null
        }

        {paymentData.methods && paymentData.methods.length > 0 ?
            <>
              <StyledSelect>
                <StyledSelectLabel style={{fontSize: 16}}>{t('chooseNetwork')}:</StyledSelectLabel>
                <Select
                    getPopupContainer={() => document.getElementById("payment-invoice")}
                    className="custom-select"
                    name="category"
                    value={selectedPaymentMethod?.id}
                    defaultValue={selectedPaymentMethod?.id}
                    onChange={(value) => handleChangePaymentSystem(value)}
                >
                  {paymentData.methods.map((value, key) => (
                      <Option
                          value={value.id}
                          key={key}
                      >
                        <div className="option-select-item">
                          {value?.paymentSystem?.name}
                        </div>
                      </Option>
                  ))}
                </Select>
              </StyledSelect>
              {
                  !!paymentData.invoice && <AlertMessage
                      message={<div>
                        <div>{t("makeSurePaymentSystem", {name: selectedPaymentMethod.currency.name})}</div>
                        <div>{t("transactionConfirmations", {confirmations: paymentData?.confirmations?.confirmationsAmount})}</div>
                      </div>}
                      mt="30"
                  />
              }
            </> :
            null
        }
        {(!paymentData.invoice || paymentData.maintenance) && <Maintenance
            message={t('technicalMaintenance')}
            description={t('paymentMaintenance', {
              wallet: selectedPaymentMethod.currency.name
            })}
        />}

      </div>
  );
};

export default PaymentInvoice;