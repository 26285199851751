import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useBetween } from "use-between";
import { useTranslation } from "react-i18next";
import Dialog from "rc-dialog";
import InputGroup from "../elements/inputGroup/InputGroup";
import { closableNotification } from "../elements/notification/ClosableNotification";
import scrollIntoView from 'scroll-into-view-if-needed';

import userAuthenticationConfig from "../../utils/userAuthenticationConfig";
import {
  defaultCurrentStatistics,
  MERCURE_AUTHORIZATION_COOKIE_NAME,
  responseStatus,
  TOPICS_LIST
} from "../../utils/consts";
import { StyledButton } from "../styles/styledButton";
import {
  StyledBalanceWrapper, StyledMenuHeader, StyledMenuHeaderItem,
  StyledNothingFound,
  StyledPaymentMethodItem,
  StyledPaymentMethodList, StyledPaymentMethodListWrapper,
  StyledPaymentMethodWrapper,
  StyledSearchWrapper
} from "./styledPaymentMethod";
import { StyledPaymentMethodsIcons } from "../styles/StyledPaymentMethodsIcons";
import PaymentInvoice from "../elements/payment/PaymentInvoice";
import PayoutInvoice from "../elements/payout/PayoutInvoice";
import Balance from "./Balance";
import BalanceStates from "./BalanceStates";
import { AppContext, MercureUrl } from "../../App";
import {
  useAutoBetsStatesBalls,
  useAutoBetsStatesDice,
  useAutoBetsStatesRoulette
} from "../elements/autoBets/AutoBetsStates";
import { generateJWSToken } from "../../utils/mercureAuth";
import Cookies from "js-cookie";
import http from "../../http";
import CoinConfig from "../elements/coinConfig/CoinConfig";
import { useAnimationSyncData } from './AnimationSyncDataStates';
import CurrentStatisticStates from './CurrentStatisticStates';
import { GAMES } from './Constants';
import BurgerStates from "../elements/mobileNavigation/BurgerStates";

const PaymentMethodContainer = React.memo(({
  paymentMethod,
  // submitData,
  setSubmitData,
  responseData,
  isEnd,
  isNavigation = false,
  isRoulette = false,
  game,
}) => {
  const {
    paymentMethods,
    setPaymentMethods,
    balance,
    setBalance,
    activeCurrency,
    setActiveCurrency,
    paymentDialogVisible,
    setPaymentDialogVisible,
    payoutDialogVisible,
    setPayoutDialogVisible,
    coinConfigDialogVisible,
    setCoinConfigDialogVisible,
    coinConfig,
    setCoinConfig,
    profit,
    setProfit,
  } = useBetween(BalanceStates);
  const {
    setIsOpenCurrency,
    isOpenCurrency
  } = useBetween(BurgerStates);
  const { setCurrency } = useBetween(CurrentStatisticStates);

  const { authenticated } = useContext(AppContext);

  const [sortPaymentMethods, setSortPaymentMethods] = useState(paymentMethods);
  const [valueSearch, setValueSearch] = useState("");

  const [isNothingFound, setIsNothingFound] = useState(false);

  const lastRespIdRef = useRef(null);
  const paymentMethodListRef = useRef(null);

  const { autoModeIsStart: diceAutoModeIsStart } = useAutoBetsStatesDice();
  const { autoModeIsStart: ballsAutoModeIsStart } = useAutoBetsStatesBalls();
  const { autoModeIsStart: rouletteAutoModeIsStart } = useAutoBetsStatesRoulette();

  const autoModeIsStart = useMemo(
    () => {
      const mapAutoModeIsStart = {
        [GAMES.DICE]: diceAutoModeIsStart,
        [GAMES.BALLS]: ballsAutoModeIsStart,
        [GAMES.ROULETTE]: rouletteAutoModeIsStart,
      }
      return mapAutoModeIsStart[game];
    },
    [game, diceAutoModeIsStart, ballsAutoModeIsStart, rouletteAutoModeIsStart],
  );
  // const { autoModeIsStart } = useBetween(AutoBetsStates);

  const { t } = useTranslation("games");
  const { t: sT } = useTranslation("siteOptions");
  const { t: lT } = useTranslation("leftSidebar");

  const coinConfigDialogToggle = useCallback(() => {
    setCoinConfigDialogVisible(oldState => !oldState);
  }, [setCoinConfigDialogVisible]);

  const filteredPaymentMethods = useMemo(() => {
    const filteredMethods = sortPaymentMethods.filter(((item, index, self) => {
      if (coinConfig?.assetsVisibility === 'nonzero') {
        return item.balances ? item.balances.amount > 0 : false;
      }
      return self.findIndex(v => v.currency['@id'] === item.currency['@id']) === index;
    }));
    setIsNothingFound(filteredMethods.length === 0)
    return filteredMethods;
  }, [sortPaymentMethods, coinConfig]);

  const getItemName = useCallback((item) => {
    if (coinConfig?.coinShownNames === 'balance') {
      return item.balances?.amount ?? '0.00000000';
    }
    return item.currency.asset;
  }, [coinConfig?.coinShownNames]);

  const selectPaymentMethod = useCallback((value) => {
    if (authenticated) {
      localStorage.setItem("paymentMethod", JSON.stringify(value));
      localStorage.setItem("currentStatistics", JSON.stringify(defaultCurrentStatistics));
    }
    setCurrency(value.currency.asset);
    setActiveCurrency(value.currency.asset);

    setBalance(value.balances ? value.balances.amount : 0);

    setSubmitData((prevState) => ({
      ...prevState,
      paymentMethod: value
    }));
  }, [authenticated, setActiveCurrency, setBalance, setCurrency, setSubmitData]);

  const handleSearch = useCallback((e) => {
    const { value } = e.target;
    let resultSort = paymentMethods.filter((item) => item.currency.name.toLowerCase().includes(value.toLowerCase()) || item.currency.asset.toLowerCase().includes(value.toLowerCase()));
    setSortPaymentMethods(resultSort);
    if (sortPaymentMethods.length && resultSort.length) {
      setIsNothingFound(false);
    } else {
      setIsNothingFound(true);
    }
  }, [paymentMethods, sortPaymentMethods.length]);

  const handleClearSearch = useCallback(() => {
    setValueSearch("");
    setSortPaymentMethods(paymentMethods);
  }, [paymentMethods]);

  const handleFillIn = useCallback(() => {
    if (authenticated) {
      setPaymentDialogVisible(true);
    } else {
      console.log(new Error().stack);
      closableNotification("Full authentication is required to access this resource.", "error");
    }
  }, [authenticated, setPaymentDialogVisible]);

  const handleBringOut = useCallback(() => {
    if (authenticated) {
      setPayoutDialogVisible(true);
    } else {
      console.log(new Error().stack);
      closableNotification("Full authentication is required to access this resource.", "error");
    }
  }, [authenticated, setPayoutDialogVisible]);

  useEffect(() => {
    if (paymentMethodListRef.current && filteredPaymentMethods.length > 0 && paymentMethod) {
      const coinList = paymentMethodListRef.current
      const activeCoin = coinList.querySelector(".active");
      if (activeCoin) {
        scrollIntoView(activeCoin, {
          behavior: 'smooth',
          block: 'center',
          scrollMode: 'if-needed',
          boundary: coinList,
        });
      }
    }
  }, [paymentMethod]);

  const renderItemMethod = useMemo(() => {

    const items = filteredPaymentMethods.length !== 0 ? filteredPaymentMethods.map((value, key) => (
      <StyledPaymentMethodItem
        key={key}
        onClick={() => {
          selectPaymentMethod(value);
          setIsOpenCurrency(false);
        }}
        disabled={autoModeIsStart}
        className={`payment-method-item ${activeCurrency === value.currency.asset ? "active" : ""}`}
      >
        <div className="payment-method-item__name">
          {getItemName(value)}
        </div>
        <StyledPaymentMethodsIcons className={`payment-method-${value.currency.asset}`}/>
      </StyledPaymentMethodItem>
    )) : isNothingFound ?
      <StyledNothingFound>
        {t("nothingFound")}
      </StyledNothingFound> :
      <StyledPaymentMethodItem>{t("loading")}</StyledPaymentMethodItem>;

    return (
      <>
        {isNavigation ?
          <StyledMenuHeader>
            <StyledMenuHeaderItem className={'nav-title'}>{sT('cryptocurrency')}</StyledMenuHeaderItem>
            <StyledMenuHeaderItem>
              <button
                className={'coin-config__button'}
                onClick={coinConfigDialogToggle}
              />
              <Dialog
                visible={coinConfigDialogVisible}
                wrapClassName="default-modal-wrapper"
                onClose={coinConfigDialogToggle}
                animation="zoom"
                maskAnimation="fade"
                title={t("assetsSettingsTitle")}
                forceRender={false}
                className="default-modal"
              >
                <CoinConfig
                  coinConfig={coinConfig}
                  setCoinConfig={setCoinConfig}
                  close={coinConfigDialogToggle}
                />
              </Dialog>
              <section
                className="close"
                onClick={() => {
                  setIsOpenCurrency(false);
                }}
              ></section>
            </StyledMenuHeaderItem>
          </StyledMenuHeader> :
          null
        }
        <StyledSearchWrapper className="coin-search" isNavigation={isNavigation}>
          <input
            autoComplete="off"
            type="text"
            className={`coin-search__input ${valueSearch ? "active" : ""}`}
            placeholder={`${t("find")}...`}
            value={valueSearch}
            onChange={(e) => setValueSearch(e.target.value)}
            onKeyUp={handleSearch}
          />
          <div
            className={`coin-search__clean ${valueSearch ? "active" : ""}`}
            onClick={handleClearSearch}
          >✕
          </div>
          {!isNavigation ?
            <>
              <button
                className={'coin-config__button'}
                onClick={coinConfigDialogToggle}
              />
              <Dialog
                visible={coinConfigDialogVisible}
                wrapClassName="default-modal-wrapper"
                onClose={coinConfigDialogToggle}
                animation="zoom"
                maskAnimation="fade"
                title={t("assetsSettingsTitle")}
                forceRender={false}
                className="default-modal"
              >
                <CoinConfig
                  coinConfig={coinConfig}
                  setCoinConfig={setCoinConfig}
                  close={coinConfigDialogToggle}
                />
              </Dialog>
            </> :
            null
          }

        </StyledSearchWrapper>
        {
          isNavigation ? <StyledPaymentMethodListWrapper>{items}</StyledPaymentMethodListWrapper> : <>{items}</>
        }
      </>
    );
  }, [
    activeCurrency,
    autoModeIsStart,
    coinConfig,
    coinConfigDialogToggle,
    coinConfigDialogVisible,
    filteredPaymentMethods,
    getItemName,
    handleClearSearch,
    handleSearch,
    isNothingFound,
    selectPaymentMethod,
    setCoinConfig,
    t,
    sT,
    setIsOpenCurrency,
    valueSearch,
    isNavigation
  ]);

  const getActuallyPaymentData = (paymentMethodData) => {
    let paymentMethod = JSON.parse(localStorage.getItem("paymentMethod"));

    paymentMethodData.forEach(actuallyPaymentMethod => {
      if (paymentMethod.id === actuallyPaymentMethod.id) {
        paymentMethod.balances = actuallyPaymentMethod.balances;
      }
    });

    return paymentMethod;
  };

  const getPaymentMethod = useCallback(() => {
    http.get("/api/payment-methods", userAuthenticationConfig()).then((response) => {
      if (response.status === responseStatus.HTTP_OK) {
        setPaymentMethods(response.data["hydra:member"]);
        setSortPaymentMethods(response.data["hydra:member"]);

        if (!localStorage.getItem("paymentMethod")) {
          selectPaymentMethod(response.data["hydra:member"][0]);
        } else {
          selectPaymentMethod(getActuallyPaymentData(response.data["hydra:member"]));
        }
      }
    }).catch((error) => {
      if (error?.response?.status === responseStatus.HTTP_BAD_REQUEST) {
        console.log(new Error().stack);
        closableNotification(error.response.data.error, "error");
      }
    });
  }, [selectPaymentMethod, setPaymentMethods]);

  useEffect(() => {
    getPaymentMethod();
  }, []);

  useEffect(() => {

    if (!paymentMethod?.balances || isNavigation) {
      return;
    }

    const topic = TOPICS_LIST.BALANCES.BALANCE + paymentMethod?.balances.id;
    const token = generateJWSToken(topic);

    MercureUrl.searchParams.delete("topic");

    MercureUrl.searchParams.append("topic", topic);

    Cookies.set("mercureAuthorization", token, { path: "" });

    const es = new EventSource(MercureUrl, { withCredentials: true });

    console.log(MercureUrl.href)

    const onmessage = (event) => {
      let dataMercure = JSON.parse(event.data ?? null);
      setBalance(dataMercure.amount);
    };

    es.addEventListener('message', onmessage);

    return () => {
      es.removeEventListener('message', onmessage)
      es.close();
    };
  }, [paymentMethod, isNavigation]);

  useEffect(() => {
    if (responseData.id !== lastRespIdRef.current && isEnd === true) {
      lastRespIdRef.current = responseData.id;
      if (isRoulette) {
        if (parseFloat(responseData.win) === 0) {
          setProfit({
            win: null,
            lose: responseData.lose
          });
        } else {
          setProfit({
            win: responseData.win,
            lose: null
          });
        }
      } else if (parseFloat(responseData.lose) !== 0) {
        setProfit({
          win: null,
          lose: responseData.lose
        });
      } else if (parseFloat(responseData.win) !== 0) {
        setProfit({
          win: responseData.win,
          lose: null
        });
      }
    }
  }, [responseData.id, isEnd, lastRespIdRef, responseData.win, responseData.lose, isRoulette, setProfit]);

  const dataBalanceInfo = useMemo(() => ({ balance, profit }), [balance, profit]);
  const balanceInfo = useAnimationSyncData(dataBalanceInfo);

  return (
    isNavigation ?
      renderItemMethod :
      <StyledPaymentMethodWrapper>
        <StyledPaymentMethodList ref={paymentMethodListRef}>
          {renderItemMethod}
        </StyledPaymentMethodList>
        <StyledBalanceWrapper>
          <Balance
            balance={balanceInfo.balance}
            bet={balanceInfo.profit}
            label={`${paymentMethod?.currency?.asset || ''} ${t("balance")}`}
          />
          <InputGroup
            label={`${paymentMethod?.currency?.asset || ''} ${t('depositAddress').toLowerCase()} (${paymentMethod?.name_view || ''})`}
            type="text"
            name="wallet"
            className="form-text wallet"
            placeholder={authenticated ? sT("Wallet Maintenance") : lT('pleaseLogin')}
            value={authenticated ? (paymentMethod?.paymentFee?.active ? paymentMethod?.userWallets?.value?.address ?? sT("createAddress") : sT("Wallet Maintenance")) : lT('pleaseLogin')}
            readOnly
            onClick={(e) => {
              if (authenticated) {
                e.target.select()
              }
            }}
          />
          {paymentMethod &&
            <div className="balance-actions">
              <Dialog
                visible={paymentDialogVisible}
                wrapClassName="default-modal-wrapper"
                onClose={() => setPaymentDialogVisible(false)}
                animation="zoom"
                maskAnimation="fade"
                footer={<StyledButton
                  onClick={() => setPaymentDialogVisible(false)}
                  color="neutral"
                  type="submit"
                  width="100"
                >
                  {sT("close")}
                </StyledButton>}
                title={t("titleFillIn")}
                forceRender={false}
                className="default-modal"
              >
                <PaymentInvoice
                  paymentMethod={paymentMethod}
                  selectPaymentMethod={selectPaymentMethod}
                  getPaymentMethod={getPaymentMethod}
                  setVisible={setPaymentDialogVisible}
                  visible={paymentDialogVisible}
                />
              </Dialog>
              <StyledButton
                color="success"
                onClick={handleFillIn}
              >
                {t("fillIn")}
              </StyledButton>
              <Dialog
                visible={payoutDialogVisible}
                wrapClassName="default-modal-wrapper"
                onClose={() => setPayoutDialogVisible(false)}
                animation="zoom"
                maskAnimation="fade"
                title={t("titleBringOut")}
                forceRender={false}
                className="default-modal"
              >
                <PayoutInvoice
                  balance={balance}
                  paymentMethod={paymentMethod}
                  setVisible={setPayoutDialogVisible}
                />
              </Dialog>
              <StyledButton
                color="danger"
                onClick={handleBringOut}
              >
                {t("bringOut")}
              </StyledButton>
            </div>
          }
        </StyledBalanceWrapper>
      </StyledPaymentMethodWrapper>
  );
});

export default PaymentMethodContainer;
