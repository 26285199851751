import styled from "styled-components";
import reset from "../../../../assets/images/icons/reset.png";
import { StyledHeaderLeftSidebar } from "../Games/styledLeftSidebarGames";

export const StyledLeftSidebarStatisticsWrapper = styled.div`
  ${StyledHeaderLeftSidebar} {
    padding: 20px 20px 0;

    h3 {
      font-weight: 100;
      font-size: 28px;
      line-height: 33px;
      color: ${({ theme }) => theme.subTitleColor};
    }
  }
`;

export const StyledStatisticsTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding-bottom: 15px;

  p {
    ${({ pt }) => pt && `padding-top: ${pt}px`};
    color: #058bd8;
    font-size: 21px;
    padding-left: 20px;
  }

  button {
    font-weight: 500;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 20px;
    color: ${({ theme }) => theme.grayColorText};

    &:before {
      content: "";
      display: block;
      width: 11px;
      height: 11px;
      background: url(${reset}) no-repeat center;
      margin-right: 5px;
      transition: all 0.5s ease-in-out;
      ${({ theme }) => theme.leftSidebar.resetIcon}
    }

    &:hover {
      &:before {
        transform: rotate(360deg);
      }
    }
  }
`;

export const StyledStatisticsBlock = styled.div`
  padding: 15px;
  text-align: center;
  font-size: 18px;
  color: ${({ theme }) => theme.leftSidebar.blockTitleColor};
  border-bottom: 1px solid ${({ theme }) => theme.leftSidebar.blockBorderColor};
  ${({ theme }) => theme.leftSidebar.statisticBlock};
  ${({ flex }) => flex && `
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 0;
  `};

  &.light {
    background-color: ${({ theme }) => theme.leftSidebar.blockBgLightColor};
    border-bottom: 1px solid ${({ theme }) => theme.leftSidebar.blockBorderLightColor};
  }

  @media screen and (max-width: 1100px) {
    margin-left: 0;
  }
`;

export const StyledStatisticsBlockLabel = styled.div`
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  color: ${({ theme }) => theme.leftSidebar.labelStatistic};
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
`;

export const StyledStatisticsBlockPayment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;

  & > span {
    width: 18px;
    height: 18px;
  }
`;

const numColor = (color) => {
  switch (color) {
    case "wins":
      return `
        color: #298f4e;
      `;
    case "lose":
      return `
        color: #942a39;
      `;
  }
};

export const StyledStatisticsBlockItem = styled.div`
  min-width: 85px;
  padding: 15px 10px;

  .num {
    padding-bottom: 5px;
    ${({ color }) => numColor(color)};
  }
`;