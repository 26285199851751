import React from "react";
import { DateTime } from "luxon";
import Moment from "react-moment";
import moment from "moment";
// import 'moment/locale/fr';
import "moment/locale/ru";

import { lang } from "./getLanguage";

moment.updateLocale("en", {
  relativeTime: {
    s: "a few seconds",
    future: "a few seconds ago"

  }
});
moment.updateLocale("ru", {
  relativeTime: {
    s: "несколько секунд",
    future: "несколько секунд"
  }
});
const format = "DD-MM-YYYY HH:mm:ss";

const TimestampToDateMoment = (str) => {
  // return <Moment format={format}>{new Date(str * 1000)}</Moment>;
};

const DateToFormat = (str) => {
  // return <Moment>{str}</Moment>;
};

const TimestampToAgoFormat = (str) => {
  return (
    <div title={DateTime.fromMillis(str * 1000).toFormat("dd.MM.yyyy, HH:mm")}>
      <Moment
        locale={lang()}
        fromNow
      >{new Date(str * 1000)}</Moment>
    </div>
  );
};

const DateToTimestamp = (date) => {
  return date ? (Date.parse(date) / 1000).toString() : "";
};

const TimestampToDate = (date) => {
  return date ? new Date(date * 1000) : "";
};

const TimestampToDateTime = (timestamp) => {
  return DateTime.fromMillis(timestamp * 1000).toFormat("dd.MM.yyyy, HH:mm");
};

const DateToTimestampStart = (date) => {
  return date ? (new Date(date).setHours(0, 0, 0) / 1000) : "";
};

const DateToTimestampEnd = (date) => {
  return date ? (new Date(date).setHours(23, 59, 59) / 1000) : "";
};

const TimestampToDateTimeWithSeconds = (timestamp) => {
  return DateTime.fromMillis(timestamp * 1000).toFormat("dd.MM.yyyy, HH:mm:ss");
};

const TimestampToDateTimeWithSecondsABC = (timestamp) => {
  return <Moment
    locale={lang()}
    format="MMM DD, HH:mm:ss"
  >{new Date(timestamp * 1000)}</Moment>;
};

const MillisecondsToTimestamp = (date, endOfDay = false) => {
  if (date && endOfDay) {
    date.setHours(23, 59, 59, 999);
  }

  return date ? (Date.parse(date)).toString() : "";
};

const getRemainingTime = (targetTimestamp) => {
  const now = new Date().getTime();
  const difference = targetTimestamp * 1000 - now;

  if (difference < 0) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };
  }

  return {
    days: Math.floor(difference / (1000 * 24 * 60 * 60)),
    hours: Math.floor((difference % (1000 * 24 * 60 * 60)) / (1000 * 60 * 60)),
    minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor(difference % (1000 * 60) / 1000)
  };
};

const getRemainingTimeInHours = (targetTimestamp) => {
  const now = new Date().getTime();
  const difference = targetTimestamp * 1000 - now;

  if (difference < 0) {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0
    };
  }

  const totalSeconds = Math.floor(difference / 1000);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);

  const hoursLeft = Math.floor(totalHours % 24);
  const daysLeft = Math.floor(totalHours / 24);
  const minutesLeft = Math.floor(totalMinutes % 60);
  const secondsLeft = Math.floor(totalSeconds % 60);

  return {
    hours: hoursLeft + daysLeft * 24,
    minutes: minutesLeft,
    seconds: secondsLeft
  };
};

const getSecondsToNextMinute = (targetTimestamp = null) => {
  return 60 - Math.floor(targetTimestamp % 60);
};

const TimestampToCustomFormat = (timestamp) => {
  const dateTime = DateTime.fromMillis(timestamp * 1000);
  const month = dateTime.toFormat("LLL"); // "LLL" для скороченого найменування місяця
  const day = dateTime.toFormat("dd");
  const time = dateTime.toFormat("HH:mm:ss");

  return `${month} ${day}, ${time}`;
};

export {
  TimestampToDateMoment,
  DateToFormat,
  DateToTimestamp,
  TimestampToDate,
  TimestampToDateTime,
  DateToTimestampStart,
  DateToTimestampEnd,
  TimestampToDateTimeWithSeconds,
  TimestampToDateTimeWithSecondsABC,
  MillisecondsToTimestamp,
  TimestampToAgoFormat,
  getRemainingTime,
  getSecondsToNextMinute,
  getRemainingTimeInHours,
  TimestampToCustomFormat
};
