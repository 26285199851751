import React from "react";
import SidebarItem from "./SidebarItem";
import { NavLink } from "react-router-dom";

import routesSidebar from "./routesSidebar";

const SidebarLinkList = ({ role, handleChange }) => {

  const sidebarRoutes = routesSidebar;
  return (
    <React.Fragment>
      {sidebarRoutes.map((rout) => {
        return (
          <SidebarItem
            key={rout.title}
            role={role}
            perform={rout.perform}
            as={NavLink}
            to={rout.path}
            icon={rout.icon}
            linkTitle={rout.title}
            handleChange={handleChange}
          />
        );
      })}
    </React.Fragment>
  );
};

export default SidebarLinkList;