const basicAuthenticationConfig = (login, password) => {
  return {
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Basic " + btoa(unescape(encodeURIComponent(login + ":" + password)))
    }
  };
};

export default basicAuthenticationConfig;
