import {useState, useRef} from "react";

const initialState = {
  burger: false,
  currency: false,
  chat: false,
  profile: false,
  balance: false
};

const BurgerStates = () => {
  const balanceRef = useRef();

  const [list, setList] = useState(initialState);

  const openMenu = (name: 'burger' | 'currency' | 'chat' | 'profile' | 'balance', value = true) => {
    setList({...initialState, [name]: value});
  }

  const handleClearAll = () => {
    setList(initialState)
  };

  return {
    isOpenBurger: list.burger,
    setIsOpenBurger: (val) => openMenu('burger', val),
    isOpenCurrency: list.currency,
    setIsOpenCurrency: (val) => openMenu('currency', val),
    isOpenChat: list.chat,
    setIsOpenChat: (val) => openMenu('chat', val),
    handleClearAll,
    balanceRef,
    isOpenProfile: list.profile,
    setIsOpenProfile: (val) => openMenu('profile', val),
    isBalanceOpen: list.balance,
    setIsOpenBalance: (val) => openMenu('balance', val),
  };
}

export default BurgerStates;
