import styled from "styled-components";

export const StyledAccountSettingsWrapper = styled.div`
  color: ${({theme}) => theme.grayColorText};
  text-align: center;
  ul {
    display: flex;
    justify-content: center;
    li {
      &:not(:last-child) {
        margin-right: 20px;
      }
      button, a {
        border: none;
        outline: none;
        transition: all 0.15s ease-in-out;
        &:hover {
          color: ${({theme}) => theme.accountSettingLink.color};
        }
      }
    }
  }
  
  p {
    text-align: center;
    display: inline-flex;
    justify-content: center;
    transition: all .3s ease;
    cursor: pointer;
    &:hover {
      color: ${({theme}) => theme.accountSettingLink.color};
    }
  }
  
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

export const StyledClientsChangePassword = styled.div`
  width: 100%;
  .form-change-password {
    display: flex;
    flex-direction: column;
    &__action {
      width: 100%;
      margin: 20px auto 0;
    }
  }
`;