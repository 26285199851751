import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useBetween } from "use-between";

import LanguageStates from "./LanguageStates";
import { listLanguage } from "../../../utils/listLanguage";
import english from "../../../assets/images/flags/en.png";
import russian from "../../../assets/images/flags/ru.png";

const ListLanguage = ({setIsActiveLang}) => {
  const path = useLocation();
  const {setActiveLang, activeLang} = useBetween(LanguageStates);
  const { i18n } = useTranslation();
  let isNotEnLang = false;
  const pieceOfPath = path.pathname.split('/');

  listLanguage.listLang.forEach((item) => {
    if (pieceOfPath[1] === item && !isNotEnLang) {
      isNotEnLang = true;
    }
  })

  const changeLanguage = (lang) => {
    if (setIsActiveLang) {
      setIsActiveLang(false);
    }

    if (listLanguage.listLang.includes(lang)) {
      document.cookie = `lang=${lang}; path=/; max-age=-1`;
      document.cookie = `lang=${lang}; path=/`;
      i18n.changeLanguage(lang);
      setActiveLang(lang);
    }
  };

  const pathDefaultLang = (lang) => {
    if (lang === listLanguage.defaultLang) {
      if (isNotEnLang) {
        pieceOfPath.splice(1, 1);
        return pieceOfPath.join('/');
      }

      return path.pathname;
    } else {
      if (isNotEnLang) {
        return path.pathname;
      }
      if (path.pathname === '/') {
        return lang;
      }
      return `/${lang}${path.pathname}`;
    }
  }

  return (
    <ul className="list-language">
      <li>
        <NavLink to={pathDefaultLang('en')} onClick={() => changeLanguage('en')}>
          <img src={english} alt="en" /> English
        </NavLink>
      </li>
      <li>
        <NavLink className={`${activeLang}`} to={pathDefaultLang('ru')} onClick={() => changeLanguage('ru')}>
          <img src={russian} alt="ru" /> Русский
        </NavLink>
      </li>
    </ul>
  );
}

export default ListLanguage;