import { useState } from "react";
import { randomHash } from "../../../../utils/random";

export const CurrentHonestyControl = () => {

  const [currentHonestyControl, setCurrentHonestyControl] = useState({
    clientSeed: randomHash(16),
    serverSeedHash: "",
    game: ""
  });

  const [lastHonestyControl, setLastHonestyControl] = useState({
    clientSeed: "",
    serverSeed: "",
    serverSeedHash: ""
  });

  return {
    currentHonestyControl,
    setCurrentHonestyControl,
    lastHonestyControl,
    setLastHonestyControl,
  };
};
