import React from 'react';
import { useTranslation } from "react-i18next";
import {
  StyledStatisticsBlock,
  StyledStatisticsBlockLabel,
  StyledStatisticsBlockPayment,
} from "../styledStatistics";
import { StyledPaymentMethodsIcons } from "../../../../styles/StyledPaymentMethodsIcons";


const StatisticsBlock = React.memo(({ label, result, activeCurrency }) => {
  const { t } = useTranslation("leftSidebar");
  return (
    <StyledStatisticsBlock className={label === t("generalBet") || label === t("totalProfit") ? "light" : "maximum"}>
      <StyledStatisticsBlockLabel mb="8">
        {label}
      </StyledStatisticsBlockLabel>
      <StyledStatisticsBlockPayment>
        <StyledPaymentMethodsIcons className={`payment-method-${activeCurrency}`} mr="7"/>
        {result}
      </StyledStatisticsBlockPayment>
    </StyledStatisticsBlock>
  )
});

export default StatisticsBlock;
