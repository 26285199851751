import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import InputGroup from "../inputGroup/InputGroup";
import LoadButton from "../spinner/ButtonSpinner";

import { StyledButton } from "../../styles/styledButton";
import { StyledTwoFaForm } from "../twoFa/styledTwoFa";

const Nickname = ({ handleSubmit, loading }) => {
  const { t } = useTranslation("siteOptions");
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("nickname").focus();
  }, []);

  const validateNickname = (nickname) => {
      // Условие 1: Длина от 6 до 14 символов
      if (nickname.length < 6 || nickname.length > 14) {
          return false;
      }
      // Условие 2: Только допустимые символы (буквы, цифры, _)
      const validChars = /^[a-zA-Z0-9_]+$/;
      if (!validChars.test(nickname)) {
          return false;
      }
      // Условие 3: Не начинается и не заканчивается с _
      if (nickname.startsWith('_') || nickname.endsWith('_')) {
          return false;
      }
      // Условие 4: Не содержит более одного _
      const underscoreCount = (nickname.match(/_/g) || []).length;
      return underscoreCount <= 1;
  };

  const handleChange = (e) => {
      if (validateNickname(e.target.value))setErrors('');
  };
  const handleBlur = (e) => {
      if (validateNickname(e.target.value)) {
          setErrors('');
      } else {
          setErrors(t('nickname_error_msg'));
      }
  };
    const handleSubmitValidate = (e) => {
        e.preventDefault();

        if (!errors) {
            handleSubmit(e)
        } else {
            return false
        }
    };

  return (
    <StyledTwoFaForm onSubmit={handleSubmitValidate}>
      <InputGroup
        autocomplete="off"
        id="nickname"
        type="text"
        label={t('enterNickname')}
        name="nickname"
        className="nickname"
        onBlur={handleBlur}
        onChange={handleChange}
        error={errors}
        required
      />
      <div className="confirm-action">
        {!loading ?
          <StyledButton color="neutral" type="submit">
            {t('confirm')}
          </StyledButton> :
          <LoadButton
            text={t('confirm')}
            color="neutral"
          />
        }
      </div>
    </StyledTwoFaForm>
  );
};

export default Nickname;