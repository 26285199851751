import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MercureUrl } from "../../../../../App";
import { chatRooms, responseStatus, TOPICS_LIST } from "../../../../../utils/consts";
import { generateJWSToken } from "../../../../../utils/mercureAuth";
import AlertMessage from "../../../alert/Alert";
import { closableNotification } from "../../../notification/ClosableNotification";
import Spinner from "../../../spinner/Spinner";
import {
  StyledCharRoomsUserInfo,
  StyledChatRooms,
  StyledChatRoomsBody,
  StyledChatRoomsBodyTitle,
  StyledChatRoomsCloseIcon,
  StyledChatRoomsLang,
  StyledChatRoomsList,
  StyledChatRoomsListItem
} from "./styledChatRooms";
import { StyledChatHeader, StyledChatTitle } from "../styledSidebarChat";

export default function ChatRooms ({ active, closeRooms, setLangIndex }) {
  const { t } = useTranslation("leftSidebar");

  const [rooms, setRooms] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchRooms = () => {
    setLoading(true);

    axios.get("/api/rooms").then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setRooms(response.data);
      }
    }).catch(error => {
      closableNotification(error.response.data.error, "error");
    }).finally(() => setLoading(false));
  };

  const handleClickRoom = (index) => () => {
    localStorage.setItem("langIndexForChatRoom", index);

    setLangIndex(index);

    closeRooms();
  };

  useEffect(() => {
    fetchRooms();
  }, []);

  const onlineTopic = TOPICS_LIST.ONLINE.ROOM;
  const onlineToken = generateJWSToken(onlineTopic);

  useEffect(() => {
    MercureUrl.searchParams.delete("topic");

    MercureUrl.searchParams.append("topic", onlineTopic);

    Cookies.set("mercureAuthorization", onlineToken, { path: "" });

    const es = new EventSource(MercureUrl, { withCredentials: true });

    es.addEventListener("message", (data)=>{
      fetchRooms();
    });

    return () => {
      es.close();
    };
  }, []);

  if (loading) {
    return (
      <Spinner display="block" size="35px" />
    );
  }

  if (!rooms || rooms.length === 0) {
    return <AlertMessage type="warning" message={"No rooms"} />;
  }

  return (
    <StyledChatRooms active={active} className="chat-rooms">
      <StyledChatHeader className="chat-header">
        <StyledChatTitle>{t("rooms")}</StyledChatTitle>
        <StyledChatRoomsCloseIcon>
          <button onClick={() => closeRooms()}>✕</button>
        </StyledChatRoomsCloseIcon>
      </StyledChatHeader>
      <StyledChatRoomsBody>
        <StyledChatRoomsBodyTitle>{t("publicRooms")}</StyledChatRoomsBodyTitle>
        <StyledChatRoomsList>
          {rooms && rooms.map((room, index) => (
            <StyledChatRoomsListItem key={index} onClick={handleClickRoom(index)}>
              <StyledChatRoomsLang>
                <img
                  src={require(`../../../../../assets/images/flags/${room.language.language}.png`)}
                  alt={room.language.language}
                />
                <span>{chatRooms[room.language.language].title}</span>
              </StyledChatRoomsLang>
              <StyledCharRoomsUserInfo>{room.onlineCount ?? 0} {t("users")}</StyledCharRoomsUserInfo>
            </StyledChatRoomsListItem>
          ))}
        </StyledChatRoomsList>
      </StyledChatRoomsBody>
    </StyledChatRooms>
  );
}
