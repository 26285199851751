import styled from "styled-components";
import maintenanceImage from "../../../assets/images/maintenance-icon.svg";

export const StyledMaintenance = styled.div`
  padding-top: 2px;
  margin-top: 20px;

  & > div.mt-image {
    padding: 255px 20px 30px;
    background-color: ${({ theme }) => theme.lightBackground};
    background-image: url(${maintenanceImage});
    background-repeat: no-repeat;
    background-position: center top 35px;
    background-size: 190px 185px;
    text-align: center;
    margin-bottom: 27px;
    color: ${({ theme }) => theme.mainTextColor};
    font-size: 18px;
  }
`;