import React, { lazy } from "react";
import routes from "./routes";
import StakingPage from "../pages/staking/StakingPage";

const PartnersPage = lazy(() => import("../pages/partners/PartnersPage"));
const PartnersReferralsPage = lazy(() => import("../pages/partners/PartnersReferralsPage"));
const CompetitionPage = lazy(() => import("../pages/competition/CompetitionPage"));
const StorePage = lazy(() => import("../pages/store/StorePage"));
const SupportPage = lazy(() => import("../pages/support/SupportPage"));
const TransactionAndRainHistoryPage = lazy(() => import("../pages/transactionAndRainHistory/TransactionAndRainHistoryPage"));

const userRoutes = [
  {
    title: "Partners",
    path: "/:lang?/partners",
    exact: true,
    component: PartnersPage
  },
  {
    title: "Partners",
    path: "/:lang?/partners/referrals",
    component: PartnersReferralsPage
  },
  {
    title: "Competition",
    path: "/:lang?/konkyrs",
    exact: true,
    component: CompetitionPage
  },
  {
    title: "Store",
    path: "/:lang?/store",
    exact: true,
    component: StorePage
  },
  {
    title: "Support",
    path: "/:lang?/support",
    exact: true,
    component: SupportPage
  },
  {
    title: "Transaction and Rain history",
    path: "/:lang?/transaction/:type?",
    exact: true,
    component: TransactionAndRainHistoryPage
  },
  {
    title: "Stacking",
    path: "/:lang?/earn/:type?",
    exact: true,
    component: StakingPage
  }
];

const userRoutesConcat = userRoutes.concat(routes);

export default userRoutesConcat;