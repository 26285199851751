import axios from "axios";
import jwt_decode from "jwt-decode";
import eventBus from "./eventBus";
import { responseStatus, roles } from "./consts";
import { closableNotification } from "../components/elements/notification/ClosableNotification";

const roleAdmin = roles.ADMIN;

const authenticationRequest = (authData, setAuthenticated, history, setExistUser = null, setLoading = null, setVisible = null, setExistUserNickname, tE = null) => {

  if (!authData.headers) {
    return;
  }

  if (setLoading) {
    setLoading(true);
  }

  axios.post(`/api`, authData.body, authData.headers).then(response => {
    if (response.status === responseStatus.HTTP_OK && response.data.token) {
      localStorage.setItem("token", response.data.token);
      setAuthenticated(true);
      setVisible("");

      authentication(history, response.data.token);
      setExistUser(false);
      return;
    }

    if (response.status === responseStatus.HTTP_OK && response.data.nickname && setExistUserNickname) {
      setExistUserNickname(true);
    }

    if (setExistUser) {
      setExistUser(true);
    }

  }).catch(error => {
    if (error.response) {
      const message = tE ? tE(error.response.data.detail) : error.response.data.detail;

      console.log(new Error().stack);
      closableNotification(message, "error");
    }
  }).finally(() => setLoading(false));
};

const authentication = (history, token, setExistUser) => {
  const { userId, roles } = jwt_decode(token);

  eventBus.dispatch("login-user", { "id": userId });
  if (roles.includes(roleAdmin)) {
    history.push("/panel/clients");
  }

  // history.push("/");
};

export { authenticationRequest, authentication };
