export const tagRegex = /(?:\b|:)coin:([A-Za-z0-9_]{1,30})(?!\w|:)/gm;

export const CoinTag = ({ coinSymbol }) => {
    let coinIconPath;

    try {
        coinIconPath = require(`../../../assets/images/coins/svg/${coinSymbol.toLowerCase()}.svg`);
    } catch (e) {
        console.error(e);
    }

    if (!coinIconPath) {
        return coinSymbol;
    }

    return (
        <img
            className="coin-icon"
            src={coinIconPath}
            alt={`${coinSymbol.toLowerCase()} icon`}
            width={"auto"}
            height={"16px"}
            style={{
                verticalAlign: "top",
                maxWidth: "16px",
                marginTop: "2px",
            }}
        />
    );
}

export default CoinTag;