import {useEffect, useState} from "react";
import Cookies from "js-cookie";

const BallsStates = () => {
  const [animationDisabled, setAnimationDisabled] = useState(localStorage.getItem(`animationDisabled`) ? localStorage.getItem(`animationDisabled`) === 'true' : false);
  const [submitData, setSubmitData] = useState({
      game: "balls",
      suggestedNumbers: JSON.stringify([]),
      bet: "0.00000000",
      isFirstBet: false,
      firstBetValue: null
    }
  );

  const [settings, setSettings] = useState();
  const [responseData, setResponseData] = useState({
    win: 0,
    manualMode: true
  });

  const [errorData, setErrorData] = useState({
    error: false,
    terminate: false,
  });

  const [winAmount, setWinAmount] = useState(0);

  useEffect(() => {
    localStorage.setItem("animationDisabled", animationDisabled.toString());
  }, [animationDisabled])

  return {
    submitData,
    setSubmitData,
    settings,
    setSettings,
    responseData,
    setResponseData,
    errorData,
    setErrorData,
    winAmount,
    setWinAmount,
    animationDisabled,
    setAnimationDisabled,
  };
};

export default BallsStates;
