import React from "react";
import { useTranslation } from "react-i18next";
import { useBetween } from "use-between";
import CurrentStatisticsStates from "../../../games/CurrentStatisticStates";
import { useAnimationSyncData } from '../../../games/AnimationSyncDataStates';
import StatisticsBlock from './elements/StatisticsBlock';
import StatisticsResults from './elements/StatisticsResults';
import StatisticsCounter from './elements/StatisticsCounter';

const LeftSidebarCurrentStatisticsInfo = React.memo(({
  statisticsCounter,
  activeCurrency,
}) => {
  const { t } = useTranslation("leftSidebar");
  const {
    statistics
  } = useBetween(CurrentStatisticsStates);


  const {
    sumOfBet,
    profit,
    countOfBet,
    countOfWin,
    countOfLose,
    percentOfLuck,
    countOfMessages,
    maxBet,
    maxProfit
  } = useAnimationSyncData(statistics || {});
  console.log('AFTER useAnimationSyncData: countOfBet', countOfBet);
  /*
  const {
    sumOfBet,
    profit,
    countOfBet,
    countOfWin,
    countOfLose,
    percentOfLuck,
    countOfMessages,
    maxBet,
    maxProfit
  } = statistics || {};
  */



  return (
    <>
      <StatisticsBlock
        activeCurrency={activeCurrency}
        label={t("generalBet")}
        result={Number(sumOfBet).toFixed(8)}
      />
      <StatisticsBlock
        activeCurrency={activeCurrency}
        label={t("totalProfit")}
        result={Number(profit).toFixed(8)}
      />
      <StatisticsResults
        countOfBet={countOfBet}
        countOfWins={countOfWin}
        countOfLose={countOfLose}
      />
      <StatisticsCounter
        percentOfLuck={percentOfLuck}
        countOfMessages={countOfMessages ?? 0}
      />
      <StatisticsBlock
        activeCurrency={activeCurrency}
        label={t("maxBet")}
        result={Number(maxBet).toFixed(8)}
      />
      <StatisticsBlock
        activeCurrency={activeCurrency}
        label={t("maxProfit")}
        result={Number(maxProfit).toFixed(8)}
      />
    </>
  );
});

export default LeftSidebarCurrentStatisticsInfo;
