import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Checkbox from "rc-checkbox";
import { AppContext } from "../../../../App";
import { responseStatus } from "../../../../utils/consts";
import { closableNotification } from "../../../elements/notification/ClosableNotification";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import Spinner from "../../../elements/spinner/Spinner";
import AlertMessage from "../../../elements/alert/Alert";
import { StyledCheckboxLabel, StyledCheckboxWrapper } from "../../../styles/styledCheckbox";
import TwoFaModal from "../../../elements/twoFa/TwoFaModal";
import http from "../../../../http";

const ConfigSecurityContainer = () => {

  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  const [twoFaIsActive, setTwoFaIsActive] = useState(false);

  const { t } = useTranslation("siteOptions");

  const onChange = () => {
    setVisible(true);
  };

  const checkTwoFaIsActive = () => {
    http.get("/api/users/authenticator-secret-is-active/" + user.userId, userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setTwoFaIsActive(response.data.isActive);
        setLoading(false);
      }
    }).catch(error => {
      closableNotification(error.response.data.detail, "error");
      setLoading(false);
    });
  };
  useEffect(() => {
    checkTwoFaIsActive();
  }, [visible]);

  if (loading) {
    return <Spinner display="block" size="35px" />;
  }

  return (
      <div className="about-two-fa">
        <AlertMessage type="help" message={
          <>
            <p>{t('useTheAppForTwoFactor')}</p>
            <br/>
            <b>{t('youCanInstall')}:</b>
            <ul>
              <li>
                <a
                  className="default-link"
                  href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
                  target="_blank"
                  rel="noreferrer"
                >
                  Apple App Store;
                </a>
              </li>
              <li>
                <a
                  className="default-link"
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru"
                  target="_blank"
                  rel="noreferrer"
                >
                  Google Play Market.
                </a>
              </li>
            </ul>
          </>
        } mb="20" />
      {!twoFaIsActive && <AlertMessage
        type="warning"
        message={t('twoFactorUnused')}
        mt="20"
        mb="25"
      />}
      {twoFaIsActive && <AlertMessage
        type="success"
        message={t('twoFactorUsed')}
        mt="20"
        mb="25"
      />}
      <StyledCheckboxWrapper margin="0 0 20px">
        <Checkbox
          id="defaultCheckbox"
          className="default-checkbox"
          name="publish"
          checked={twoFaIsActive}
          onChange={onChange}
        />
        <StyledCheckboxLabel
          position="right"
          htmlFor="defaultCheckbox"
        >
          {t('useToLogin')}
        </StyledCheckboxLabel>
      </StyledCheckboxWrapper>
      <TwoFaModal
        visible={visible}
        twoFaIsActive={twoFaIsActive}
        setVisible={setVisible}
      />
    </div>
  );
};

export default ConfigSecurityContainer;