import React, { lazy } from "react";

const ClientsPage = lazy(() => import("../pages/clients/ClientsPage"));
const ClientsDetailsPage = lazy(() => import("../pages/clients/ClientsDetailsPage"));
const TransactionsPage = lazy(() => import("../pages/transactions/TransactionsPage"));
const LogsPage = lazy(() => import("../pages/logs/LogsPage"));

const adminRoutes = [
  {
    title: "Clients",
    path: "/:lang?/panel/clients",
    component: ClientsPage,
    exact: true,
  },
  {
    title: "Clients detail",
    path: "/:lang?/panel/client-details/:id",
    component: ClientsDetailsPage
  },
  {
    title: "Transactions",
    path: "/:lang?/panel/transactions",
    component: TransactionsPage,
    exact: true,
  },
  {
    title: "Logs",
    path: "/:lang?/panel/logs",
    exact: true,
    component: LogsPage
  }
];

export default adminRoutes;