import styled from "styled-components";
import {device} from "../../../../styles/responsive/responsiveUtils";

export const StyledChatRooms = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) => (props.active ? "0" : "-100%")};
  width: 350px;
  height: 100%;
  background: ${({ theme }) => theme.leftSidebar.blockBgColor};
  z-index: 999999;
  color: #333;
  overflow-y: auto;
  transition: all 0.4s ease-in-out;

  @media screen and ${device('desktop')} {
    left: ${(props) => (props.active ? "0" : "-110%")};
    width: 100%;
  }
`;

export const StyledChatRoomsCloseIcon = styled.div`
  font-size: 24px;
  line-height: 24px;
  opacity: 0.6;
  margin: 23px 20px 0 0;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;

  &:hover {
    opacity: 1;
  }

  & > button {
    font-size: 24px;
    line-height: 24px;
    border: none;
  }

  ${({ theme }) => theme.leftSidebar.closeBtn}
`;

export const StyledChatRoomsBody = styled.div`
  padding: 20px;
`;

export const StyledChatRoomsBodyTitle = styled.p`
  color: #555;
  font-size: 16px;
  padding-bottom: 15px;
`;

export const StyledChatRoomsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledChatRoomsListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
  font-size: 16px;
  cursor: pointer;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;

  &:hover {
    ${({ theme }) => theme.leftSidebarRooms.itemHover}
  }

  ${({ theme }) => theme.leftSidebarRooms.item}
`;

export const StyledCharRoomsUserInfo = styled.span`
  font-size: 14px;
`

export const StyledChatRoomsLang = styled.div`
  display: flex;
  align-items: center;
  height: 26px;
 
  & img {
    height: 20px;
    margin-right: 10px;
    border-radius: 3px;
  }
 
  & span {
    height: 20px;
  }
`
