import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import KeepAlive from 'react-activation';
import HelpContainer from "../../components/home/helpSection/HelpContainer";
import BallsContainer from "../../components/games/balls/BallsContainer";
import BallsBetsHistoryWrapper from "../../components/games/balls/BallsBetsHistoryWrapper";

import { StyledContainer, StyledContentWrapper } from "../../components/styles/styledContainer";
import { StyledAboutBalls } from "../../components/games/balls/styledBalls";
import { StyledBlockTitle, StyledParagraph } from "../../components/styles/styledDocumetnElemets";
import AlertMessage from "../../components/elements/alert/Alert";

const BallsPage = () => {
  const { t } = useTranslation("games");
  return (
    <StyledContainer>
      <Helmet>
        <title>{t("meta.balls.title")}</title>
        <meta
          name="description"
          content={t("meta.balls.description")}
        />
        <link
          rel="canonical"
          href={"https://" + window.location.hostname + "/balls"}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/balls"}
          hrefLang="en-US"
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ru/balls"}
          hrefLang="ru-RU"
        />
      </Helmet>
      <HelpContainer/>
      <KeepAlive>
        <BallsContainer />
      </KeepAlive>
      <BallsBetsHistoryWrapper/>

      <StyledContentWrapper>
        <AlertMessage
          type="info"
          message="Bets history is temporarily unavailable"
          mb="20"
          mt="20"
        />
      </StyledContentWrapper>
      <StyledAboutBalls>
        <StyledBlockTitle pb="20" fw="100">
          {t("titleBestCasino")}
        </StyledBlockTitle>
        <StyledParagraph>
          {t("textBestCasino")}
        </StyledParagraph>
        <StyledBlockTitle pb="20" fw="100">
          {t("titleDescriptionBalls")}
        </StyledBlockTitle>
        <StyledParagraph>
          {t("textDescriptionBalls")}
        </StyledParagraph>
      </StyledAboutBalls>
    </StyledContainer>
  );
};

export default BallsPage;
