import styled from "styled-components";
import { device } from "./responsive/responsiveUtils";

const colorButton = {
  color: String,
  border: Boolean
};

const styleButton = (color, border, theme) => {
  switch (color) {
    case "main":
      return `
        color: ${border ? '#307cad': '#fff'};
        background-color: ${border ? 'transparent': '#307cad'};
        border: 2px solid #307cad;
        &:hover {
          background-color: ${border ? 'rgba(25, 54, 54, 0.1)': '#2097e0'};
          border: 2px solid #2097e0;
        }
    `;
    case "neutral":
      return `
        color: ${border ? theme.neutralColor : '#fff'};
        background-color: ${border ? 'transparent': theme.neutralColor};
        border: 2px solid ${theme.neutralColor};
        &:hover {
          background-color: ${border ? 'rgba(140, 155, 155, 0.1)': theme.neutralColorHover};
          border: 2px solid ${theme.neutralColorHover};
        }
    `;
    case "success":
      return `
        color: ${border ? '#2eac5b': '#fff'};
        background-color: ${border ? 'transparent': '#2eac5b'};
        border: 2px solid #2eac5b;
        &:hover {
          background-color: ${border ? 'rgba(94, 158, 108, 0.1)': '#21df66'};
          border: 2px solid #21df66;
        }
     `;
    case "danger":
      return `
        color: ${border ? '#ab2e40': '#fff'};
        background-color: ${border ? 'transparent': '#ab2e40'};
        border: 2px solid #ab2e40;
        &:hover {
          background-color: ${border ? 'rgba(210, 92, 101, 0.1)': '#e21f3a'};
          border: 2px solid #e21f3a;
        }
      `;
    case "warning":
      return `
        color: ${border ? '#202020': '#fff'};
        background-color: ${border ? 'transparent': '#FEB967'};
        border: 2px solid #FEB967;
        &:hover {
          background-color: ${border ? 'rgba(254, 185, 103, 0.1)': '#EAA85A'};
          border: 2px solid #EAA85A;
        }
     `;
    case "info":
      return `
        color: ${border ? '#44B5B4': '#fff'};
        background-color: ${border ? 'transparent': '#44B5B4'};
        border: 2px solid #44B5B4;
        &:hover {
          background-color: ${border ? 'rgba(68, 181, 180, 0.1)': '#3BC7C6'};
          border: 2px solid #3BC7C6;
        }
     `;
    case "help":
      return `
        color: ${border ? '#193636': '#fff'};
        background-color: ${border ? 'transparent': '#193636'};
        border: 2px solid #193636;
        &:hover {
          background-color: ${border ? 'rgba(20, 115, 115, 0.24)': '#1a4e4e'};
          border: 2px solid #1a4e4e;
        }
    `;
    case "white":
      return `
        color: ${border ? '#fff': '#262626'};
        background-color: ${border ? 'transparent': '#fff'};
        border: 2px solid #fff;
        &:hover {
          background-color: ${border ? 'rgba(255, 255, 255, 0.15)': '#fff'};
          border: 2px solid #fff;
        }
      `;
    default:
      return `
        color: #323233;
        background: transparent;
        border: 2px solid #323233;
        &:hover {
          background-color: rgba(55, 55, 55, 0.15);
          border: 2px solid #4e4e4e;
        }
      `;
  }
};

export const StyledButton = styled("button", colorButton)`
  margin: 10px 0 0;
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ width }) => width && `width: ${width}%`};
  ${({ size }) => size && `font-size: ${size}px`};
  padding:  ${({ figure }) => figure === "circle" ? "12px" : "2px 10px"};
  font-weight: ${({ weight }) => weight || "200"};
  font-family: Arial,serif;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 50%);
  line-height: 36px;
  color: #ffffff;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.3s linear;
  border-radius: ${({ figure }) => figure === "circle" ? "50%" : "3px"};
  outline: none;
  user-select: none;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  ${({ disabled }) => disabled && `
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.7;
    transform: scale(1) !important;
  `};
  ${({ color, border, theme }) => styleButton(color, border, theme)}
  &:active {
    transform: scale(0.948) translateZ(0);
  }
  @media screen and ${device('desktop')} {
    font-size: 14px;
    line-height: 28px;
  }
`;