import { useState } from "react";
import { defaultOurStatistics } from "../../../../utils/consts";

export const OurStatistics = () => {

  const [ourStatistics, setOurStatistics] = useState(defaultOurStatistics);

  return {
    ourStatistics,
    setOurStatistics
  };
};