import getUserInfo from "./getUserInfo";

const userAuthenticationConfig = (jsonld = true, multipart = false) => {

  let type = jsonld ? "application/json+ld" : "application/json";
  let contentType = multipart ? "multipart/form-data" : type;

  let user = getUserInfo();

  if (!user) {
    return {
      headers: {
        "Content-Type": contentType,
        "Accept": type
      }
    };
  }

  return {
    headers: {
      "Content-Type": contentType,
      "Authorization": "Bearer " + localStorage.getItem("token"),
      "Accept": type
    }
  };
};

export default userAuthenticationConfig;