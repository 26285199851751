import { lazy } from "react";
import HomePage from "../pages/home/HomePage";
import BallsPage from "../pages/balls/BallsPage";
import DicePage from "../pages/dice/DicePage";
import ExamplePage from "../pages/example/ExamplePage";
import RoulettePage from "../pages/roulette/RoulettePage";
import ClientDetailsPage from "../pages/account/ClientDetailsPage";
import BetsDetailsPage from "../pages/bets/BetsDetailsPage";
import NotFoundPage from "../pages/notFound/NotFoundPage";
import ContestsPage from "../pages/contests/ContestsPage";

const EmailConfirm = lazy(() => import("../pages/emailConfirm/EmailConfirmPage"));
const ForgotPassword = lazy(() => import("../pages/forgotPassword/ForgotPasswordPage"));
const ChangePassword = lazy(() => import("../pages/changePassword/ChangePasswordPage"));
const SupportPage = lazy(() => import("../pages/support/SupportPage"));
const FaqPage = lazy(() => import("../pages/faq/FaqPage"));
const BannersPage = lazy(() => import("../pages/banners/BannersPage"));
const ControlPage = lazy(() => import("../pages/control/ControlPage"));
const Politic = lazy(() => import("../pages/politic/PoliticPage"));

const routes = [
  {
    title: "Balls page",
    path: "/:lang?/bet/:id",
    component: BetsDetailsPage
  },
  {
    title: "Balls page",
    path: "/:lang?/balls",
    component: BallsPage
  },
  {
    title: "Roulette page",
    path: "/:lang?/roulette",
    component: RoulettePage
  },
  {
    title: "Dice page",
    path: "/:lang?/dice",
    component: DicePage
  },
  {
    title: "Example page",
    path: "/:lang?/example-components",
    exact: false,
    component: ExamplePage
  },
  {
    title: "Email Confirm",
    path: "/:lang?/email-confirm/:token?",
    component: EmailConfirm
  },
  {
    title: "Forgot Password",
    path: "/:lang?/remind",
    component: ForgotPassword
  },
  {
    title: "Change Password",
    path: "/:lang?/change-password/:token?",
    component: ChangePassword
  },
  {
    title: "Account",
    path: "/:lang?/account/:nickname",
    exact: true,
    component: ClientDetailsPage
  },
  {
    title: "Support",
    path: "/:lang?/support",
    exact: true,
    component: SupportPage
  },
  {
    title: "Faq",
    path: "/:lang?/faq",
    exact: true,
    component: FaqPage
  },
  {
    title: "Banners",
    path: "/:lang?/banners",
    exact: true,
    component: BannersPage
  },
  {
    title: "Contests",
    path: "/:lang?/contests",
    exact: true,
    component: ContestsPage
  },
  {
    title: "Page Not Found",
    path: "/:lang?/page-not-found",
    exact: true,
    component: NotFoundPage
  },
  {
    title: "Control",
    path: "/:lang?/control",
    exact: true,
    component: ControlPage
  },
  {
    title: "Politic",
    path: "/:lang?/politic",
    exact: true,
    component: Politic
  },
  {
    title: "Home page",
    path: "/:lang?",
    exact: true,
    component: HomePage
  },
];

export default routes;