import styled from "styled-components";
import { device } from "../../styles/responsive/responsiveUtils";

export const StyledWrapperButtons = styled.div`
  display: flex;
  padding-left: 3px;
  padding-bottom: 3px;
`;

export const StyledBetButton = styled.div`
  padding: 1px 6px 3px;
  border-radius: 2px;
  background: ${({ theme }) => theme.optionButtonsBg};
  color: ${({ theme }) => theme.optionButtonsColor};
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 2px;
  transition: all 0.15s ease-in-out;
  user-select: none;
  cursor: ${({ isPlay }) => isPlay ? "not-allowed" : "pointer"};

  &:hover {
    ${({ isPlay, theme }) => !isPlay && theme.betActions};
  }

  @media screen and ${device('xs')} {
    font-size: 10px;
  }
`;