import jwtEncode from "jwt-encode";

const telegramAuthenticationConfig = (data) => {

  const telegramToken = () => {
    if (window.location.host === process.env.REACT_APP_BASE_URL_IO) {
      return process.env.REACT_APP_TELEGRAM_BOT_TOKEN_IO;
    }

    return process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
  };

  return {
    headers: {
      "Content-Type": "application/json",
      "Authorization": "TelegramAuth " + jwtEncode(data, telegramToken())
    }
  };
};

export default telegramAuthenticationConfig;
