export const randomHash = (char) => {
  let nBytes = Math.ceil(char = (+char || 8) / 2);
  let u = new Uint8Array(nBytes);

  window.crypto.getRandomValues(u);

  let zpad = str => "00".slice(str.length) + str;
  let a = Array.prototype.map.call(u, x => zpad(x.toString(16)));
  let str = a.join("").toUpperCase();

  if (char % 2) {
    str = str.slice(1);
  }

  return str.toLowerCase();
};