import styled from "styled-components";

export const StyledNotFoundPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  line-height: 23px;
  color: ${({theme}) => theme.grayColorText};
  
  
  & > img {
    margin-top: 16px;
    border-radius: 200px;
  }
`;