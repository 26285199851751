import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../App";
import Dialog from "rc-dialog";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import { canceledStatus, responseStatus } from "../../../utils/consts";
import { closableNotification } from "../../elements/notification/ClosableNotification";
import { convertImageToBase64 } from "../../../utils/convertImageToBase64";
import http from "../../../http";

import defaultImg from "../../../assets/images/no-avatar.svg";

import { StyledButton } from "../../styles/styledButton";
import {StyledDefaultImageContainer, StyledLabel, StyledWrapperAvatarLoad} from "./styledAccountImage";


const AccountImageContainer = ({ nickname = null }) => {

  const { user } = useContext(AppContext);
  const [changeImg, setChangeImg] = useState(false);

  const { t } = useTranslation("siteOptions");

  const [base64Image, setBase64Image] = useState(defaultImg);
  const [isFileChoose, setIsFileChoose] = useState(false);

  const [defaultImages, setDefaultImages] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    http.get(
      "/api/media-objects",
      { ...userAuthenticationConfig(), signal: abortController.signal }
    ).then(response => {
      if (response.status === responseStatus.HTTP_OK && response.data.base64 !== "") {
        setBase64Image(response.data.base64 ?? defaultImg);
      } else {
        setBase64Image(defaultImg);
      }
    }).catch(error => {
      setBase64Image(defaultImg);

      if (error.message === canceledStatus) {
        return;
      }

      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error.response.data.error, "error");
      }
    });
    http.get(
        "/api/media-objects/default",
        { ...userAuthenticationConfig() }
    ).then(response => {
      if (response.status === responseStatus.HTTP_OK && response.data !== "") {
        setDefaultImages(response.data);
      }
    }).catch(error => {
      if (error.message === canceledStatus) {
        return;
      }
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error.response.data.error, "error");
      }
    });
    // http.post("/api/media-objects/default", { name: '1' }, userAuthenticationConfig(false))
    return () => {
      abortController.abort();
    }
  }, []);

  const uploadImage = async (event) => {
    const file = event.target.files[0];

    setIsFileChoose(true);
    setBase64Image(await convertImageToBase64(file));
  };

  if (!user || (nickname && user?.nickname !== nickname)) {
    return (
      <>
        <div className="user-info__level" title={t('accountRating')}>
          ∞
        </div>
        <div className="user-info__image">
          <img src={base64Image} alt="user-img"/>
        </div>
      </>
    );
  }

  const createMediaObject = (defaultImage=null) => {
    let url = defaultImage?"/api/media-objects/default":"/api/media-objects"
    let data = defaultImage?{name: defaultImage}:{ base64Image: base64Image }

    http.post(url, data, userAuthenticationConfig(false)).then(response => {
      if (response.status === responseStatus.HTTP_CREATED && response.data.base64 !== "") {
        setBase64Image(response.data.base64);
        setChangeImg(false);
        setIsFileChoose(false);
        closableNotification(t('imageChanged'), "success");
      }
    }).catch(error => {
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error.response.data.error, "error");
      }
    });
  };

  return (
    <>
      <Dialog
        visible={changeImg}
        wrapClassName="default-modal-wrapper"
        onClose={() => setChangeImg(false)}
        animation="zoom"
        maskAnimation="fade"
        title={t('changeAvatar')}
        forceRender={false}
        className="default-modal"
      >
        <StyledWrapperAvatarLoad>
          <StyledLabel htmlFor="userPhoto" className={isFileChoose && 'active'}>
            <input
              type="file"
              id="userPhoto"
              accept="image/png, image/gif, image/jpeg, image/jpg"
              onChange={event => uploadImage(event)}
            />
            <span className="file-photo"><img src={base64Image} alt=""/></span>
            <span className="label-file__text">{t('uploadAvatar')}</span>
          </StyledLabel>
          <div className="clean-input" onClick={() => setIsFileChoose(false)}></div>
          <span>Или выберите из существующих</span>
          <StyledDefaultImageContainer>
            {defaultImages.map((image, index) => (
                <img onClick={()=>{createMediaObject(image.name)}} key={index} src={image.url} alt={`Image ${index}`} />
            ))}
          </StyledDefaultImageContainer>
          <StyledButton color="neutral" width="100" onClick={createMediaObject}>
            {t('save')}
          </StyledButton>
        </StyledWrapperAvatarLoad>
      </Dialog>
      <button className="user-info__image user-info_edit" onClick={() => setChangeImg(true)}
              title={t('changeAvatar')}>
        {base64Image && <img src={base64Image} alt="user-image"/>}
        <div className="user-info__level" title={t('accountRating')}>
          ∞
        </div>
      </button>
    </>
  );
};

export default AccountImageContainer;