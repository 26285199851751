import { device } from "../styles/responsive/responsiveUtils";
import styled from "styled-components";

export const StyledBetHistoryTable = styled.table`
  overflow-x: scroll;
  padding: 20px 0 30px;
  font-size: 16px;
  font-weight: 100;
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;
`

export const StyledBetHistoryTHead = styled.thead`
  th {
    color: #6b99b6;
    font-size: 12px;
    padding: 10px 7px;
    text-align: left;
    border-bottom: ${({ theme }) => theme.tableRow.borderWidth}px ${({ theme }) => theme.tableRow.borderColor} solid;
    text-transform: uppercase;
    font-weight: 100;
    -webkit-text-size-adjust: 100%;
    text-wrap: nowrap;

    &:first-child {
      padding-left: 15px;
    }

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }

    @media screen and ${device('xs')} {
      &:nth-child(4), &:nth-child(5) {
        display: none;
      }
    }

    @media screen and ${device('desktop', 'min')} {
      padding: 10px 7px;
      font-size: 16px;

      &:first-child {
        padding-left: 20px;
      }
    }
  }
`

export const StyledBetHistoryTBody = styled.tbody`
  tr {
    &:nth-child(odd) {
      background-color: ${({ theme }) => theme.tableRow.backgroundColor};
    }
    
    &.full {
      position: relative;
      width: 100%;
      height: 40px;
      margin: 0 !important;
      text-transform: uppercase;
      
      td {
        width: 100%;
        position: absolute;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin: 0 !important;
        background: ${({ theme }) => theme.tableRow.backgroundColor};
      }
    }
    
    td {
      font-size: 12px;
      padding: 10px 7px 13px;
      vertical-align: middle;
      border-bottom: 
        ${({ theme }) => theme.tableRow.borderWidth}px 
        ${({ theme }) => theme.tableRow.borderColor} 
        solid;
      
      &.center {
        text-align: center;
      }

      @media screen and ${device('xs')} {
        &:nth-child(4), &:nth-child(5) {
          display: none;
        }
      }

      @media screen and ${device('desktop', 'min')} {
        font-size: 16px;

        &:first-child {
          padding-left: 20px;
        }
      }
    }
  }
`