import styled from "styled-components";
import { device } from "../../styles/responsive/responsiveUtils";

export const StyledNavigationWrapper = styled.nav`
  position: relative;
  @media screen and ${device("desktop")} {
    display: none;
  }
`;

export const StyledMenuList = styled.ul`
  display: flex;
  justify-content: start;

  .menu-item {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    &:hover {
     a {
       color: #ffffff;
     }
    }

    @media screen and ${device("desktop")} {
      width: 100%;
      height: unset;
      padding: 0;
      justify-content: start;
      border-radius: 4px;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &:before, &:after {
        position: unset;
        width: 0;
      }

      &:hover .menu-item_link, .menu_item {

      }
    }

    &:first-child {
      .menu-item_link {
        &:before {
          display: none;
        }
      }
    }

    .menu-item_link {
      width: 100%;
      padding: 30px 18px;
      color: #9F9F9F;
      font-size: 13px;
      text-transform: uppercase;
      text-align: left;
      border: none;
      outline: none;
      position: relative;
      transition: none;
      @media screen and ${device("desktop", "min")} {
        &:before {
          content: "";
          width: 1px;
          height: 18px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background-color: #383838;
        }
      }
      @media screen and ${device("desktop")} {
        text-transform: unset;
        font-size: 16px;
        padding: 8px 15px;
        background-color: ${({ theme }) => theme.mobileNav.item.backgroundColor};
        color: ${({ theme }) => theme.mobileNav.item.color};
        border-radius: 4px;
      }


      &-active {
        color: #FFFFFF;

        &:after {
          content: "";
          width: calc(100% - 18px - 18px);
          height: 2px;
          position: absolute;
          bottom: 0;
          left: 18px;
          background-color: #058bd8;

          @media screen and ${device("desktop")} {
            width: 100%;
            left: 0;
          }
        }
      }
    }

  }

  @media screen and ${device("desktop")} {
    width: 100%;
    border-radius: 4px;
    flex-direction: column;
    padding: 0 0;
  }
`;

export const StyledHoverNavigation = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  transition: opacity, left .1s ease;
  overflow: hidden;

  div {
    height: 2px;
    background-color: #058bd8;
    transition: opacity .2s ease;
  }
`;