import styled from "styled-components";
import "../../assets/images/coins/style.css"

export const StyledPaymentMethodsIcons = styled.span.attrs(props => {
  if (props.className?.includes('payment-method-')) {
    const classList = props.className.split(' ');
    let found;
    let size = 'small/';
    for (const className of classList) {
      if (className.includes('payment-method-')) {
        found = className.replace('payment-method-', '').toLowerCase();
        if (found.startsWith('big-')) {
          found = found.substring(4, found.length);
          size = 'big/';
        }
        break;
      }
    }
    if (found) {
      try {
        const bgImage = require(`../../assets/images/coins/svg/${found}.svg`);
        if (bgImage) {
          return {
            ...props,
            bgImage
          }
        }
      } catch (error) {
        console.log(`Image not found: ${size + found}`);
      }
    }
  }
  return props;
})`
  width: ${({ width }) => width ? `${width}px` : '25px'};
  height: ${({ height }) => height ? `${height}px` : '25px'};
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mr }) => mr && `margin-right: ${mr}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ ml }) => ml && `margin-left: ${ml}px`};
  display: inline-flex;
  ${({ bgImage }) => bgImage && `background-image: url(${bgImage})`};
`;