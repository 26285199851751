import styled from "styled-components";

export const StyledCoinConfig = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  & > button {
    margin-top: 0;
  }
`;