import React from "react";
import { StyledMaintenance } from "./styledMaintenance";
import AlertMessage from "../alert/Alert";

export const Maintenance = ({ message, description }) => {
  return <StyledMaintenance>
    <div className={'mt-image'}>
      <span>{message}</span>
    </div>
    {description && <AlertMessage message={description}/>}
  </StyledMaintenance>
}

export default Maintenance;