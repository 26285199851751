import React from "react";
import { useTranslation } from "react-i18next";
import {
  StyledChatFriends,
  StyledChatFriendsBody,
  StyledChatFriendsCloseIcon,
  StyledChatFriendsSearch,
  StyledChatIgnored,
  StyledChatMyFriends
} from "./styledChatFriends";
import { StyledChatHeader, StyledChatTitle } from "../styledSidebarChat";
import AlertMessage from "../../../alert/Alert";

export default function ChatFriends({ active, closeFriends }) {
  const { t } = useTranslation("leftSidebar");

  return (
    <StyledChatFriends active={active} className="chat-rooms">
      <StyledChatHeader className="chat-header">
        <StyledChatTitle>{t("friends")}</StyledChatTitle>
        <StyledChatFriendsCloseIcon>
          <button onClick={() => closeFriends()}>✕</button>
        </StyledChatFriendsCloseIcon>
      </StyledChatHeader>
      <StyledChatFriendsBody>
        <StyledChatFriendsSearch placeholder={t("friendsSearchPlaceholder")} className={'search-input'}/>
        <AlertMessage className={'search-warning'}
                      type="warning"
                      message={"Hint: Type 'mod' to find moderator. Type 'help' to find support operator. Type 'admin' to find administrator."}
        />
        <div className={'content'}>
          <StyledChatMyFriends>
            <div className={'friends__title'}>My Friends (0)</div>
            <div>Nobody's here</div>
          </StyledChatMyFriends>
          <StyledChatIgnored>
            <div className={'ignored__title'}>Ignored (0)</div>
            <div>Nobody's here</div>
          </StyledChatIgnored>
        </div>
      </StyledChatFriendsBody>
    </StyledChatFriends>
  );
}
