const googleAuthenticationConfig = (token) => {
  return {
    headers: {
      "Content-Type": "application/json",
      "Authorization": "GoogleAuth " + token
    }
  };
};

export default googleAuthenticationConfig;
