import styled from "styled-components";

export const StyledFooterLangs = styled.div`
  height: 0;
  position: absolute;
  bottom: 120%;
  right: -6px;
  padding: 0 6px;
  box-shadow: 0 0 3px rgb(0 0 0 / 30%);
  background: #222;
  color: #c0c0c0;
  width: max-content;
  overflow: hidden;
  transition: .5s;

  & a:hover {
    background: #191919;
  }

  & img {
    display: block;
    margin-right: 8px;
  }

  & a {
    display: flex;
    align-items: center;
    margin-right: 3px;
    padding: 5px;
    border-radius: 4px;

    &:last-child {
      margin-right: 0;
    }
  }

  & li {
    border-top: 1px solid rgba(255, 255, 255, 0.05);

    &:first-child {
      border-top: none;
    }
  }

  ${({isActive}) =>
  isActive && `
      padding: 6px;
      height: 85px;
      overflow: hidden;
    `
}
`;