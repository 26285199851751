import styled from "styled-components";
import { StyledContentWrapper } from "../../components/styles/styledContainer";
import { StyledParagraph } from "../../components/styles/styledDocumetnElemets";
import { device } from "../../components/styles/responsive/responsiveUtils";

export const StyledContestsPage = styled(StyledContentWrapper)`
  .custom-hint {
    position: relative;
    width: 0;
    transform: translate(15px, -30px);
    z-index: 100;
  }
`;

export const StyledContestsPageDescription = styled(StyledParagraph)`
  color: ${({ theme }) => theme.secondTextColor};
`;

export const StyledContestsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const StyledContestItem = styled.div`
  background: rgba(130, 130, 130, 0.1);
  border: 2px ${({ theme }) => theme.body} solid;
  padding: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  cursor: default;

  & span.green {
    color: ${({ theme }) => theme.diceHistory.win} !important;
  }

  & span.red {
    color: ${({ theme }) => theme.diceHistory.lose} !important;
  }

  & > .contest-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.subTitleColor};
    font-size: 26px;
    line-height: 26px;
    font-weight: 500;

    & > .contest-title-wrapper {
      display: flex;
      align-items: center;

      justify-content: space-between;

      & > .contest-status {
        background: ${({ isActive, theme }) => isActive ? "#2eab5b" : theme.body};
        color: ${({ isActive, theme }) => isActive ? "#ffffff" : theme.secondTextColor};
        font-size: 12px;
        line-height: 12px;
        padding: 7px 10px;
        text-transform: uppercase;
        margin-right: 12px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
      }
    }

    & > .contest-button-wrapper {
        display: flex;
    }

    & > .contest-button-wrapper > .contest-collapsed-timeleft {
      ${({ collapsed }) => !collapsed && "opacity: 0;"}
      margin-right: 55px;
      text-align: center;
      white-space: nowrap;
      color: ${({ theme }) => theme.secondTextColor};
      font-size: 20px;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      @media screen and ${device(800)} {
          display: none;
      }
    }

    & > .contest-button-wrapper > .contest-collapse-btn {
      justify-self: flex-end;
      border: none;
      color: ${({ theme }) => theme.secondTextColor};
      text-decoration: none;
      cursor: pointer;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;

      &:hover {
        color: ${({ theme }) => theme.text};
      }
    }
  }

  & > .contest-item-body {
    display: flex;
    flex-direction: column;
    margin-top: 25px;

    ${({ collapsed }) => collapsed && "display: none;"}
    & > .contest-description {
      font-size: 16px;
      line-height: 23px;
      color: ${({ theme }) => theme.secondTextColor};
      margin-bottom: 20px;
    }

    & > .contest-stats {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 15px;

      & > .contest-stats-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 105px;
        background: ${({ theme }) => theme.elementBg};
        padding: 19px 25px 20px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        overflow: hidden;

        &.contest-wagered .contest-stats-number {
          margin-top: 8px;
          font-size: 24px;
          font-weight: 100;
        }

        & > span:first-child.contest-stats-label {
          margin: 7px 0 5px;
        }

        & > .contest-stats-label {
          font-size: 14px;
          color: ${({ theme }) => theme.secondTextColor};
          margin-top: 15px;
          text-transform: uppercase;
        }

        & > .contest-stats-number {
          font-size: 30px;
          color: ${({ theme }) => theme.text};
          font-weight: 500;
        }

        @media screen and ${device(350)} {
          width: 100% !important;
        }

        @media screen and ${device("xs")} {
          width: 47%;
          padding: 10px 13px;
          height: 63px;

          &.contest-timeleft {
            width: 100%;
            order: 0;
          }

          &.contest-prize-pool {
            order: 1;
          }

          &.contest-participants {
            order: 2;
          }

          &.contest-place {
            order: 3;
          }

          &.contest-wagered {
            order: 4;
          }

          &.contest-wagered .contest-stats-number {
            margin-top: 0;
            font-size: 16px;
          }

          & > span:first-child.contest-stats-label, & > .contest-stats-label {
            font-size: 13px;
            margin: 5px 0 3px;
          }

          & > .contest-stats-number {
            font-size: 16px;
          }
        }

        @media screen and ${device("xs", "min")} and ${device("desktop")} {
          height: 80px;
          padding: 10px 15px;

          &.contest-wagered .contest-stats-number {
            margin-top: 0;
            font-size: 24px;
          }

          & > span:first-child.contest-stats-label, & > .contest-stats-label {
            font-size: 14px;
            margin: 7px 0 5px;
          }

          & > .contest-stats-number {
            font-size: 20px;
          }
        }
      }
    }

    & > .contest-leaderboard {
      display: flex;
      flex-direction: column;

      & > .contest-leaderboard-title {
        margin: 35px 0 25px 0;
        color: #058bd8;
        font-size: 26px;
        line-height: 26px;
        font-weight: 500;
      }

      .contest-leaderboard-wrapper {
        overflow-x: auto;
      }

      .contest-leaders-table {
        width: 100%;
        font-size: 15px;
        border: none;
        border-collapse: collapse;


        & > thead th {
          padding: 0 15px 6px 15px;
          color: #6b99b6;
          font-weight: 500;
          text-transform: uppercase;
        }

        & > tbody, & > thead {
          width: 100%;
        }

        & > tbody > tr {
          & > td {
            padding: 5px 15px;
            vertical-align: middle;
            text-align: center;
            height: 40px;

            &.contest-leader-image img {
              width: 20px;
              height: 20px;
              vertical-align: top;
            }

            &:last-child {
              font-weight: 500;
            }
          }

          &:nth-child(odd) {
            background-color: ${({ theme }) => theme.tableRow.backgroundColor};
          }
        }
      }
    }
  }
}
`;